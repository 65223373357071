import { ThemeUICSSObject } from "@theme-ui/css";
import { Button, Modal } from "@wordpress/components";
import { close } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";
import { forwardRef } from "react";

import { useDevice } from "@/data/hooks/LayoutProvider";
import { iconButtonHoverBackground } from "@/styles/mixins";

type ModalProps = {
  title?: string;
  children: React.ReactNode;
  noLeftPadding?: boolean;
  onRequestClose: () => void;
  sx?: ThemeUICSSObject;
  className?: string;
  shouldCloseOnClickOutside?: boolean;
  hideHeader?: boolean;
  isDismissible?: boolean;
  shouldCloseOnEsc?: boolean;
};

const modalHeadingHeight = 60;

export const D1Modal = forwardRef<HTMLDivElement | null, ModalProps>(
  function D1Modal(
    {
      title = "",
      className,
      children,
      noLeftPadding = false,
      onRequestClose,
      shouldCloseOnClickOutside = false,
      hideHeader = false,
      isDismissible = true,
      shouldCloseOnEsc = true,
    },
    ref,
  ) {
    // Add additional styles for mobile
    const { isMobile } = useDevice();

    const mobileStyles = isMobile
      ? ({
          height: "calc(100dvh - 40px)",
          mt: "40px",
          borderRadius: "12px 12px 0 0",
          maxHeight: "initial !important",
        } as ThemeUICSSObject)
      : {};

    return (
      <Modal
        ref={ref}
        title={title}
        onRequestClose={onRequestClose}
        shouldCloseOnClickOutside={shouldCloseOnClickOutside}
        isDismissible={isDismissible}
        shouldCloseOnEsc={shouldCloseOnEsc}
        sx={{
          bg: "surface_light1_dark4",
          color: "textPrimary",
          borderRadius: "12px",
          boxShadow: "shadow3",
          "& .components-modal__content": {
            p: 4,
            paddingLeft: noLeftPadding && 0,
            mt: `${modalHeadingHeight - 1}px`,
          },
          "& .components-modal__content.has-scrolled-content:not(.hide-header) .components-modal__header":
            {
              borderBottomColor: "borderPrimary",
            },
          "&&&& .components-modal__header": {
            borderBottom: "1px solid",
            borderBottomColor: "borderPrimary",
            paddingLeft: 4,
            paddingRight: 3,
            py: 3,
            height: `${modalHeadingHeight}px`,
            display: hideHeader ? "none" : "flex",
            "& .components-modal__header-heading, & .components-modal__header-heading-container":
              {
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                fontSize: 2,
                lineHeight: 2,
              },
            "& button.components-button": {
              width: "2.25rem",
              height: "2.25rem",

              "&:hover": { bg: "surface_light3_dark3", color: "inherit" },

              svg: {
                fill: "currentcolor",
                width: "24px",
                height: "24px",
              },
            },
          },
          ...mobileStyles,
        }}
        className={className}
      >
        {children}
        {hideHeader && isDismissible && (
          <CloseButton onClick={onRequestClose} />
        )}
      </Modal>
    );
  },
);

export const CloseButton = ({ onClick }: { onClick: () => void }) => {
  const { __ } = useI18n();
  return (
    <Button
      sx={{
        color: "textPrimary",
        position: "absolute",
        opacity: 0.75,
        m: 2,
        right: 0,
        top: "2px",
        zIndex: 2,
        ...iconButtonHoverBackground,
      }}
      label={__("Close")}
      icon={close}
      iconSize={24}
      onClick={() => {
        onClick();
      }}
    />
  );
};
