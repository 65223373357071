import { Snackbar } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { Cache } from "@/cache";
import { Z_INDEX_SNACKBAR } from "@/styles/theme";
import { primaryViewState } from "@/view_state/ViewStates";

export const UpdateAvailable = observer(() => {
  const { __ } = useI18n();
  const needsUpdate = primaryViewState.newVersionAvailable == "yes";

  const forceUpdate = async () => {
    Cache.update();
  };

  const actions = [{ label: __("Update Now"), onClick: forceUpdate }];
  if (needsUpdate) {
    return (
      <div
        sx={{
          position: "absolute",
          zIndex: Z_INDEX_SNACKBAR,
          bottom: 5,
          right: 8,
          "&& .components-snackbar": {
            backgroundColor: "surface_light3_dark3",
            color: "textPrimary",
            "& p": {
              mr: 3,
            },
          },
          "&&&& .components-snackbar__action.components-button": {
            color: "textPrimary",
          },
          "&& .components-snackbar__dismiss-button": {
            display: "none",
            pointerEvents: "none",
          },
        }}
      >
        <Snackbar actions={actions} explicitDismiss={true}>
          <p>{__("There is a new version of the web client available")}</p>
        </Snackbar>
      </div>
    );
  }
  return null;
});

UpdateAvailable.displayName = "UpdateAvailable";
