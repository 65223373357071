import { Button, Spinner } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

import { D1Modal } from "@/components/D1Modal";
import { D1ProgressBar } from "@/components/D1ProgressBar";

type LoadingModalProps = {
  title: string;
  handleClose: () => void;
  action?: () => void;
  actionLabel?: string;
  showProgress?: boolean;
  message?: string;
  progressType?: "spinner" | "bar";
};

export const LoadingModal: React.FC<LoadingModalProps> = ({
  title,
  handleClose,
  action,
  actionLabel,
  showProgress = true,
  message,
  progressType = "spinner",
}) => {
  const { __ } = useI18n();

  const ProgressComponent =
    progressType === "spinner" ? (
      <Spinner sx={{ width: 6, height: 6, margin: 0 }} />
    ) : (
      <D1ProgressBar sx={{ color: "#fff" }} />
    );

  return (
    <D1Modal
      isDismissible={false}
      shouldCloseOnEsc={false}
      sx={{
        width: ["100dvw", "100dvw", "420px"],
        "& .components-modal__content": {
          p: 6,
        },
      }}
      title={title}
      onRequestClose={handleClose}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 3,
        }}
      >
        {showProgress && ProgressComponent}
        {message}
        {action && (
          <Button variant="primary" onClick={action}>
            {actionLabel || __("Cancel")}
          </Button>
        )}
      </div>
    </D1Modal>
  );
};
