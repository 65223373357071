import { useCallback, useState } from "react";
import { RTJNode } from "types/rtj-format";
import { useLocation } from "wouter";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { convertBlocksToMarkdown } from "@/components/Editor/gb2rtj/gb2md";
import { convertRTJToBlocks } from "@/components/Editor/rtj2gb/rtj2gb";
import { getEntryURL } from "@/data/URLFunctions";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { CreateNewEntryOpts } from "@/data/repositories/EntryRepository";
import { useFocusEditor } from "@/hooks/useFocusEditor";
import { mkEntryID } from "@/utils/uuid";
import { CALENDAR_VIEW } from "@/view_state/PrimaryViewState";
import {
  calendarViewState,
  primaryViewState,
  activeEntryViewState,
  modalRouterViewState,
} from "@/view_state/ViewStates";

export function useCreateNewEntry(
  analyticsMethod: string | null,
  initialContent?: string,
) {
  const [, setLocation] = useLocation();
  const focusEditor = useFocusEditor();
  const { journalLimit, selectedEntryView, journals } = primaryViewState;
  const { selectedDate } = calendarViewState;
  const defaultJournal = journals.length ? journals[0] : null;
  const { isInSharedJournal, userIsJournalOwner } = activeEntryViewState;
  const journalStore = d1Classes.journalStore;
  const {
    showPremiumUpgradeModal,
    openAddEntryToJournal,
    showKeyModal,
    newJournal,
  } = modalRouterViewState;
  const [doneCheckCreateNewEntryAction, setDoneCheckCreateNewEntryAction] =
    useState(false);

  // No mater the plan you are allowed to create entries in journals that you are
  // a participant in. There is a limit though for the ones you own.
  // This separates out journals you own and journals you participate in.
  const { ownedJournals, participatingJournals } = journals.reduce(
    (acc, j) => {
      if (!j.is_read_only && j.is_decrypted) {
        if (j.owner_id === primaryViewState.user?.id) {
          acc.ownedJournals.push(j);
        } else if (j.is_shared) {
          acc.participatingJournals.push(j);
        }
      }
      return acc;
    },
    {
      ownedJournals: [] as JournalDBRow[],
      participatingJournals: [] as JournalDBRow[],
    },
  );
  const limit = journalLimit?.limit ?? 1;
  const validJournals = [
    ...ownedJournals.slice(0, limit),
    ...participatingJournals,
  ];

  const createNewEntry = useCallback(
    (journalId?: string, opts?: CreateNewEntryOpts) => {
      let finalJournalId: string | undefined = journalId;
      const finalOptions = opts || {};
      if (!validJournals.find((j) => j.id === journalId)) {
        finalJournalId = defaultJournal?.id;
      }
      if (
        opts?.prefill?.richTextJson &&
        opts?.prefill?.richTextJson.length > 0 &&
        !opts?.prefill?.markdown
      ) {
        const entryId = mkEntryID();
        const blocks = convertRTJToBlocks(
          opts?.prefill?.richTextJson,
          entryId,
          journalId || "",
        );
        const md = convertBlocksToMarkdown(blocks);
        finalOptions.prefill = { ...opts.prefill, markdown: md };
        finalOptions.useEntryId = entryId;
      }

      if (finalJournalId) {
        const journal = journals.find((j) => j.id === finalJournalId);
        const newEntryDate =
          selectedEntryView === CALENDAR_VIEW ? selectedDate : new Date();
        d1Classes.entryStore
          .createNewEntry(finalJournalId, newEntryDate, finalOptions)
          .then((entry) => {
            if (analyticsMethod) {
              analytics.tracks.recordEvent(EVENT.entryCreate, {
                method: analyticsMethod,
                initial_content: initialContent || "blank",
                shared_journal: journal?.is_shared || false,
                entry_id: entry.id,
              });
              if (opts?.prefill?.templateId) {
                analytics.tracks.recordEvent(EVENT.templateApplied, {
                  template_id: opts.prefill.templateId,
                  gallery_template_id: opts.prefill.galleryTemplateID || null,
                  entry_id: entry.id,
                });
              }
            }
            const url = getEntryURL(
              entry.journalId,
              entry.id,
              selectedEntryView,
            );
            setLocation(url);
            focusEditor();
          });
      }
    },
    [
      journalLimit,
      defaultJournal,
      selectedEntryView,
      selectedDate,
      analyticsMethod,
      setLocation,
      isInSharedJournal,
    ],
  );

  const createNewEntryHandler = useCallback(
    (journalId: string, content: RTJNode[] = [], tags: string[] = []) =>
      async () => {
        setDoneCheckCreateNewEntryAction(false);
        const journal = await journalStore.getById(journalId);
        const userCanAddEntry =
          await journalStore.userCanAddEntryToJournal(journal);

        switch (userCanAddEntry) {
          case "CAN_CREATE":
            createNewEntry(journalId, {
              prefill: { richTextJson: content, tags },
            });
            break;
          case "CANNOT_CREATE":
            if (userIsJournalOwner) {
              showPremiumUpgradeModal("shared_journal");
            } else {
              openAddEntryToJournal(true);
            }
            break;
          case "NO_DECRYPTED_JOURNALS":
            showKeyModal({
              openedFrom: "add_entry",
              isCreatingSharedJournal: false,
            });
            break;
          case "ZERO_JOURNALS":
            newJournal();
            break;
          case "PICK_A_JOURNAL":
            openAddEntryToJournal();
            break;
          default:
            console.error(
              "createNewEntryHandler: unexpected userCanAddEntry value",
              userCanAddEntry,
            );
            break;
        }
        setDoneCheckCreateNewEntryAction(true);
      },
    [
      createNewEntry,
      isInSharedJournal,
      userIsJournalOwner,
      showPremiumUpgradeModal,
      openAddEntryToJournal,
    ],
  );

  return {
    createNewEntryHandler,
    validJournals,
    defaultJournal,
    createNewEntry,
    doneCheckCreateNewEntryAction,
  };
}
