import { useI18n } from "@wordpress/react-i18n";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { SelectControl } from "@/components/Form/SelectControl";
import { GroupHeading, ItemGroup } from "@/components/ItemGroup";
import { HideJournalFromSync } from "@/components/JournalSettings/HideJournalFromSync";
import { JournalSettingsSlideContent } from "@/components/JournalSettings/JournalSettingsSlideContent";
import { ViewProps } from "@/components/JournalSettings/JournalSettingsTypes";
import { ToggleConceal } from "@/components/JournalSettings/ToggleConceal";
import { ToggleShowInAllEntries } from "@/components/JournalSettings/ToggleShowInAllEntries";
import { SelectItemWrapper } from "@/components/Settings/SelectItemWrapper";
import { ToggleSetting } from "@/components/Settings/ToggleSetting";
import { TemplateDBRow } from "@/data/db/migrations/template";
import { truncateString } from "@/utils/strings";

type AdvancedProps = ViewProps & {
  canEncrypt?: boolean;
  isShared?: boolean;
  templates?: TemplateDBRow[];
  newUnsyncableValue?: boolean;
  onJournalSyncToggle?: (value: boolean) => void;
  showHeading?: boolean;
  toggleCommentsDisabled: (commentsDisabled: number) => void;
};

const MAX_FIELD_WIDTH = "190px";
const MAX_TEMPLATE_NAME_LENGTH = 40;

export const trackJournalToggle = (toggle: string, value: number) => {
  const button_identifier = `journalAdvancedSettings_${toggle}_${value === 1 ? "On" : "Off"}`;
  analytics.tracks.recordEvent(EVENT.buttonTap, {
    button_identifier,
  });
};

export const JournalSettingsAdvancedView: React.FC<AdvancedProps> = ({
  journal,
  setJournal,
  toggleCommentsDisabled,
  canEncrypt,
  isShared,
  templates,
  isEditing,
  newUnsyncableValue,
  onJournalSyncToggle,
  direction,
  showHeading = false,
}) => {
  const { __ } = useI18n();
  return (
    <div sx={{ overflow: "hidden" }}>
      <JournalSettingsSlideContent
        name="advanced"
        direction={isEditing ? direction : "right"}
      >
        {showHeading && <GroupHeading>{__("Advanced")}</GroupHeading>}
        <ItemGroup addMargin={!isEditing}>
          <SelectItemWrapper sx={{ width: "100%", py: "10px" }}>
            <SelectControl
              label={__("Sort Order")}
              value={journal.sort_method}
              options={[
                { label: __("Entry Date"), value: "entryDate" },
                { label: __("Edit Date"), value: "editDate" },
              ]}
              onChange={(value) => {
                setJournal({ ...journal, sort_method: value });
              }}
            />
          </SelectItemWrapper>
          <ToggleSetting
            label={__("End-to-end encryption")}
            disabled={!canEncrypt || isShared || isEditing}
            checked={!!journal.e2e}
            onChange={() => {
              setJournal({ ...journal, e2e: journal.e2e ? 0 : 1 });
            }}
          />

          <ToggleConceal
            journal={journal}
            setJournal={setJournal}
            trackOnChange={trackJournalToggle}
          />

          <ToggleShowInAllEntries
            journal={journal}
            setJournal={setJournal}
            trackOnChange={trackJournalToggle}
          />

          <ToggleSetting
            label={__("Enable Comments")}
            checked={!journal.comments_disabled}
            onChange={() => {
              const comments_disabled = journal.comments_disabled ? 0 : 1;
              toggleCommentsDisabled(comments_disabled);
              trackJournalToggle("comments", comments_disabled);
            }}
          />

          {canEncrypt &&
            !journal.is_shared &&
            templates &&
            templates.length > 0 && (
              <SelectItemWrapper sx={{ width: "100%", py: "10px" }}>
                <SelectControl
                  sx={{
                    "& .components-input-control__container": {
                      width: MAX_FIELD_WIDTH,
                    },
                    "&&& .components-select-control__input": {
                      width: MAX_FIELD_WIDTH,
                    },
                  }}
                  label={__("Default Template")}
                  value={journal.template_id}
                  options={[
                    { value: "", label: __("Select a Template") },
                    ...templates.map((t) => {
                      const truncatedTitle = truncateString(
                        t.title,
                        MAX_TEMPLATE_NAME_LENGTH,
                      );

                      return {
                        label: truncatedTitle,
                        value: t.clientId,
                      };
                    }),
                  ]}
                  onChange={(value) => {
                    setJournal({ ...journal, template_id: value });
                  }}
                />
              </SelectItemWrapper>
            )}
          {isEditing && (
            <HideJournalFromSync
              onToggle={onJournalSyncToggle!}
              value={newUnsyncableValue!}
            />
          )}
        </ItemGroup>
      </JournalSettingsSlideContent>
    </div>
  );
};
