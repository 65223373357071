import { unregisterBlockType, registerBlockType } from "@wordpress/blocks";
import { useI18n } from "@wordpress/react-i18n";

import {
  STATE_OF_MIND_BLOCK_ID,
  StateOfMindBlockEditProps,
} from "@/components/Editor/blocks/constants";
import { SuggestionWrapper } from "@/components/Editor/blocks/suggestions/SuggestionWrapper";
import { blockIsRegistered } from "@/components/Editor/utils/register-blocks";
import { StateOfMind } from "@/components/icons/suggestions/StateOfMind";
import { useMomentThumb } from "@/data/hooks/moments";

export const StateOfMindBlock: React.FC<StateOfMindBlockEditProps> = ({
  attributes,
  clientId,
  isSelected,
}) => {
  const { __ } = useI18n();
  const { url } = useMomentThumb(
    attributes.journalId,
    attributes.entryId,
    attributes.iconIdentifier,
  );
  const listSeparator = __("and");
  const formatLists = (list: string[]) => {
    if (list.length === 1) {
      return list[0];
    } else if (list.length === 2) {
      return `${list[0]} ${listSeparator} ${list[1]}`;
    } else if (list.length > 2) {
      const first = list.slice(0, list.length - 1).join(", ");
      return `${first} ${listSeparator} ${list[list.length - 1]}`;
    } else {
      return "";
    }
  };
  return (
    <SuggestionWrapper
      title={formatLists(attributes.labelsDisplayNames)}
      body={formatLists(attributes.associationsDisplayNames)}
      imageURL={url}
      fallBackIcon={<StateOfMind />}
      clientId={clientId}
      isSelected={isSelected}
    />
  );
};

export const register = () => {
  if (!blockIsRegistered(STATE_OF_MIND_BLOCK_ID)) {
    registerBlockType(STATE_OF_MIND_BLOCK_ID, {
      edit: (props: StateOfMindBlockEditProps) => {
        return <StateOfMindBlock {...props} />;
      },
      title: "State of Mind",
      category: "media",
      textdomain: "default",
      description: "Day One State of Mind Block",
      attributes: {
        type: {
          type: "string",
        },
        identifier: {
          type: "string",
        },
        kind: {
          type: "string",
        },
        kindDisplayName: {
          type: "string",
        },
        valence: {
          type: "number",
        },
        valenceClassification: {
          type: "string",
        },
        valenceClassificationDisplayName: {
          type: "string",
        },
        associations: {
          type: "array",
        },
        associationsDisplayNames: {
          type: "array",
        },
        labels: {
          type: "array",
        },
        labelsDisplayNames: {
          type: "array",
        },
        lightColor: {
          type: "string",
        },
        darkColor: {
          type: "string",
        },
        iconIdentifier: {
          type: "string",
        },
        source: {
          type: "string",
        },
        journalId: {
          type: "string",
        },
        entryId: {
          type: "string",
        },
      },
      supports: {
        lock: false,
        html: false,
        inserter: false,
      },
    });
  }
};

export const unregister = () => {
  if (blockIsRegistered(STATE_OF_MIND_BLOCK_ID)) {
    return unregisterBlockType(STATE_OF_MIND_BLOCK_ID);
  }
  return false;
};
