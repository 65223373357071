import {
  Template,
  TemplateCategory,
} from "@/data/api_callers/schemas/PBCTemplateAPISchemas";
import { DODexie } from "@/data/db/dexie_db";

export class PBCTemplateRepository {
  constructor(protected db: DODexie) {}

  async getCategories() {
    return await this.db.pbc_template_categories.orderBy("sort_idx").toArray();
  }

  async getTemplatesForCategories(templateIds: string[]) {
    return this.db.pbc_templates.where("id").anyOf(templateIds).toArray();
  }

  async saveTemplateCategories(categories: TemplateCategory[]) {
    await this.db.pbc_template_categories.bulkPut(categories);
  }
  async saveTemplates(templates: Template[]) {
    await this.db.pbc_templates.bulkPut(templates);
  }
}
