import { Button, Spinner } from "@wordpress/components";
import { createInterpolateElement } from "@wordpress/element";
import { useI18n } from "@wordpress/react-i18n";

import { d1Classes } from "@/D1Classes";
import { D1Modal } from "@/components/D1Modal";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Reauth } from "@/components/Settings/Account/Reauth";
import { useDeleteAccount } from "@/data/hooks/userAccount/deleteAccount";

type Props = {
  onRequestClose: () => void;
};

const cancelSubscriptionURL =
  "https://dayoneapp.com/guides/premium-subscription/view-change-or-cancel-your-premium-subscription/";

const goToStripeUrl = async () => {
  const url = await d1Classes.fetchWrapper.fetchRedirectLocation(
    `/api/subscription/create_customer_portal_session`,
  );
  if (!url) {
    window.location = cancelSubscriptionURL as unknown as string & Location;
    return;
  }
  window.location = url as unknown as string & Location;
};

export const AccountDeletionModal: React.FC<Props> = ({ onRequestClose }) => {
  const { __ } = useI18n();
  const {
    deleteAccount,
    isDeleting,
    isDeleted,
    error,
    canDelete,
    reason,
    subscription,
  } = useDeleteAccount();

  const getCancelSubscriptionElement = (
    subscription: { source: string; name: string } | null,
  ) => {
    if (subscription?.name !== "free" && subscription?.source === "stripe") {
      return (
        <Button
          variant="link"
          sx={{ "&&": { color: "dayOneBlue", fontSize: 2 } }}
          onClick={goToStripeUrl}
        />
      );
    }
    return (
      <a
        href={cancelSubscriptionURL}
        rel="noopener noreferrer nofollow"
        target="_blank"
      />
    );
  };

  return (
    <D1Modal
      onRequestClose={onRequestClose}
      title={__("Confirm account deletion")}
      sx={{ maxWidth: "500px" }}
    >
      <div
        sx={{
          a: { textDecoration: "underline", color: "dayOneBlue" },
          "& p": { lineHeight: "body", mb: 2 },
        }}
      >
        {/* Handle options when trying to delete */}
        {canDelete && isDeleting && (
          <>
            <Spinner /> {__("Deleting...")}
          </>
        )}
        {canDelete && error && <ErrorMessage message={error} />}
        {canDelete && (
          <div sx={{ display: "flex", flexDirection: "column" }}>
            <p>
              {__(
                "Are you sure you want to permanently delete your Day One account?",
              )}
            </p>
            {subscription && subscription.name !== "free" && (
              <p>
                {createInterpolateElement(
                  __(
                    "You have an active Day One subscription. If you are not using it ensure you also <cancelSubscription>cancel your subscription</cancelSubscription>.",
                  ),
                  {
                    cancelSubscription:
                      getCancelSubscriptionElement(subscription),
                  },
                )}
              </p>
            )}
            <div
              sx={{
                display: "flex",
                mt: 2,
                justifyContent: "flex-end",
                width: "100%",
                gap: "1rem",
              }}
            >
              <Button variant="secondary" onClick={onRequestClose}>
                {__("Cancel")}
              </Button>
              <Button
                variant="primary"
                isDestructive={true}
                onClick={deleteAccount}
                sx={{ "&&&": { backgroundColor: "red" } }}
              >
                {__("Delete Account")}
              </Button>
            </div>
          </div>
        )}
        {canDelete && isDeleted && (
          <p>
            {__(
              "Your account has been deleted. We're sorry to see you go. You will be logged out shortly.",
            )}
          </p>
        )}
        {/* Handle reasons why account can't be deleted */}
        {canDelete === null && (
          <>
            <Spinner /> {__("Loading...")}
          </>
        )}
        {!canDelete && reason === "auth" && <Reauth />}
        {!canDelete && reason === "subscription" && (
          <p>
            {createInterpolateElement(
              __(
                "You currently have an active Day One subscription. Before you can delete your account you need to <cancelSubscription>cancel your subscription</cancelSubscription>.",
              ),
              {
                cancelSubscription: (
                  <a
                    href={cancelSubscriptionURL}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  />
                ),
              },
            )}
          </p>
        )}
        {!canDelete && reason === "sharedJournal" && (
          <p>
            {__(
              "Your account owns at least one shared journal. Before you can delete your account you would need to transfer ownership to someone else, or delete the shared journal.",
            )}
          </p>
        )}
      </div>
    </D1Modal>
  );
};
