import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import { WPConnection } from "@/components/Settings/WPConnect/WPConnectionTypes";

export class WPConnectAPIClient {
  constructor(private fetchWrapper: FetchWrapper) {}

  async fetchConnectedBlogs(): Promise<WPConnection[] | { error: string }> {
    const res = await this.fetchWrapper.fetchRoot("/api/wp-connect");
    if (!res.ok) {
      Sentry.captureException(new Error("Failed to load blogs from server."), {
        extra: { status: res.status, text: await res.text() },
      });
      return { error: "Failed to load blogs from server." };
    } else {
      const bodyJson = await res.json();
      return bodyJson as WPConnection[];
    }
  }

  async connectNewBlog({
    blog_url,
    username,
    app_pass,
  }: {
    blog_url: string;
    username: string;
    app_pass: string;
  }): Promise<NewBlogConnectionResponse> {
    const res = await this.fetchWrapper.fetchRoot("/api/wp-connect", {
      method: "POST",
      body: JSON.stringify({ blog_url, username, app_pass }),
    });
    if (!res.ok && res.status !== 400) {
      Sentry.captureException(
        new Error("Unexpectedly failed to connect new blog", {
          cause: { status: res.status, text: await res.text() },
        }),
      );
      return { newBlog: null, error: "Unknown error" };
    } else {
      if (res.status === 400) {
        return { newBlog: null, error: await res.text() };
      } else {
        return {
          newBlog: (await res.json()) as WPConnection,
          error: undefined,
        };
      }
    }
  }

  async disconnectBlog(blogUrl: string): Promise<void> {
    const res = await this.fetchWrapper.fetchRoot(
      `/api/wp-connect/${encodeURIComponent(blogUrl)}`,
      {
        method: "DELETE",
      },
    );

    if (!res.ok) {
      const status = res.status;
      const text = await res.text();
      console.error("Failed to disconnect blog.", status, text);
      const error = new Error(`Failed to disconnect blog. ${status} ${text}`);
      Sentry.captureException(error);
      throw error;
    }
  }

  async publishEntry(
    blogUrl: string,
    journalClientId: string,
    entryClientId: string,
    title: string | undefined,
    rtjson: string,
    media: { id: string; blob: Uint8Array }[],
  ): Promise<string> {
    const formData = new FormData();

    formData.append("blog_url", blogUrl);
    formData.append("journal_client_id", journalClientId);
    formData.append("entry_client_id", entryClientId);
    formData.append("title", title ?? "Post from Day One");
    formData.append("rtjson_document", rtjson);

    media.forEach(({ id, blob }) => {
      formData.append(`media[]`, new Blob([blob]), id);
    });

    try {
      const response = await this.fetchWrapper.fetchRoot(
        "/api/wp-connect/publish",
        {
          method: "POST",
          body: formData,
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result.post_url;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }
}

export type NewBlogConnectionResponse = {
  newBlog: WPConnection | null;
  error: string | undefined;
};
