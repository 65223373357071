import { BlockInstance } from "@wordpress/blocks";

import {
  AudioNode,
  ContactNode,
  EmbeddedContentNode,
  HorizontalRuleLineNode,
  LocationNode,
  PdfNode,
  PhotoNode,
  PodcastNode,
  PreviewNode,
  SongNode,
  MotionActivityNode,
  VideoNode,
  WorkoutNode,
  GenericMediaNode,
  StateOfMindNode,
} from "@/../types/rtj-format";
import {
  AUDIO_BLOCK_ID,
  CHECKLIST_ITEM_BLOCK_ID,
  CODE_BLOCK_ID,
  CONTACT_BLOCK_ID,
  GALLERY_BLOCK_ID,
  HEADING_BLOCK_ID,
  IMAGE_BLOCK_ID,
  LIST_BLOCK_ID,
  LOCATION_BLOCK_ID,
  PARAGRAPH_BLOCK_ID,
  PDF_BLOCK_ID,
  PODCAST_BLOCK_ID,
  PREVIEW_BLOCK_ID,
  QUOTE_BLOCK_ID,
  SEPARATOR_BLOCK_ID,
  SONG_BLOCK_ID,
  ACTIVITY_BLOCK_ID,
  VIDEO_BLOCK_ID,
  WORKOUT_BLOCK_ID,
  GENERIC_MEDIA_BLOCK_ID,
  STATE_OF_MIND_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { convertCodeBlockToRTJNode } from "@/components/Editor/gb2rtj/convert-code-block";
import { convertGBHeadingToRTJ } from "@/components/Editor/gb2rtj/format-heading";
import {
  convertChecklistItemToRTJNodes,
  convertGBListToRTJNodes,
} from "@/components/Editor/gb2rtj/format-list";
import { convertGBQuoteToRTJNodes } from "@/components/Editor/gb2rtj/format-quote";
import { convertHTMLToRTJ } from "@/components/Editor/gb2rtj/formatting";
import { squashParagraphBlocks } from "@/components/Editor/gb2rtj/squash-paragraph-blocks";

export const convertGBBlocksToRTJNodes = (blocks: BlockInstance[]) => {
  return squashParagraphBlocks(blocks)
    .flatMap((block) => {
      // if we skip some blocks due to embedded media we just return null
      // if we run over the end of the array.
      if (!block) {
        return [];
      }

      switch (block.name) {
        case CODE_BLOCK_ID:
          return [convertCodeBlockToRTJNode(block)];
        case CHECKLIST_ITEM_BLOCK_ID:
          return convertChecklistItemToRTJNodes(block);
        case HEADING_BLOCK_ID:
          return convertGBHeadingToRTJ(block);
        case QUOTE_BLOCK_ID:
          return convertGBQuoteToRTJNodes(block);
        case PARAGRAPH_BLOCK_ID:
          return convertHTMLToRTJ(block.attributes.content);
        case LIST_BLOCK_ID:
          return convertGBListToRTJNodes(block);
        case SEPARATOR_BLOCK_ID:
          return {
            embeddedObjects: [{ type: "horizontalRuleLine" }],
            // cast because TS seems to consider the different possible embedded object definitions as `type: string` which is incompatible with the set of strings we specify for embedded nodes.
          } as EmbeddedContentNode<HorizontalRuleLineNode>;
        case VIDEO_BLOCK_ID: {
          return {
            embeddedObjects: [
              {
                type: "video",
                identifier: block.attributes.clientId,
              },
            ],
            // cast because TS seems to consider the different possible embedded object definitions as `type: string` which is incompatible with the set of strings we specify for embedded nodes.
          } as EmbeddedContentNode<VideoNode>;
        }
        case IMAGE_BLOCK_ID: {
          if (block.attributes.clientId) {
            return {
              embeddedObjects: [
                { type: "photo", identifier: block.attributes.clientId },
              ],
              // cast because TS seems to consider the different possible embedded object definitions as `type: string` which is incompatible with the set of strings we specify for embedded nodes.
            } as EmbeddedContentNode<PhotoNode>;
          } else {
            // if the moment has not yet been uploaded, skip the image for now
            return [];
          }
        }
        case GALLERY_BLOCK_ID: {
          const embeddedObjects: (PhotoNode | VideoNode)[] = [];

          block.innerBlocks.forEach((block) => {
            embeddedObjects.push({
              type: block.name === IMAGE_BLOCK_ID ? "photo" : "video",
              identifier: block.attributes.clientId,
            });
          });

          return {
            embeddedObjects,
            // cast because TS seems to consider the different possible embedded object definitions as `type: string` which is incompatible with the set of strings we specify for embedded nodes.
          } as unknown as EmbeddedContentNode<PhotoNode | VideoNode>[];
        }
        case AUDIO_BLOCK_ID: {
          return {
            embeddedObjects: [
              { type: "audio", identifier: block.attributes.clientId },
            ],
          } as EmbeddedContentNode<AudioNode>;
        }
        case PDF_BLOCK_ID: {
          return {
            embeddedObjects: [
              { type: "pdfAttachment", identifier: block.attributes.clientId },
            ],
          } as EmbeddedContentNode<PdfNode>;
        }
        case PREVIEW_BLOCK_ID: {
          return {
            embeddedObjects: [{ type: "preview", url: block.attributes.url }],
          } as EmbeddedContentNode<PreviewNode>;
        }
        case CONTACT_BLOCK_ID: {
          return {
            embeddedObjects: [
              {
                type: "contact",
                identifier: block.attributes.identifier,
                name: block.attributes.name,
                photoIdentifier: block.attributes.photoIdentifier,
                source: block.attributes.source,
              },
            ],
          } as EmbeddedContentNode<ContactNode>;
        }
        case LOCATION_BLOCK_ID: {
          return {
            embeddedObjects: [
              {
                type: "location",
                identifier: block.attributes.identifier,
                city: block.attributes.city,
                placeName: block.attributes.placeName,
                latitude: block.attributes.latitude,
                longitude: block.attributes.longitude,
                date: block.attributes.date,
                source: block.attributes.source,
              },
            ],
          } as EmbeddedContentNode<LocationNode>;
        }
        case PODCAST_BLOCK_ID: {
          return {
            embeddedObjects: [
              {
                type: "podcast",
                identifier: block.attributes.identifier,
                show: block.attributes.show,
                episode: block.attributes.episode,
                artworkIdentifier: block.attributes.artworkIdentifier,
                date: block.attributes.date,
                source: block.attributes.source,
              },
            ],
          } as EmbeddedContentNode<PodcastNode>;
        }
        case SONG_BLOCK_ID: {
          return {
            embeddedObjects: [
              {
                type: "song",
                identifier: block.attributes.identifier,
                song: block.attributes.song,
                artist: block.attributes.artist,
                album: block.attributes.album,
                artworkIdentifier: block.attributes.artworkIdentifier,
                date: block.attributes.date,
                source: block.attributes.source,
              },
            ],
          } as EmbeddedContentNode<SongNode>;
        }
        case ACTIVITY_BLOCK_ID: {
          return {
            embeddedObjects: [
              {
                type: "motionActivity",
                identifier: block.attributes.identifier,
                startDate: block.attributes.startDate,
                endDate: block.attributes.endDate,
                iconIdentifier: block.attributes.iconIdentifier,
                steps: block.attributes.steps,
                source: block.attributes.source,
                movementType: block.attributes.movementType,
                movementTypeName: block.attributes.movementTypeName,
              },
            ],
          } as EmbeddedContentNode<MotionActivityNode>;
        }
        case WORKOUT_BLOCK_ID: {
          return {
            embeddedObjects: [
              {
                type: "workout",
                identifier: block.attributes.identifier,
                route: block.attributes.route,
                workoutMetrics: block.attributes.workoutMetrics,
                activityType: block.attributes.activityType,
                displayName: block.attributes.displayName,
                startDate: block.attributes.startDate,
                endDate: block.attributes.endDate,
                distance: block.attributes.distance,
                source: block.attributes.source,
              },
            ],
          } as EmbeddedContentNode<WorkoutNode>;
        }
        case GENERIC_MEDIA_BLOCK_ID: {
          return {
            embeddedObjects: [
              {
                type: "genericMedia",
                identifier: block.attributes.identifier,
                title: block.attributes.title,
                artist: block.attributes.artist,
                album: block.attributes.album,
                iconIdentifier: block.attributes.iconIdentifier,
                date: block.attributes.date,
                source: block.attributes.source,
              },
            ],
          } as EmbeddedContentNode<GenericMediaNode>;
        }
        case STATE_OF_MIND_BLOCK_ID: {
          return {
            embeddedObjects: [
              {
                type: "stateOfMind",
                identifier: block.attributes.identifier,
                kind: block.attributes.kind,
                kindDisplayName: block.attributes.kindDisplayName,
                valence: block.attributes.valence,
                valenceClassification: block.attributes.valenceClassification,
                valenceClassificationDisplayName:
                  block.attributes.valenceClassificationDisplayName,
                associations: block.attributes.associations,
                associationsDisplayNames:
                  block.attributes.associationsDisplayNames,
                labels: block.attributes.labels,
                labelsDisplayNames: block.attributes.labelsDisplayNames,
                lightColor: block.attributes.lightColor,
                darkColor: block.attributes.darkColor,
                iconIdentifier: block.attributes.iconIdentifier,
                source: block.attributes.source,
              },
            ],
          } as EmbeddedContentNode<StateOfMindNode>;
        }
        default:
          return [];
      }
    })
    .filter((node) => node !== null);
};
