import { unregisterBlockType, registerBlockType } from "@wordpress/blocks";

import {
  SONG_BLOCK_ID,
  SongBlockEditProps,
} from "@/components/Editor/blocks/constants";
import { SuggestionWrapper } from "@/components/Editor/blocks/suggestions/SuggestionWrapper";
import { blockIsRegistered } from "@/components/Editor/utils/register-blocks";
import { Song } from "@/components/icons/suggestions/Song";
import { useMomentThumb } from "@/data/hooks/moments";

export const SongBlock: React.FC<SongBlockEditProps> = ({
  attributes,
  clientId,
  isSelected,
}) => {
  const { url } = useMomentThumb(
    attributes.journalId,
    attributes.entryId,
    attributes.artworkIdentifier,
  );
  const body = `${attributes.artist}, ${attributes.album}`;

  return (
    <SuggestionWrapper
      imageURL={url}
      title={attributes.song}
      body={body}
      fallBackIcon={<Song />}
      clientId={clientId}
      isSelected={isSelected}
    />
  );
};

export const register = () => {
  if (!blockIsRegistered(SONG_BLOCK_ID)) {
    // @ts-ignore - seems types are not inline with code here.
    registerBlockType(SONG_BLOCK_ID, {
      edit: (props: SongBlockEditProps) => {
        return <SongBlock {...props} />;
      },
      title: "Song",
      category: "media",
      textdomain: "default",
      description: "Day One Song Block",
      attributes: {
        type: {
          type: "string",
        },
        identifier: {
          type: "string",
        },
        song: {
          type: "string",
        },
        artist: {
          type: "string",
        },
        album: {
          type: "string",
        },
        artworkIdentifier: {
          type: "string",
        },
        date: {
          type: "string",
        },
        source: {
          type: "string",
        },
        journalId: {
          type: "string",
        },
        entryId: {
          type: "string",
        },
      },
      supports: {
        lock: false,
        html: false,
        inserter: false,
      },
    });
  }
};

export const unregister = () => {
  if (blockIsRegistered(SONG_BLOCK_ID)) {
    return unregisterBlockType(SONG_BLOCK_ID);
  }
  return false;
};
