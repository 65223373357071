import { z } from "zod";

export const pbcPromptSchema = z.object({
  id: z.string(),
  content: z.string(),
  deleted_at: z.string().nullable(),
});

export const pbcPromptsSchema = z.array(pbcPromptSchema);

export type PBCPrompt = z.infer<typeof pbcPromptSchema>;
