import { ProgressBar } from "@wordpress/components";

export const D1ProgressBar = () => {
  return (
    <ProgressBar
      sx={{
        "& div": {
          backgroundColor: (theme) =>
            `color-mix(in srgb, ${theme.colors?.textPrimary}, transparent 20%)`,
        },
      }}
    />
  );
};
