import { liveQuery } from "dexie";

import { Sentry } from "@/Sentry";
import { KeyValueStore } from "@/data/db/KeyValueStore";
import { DODexie } from "@/data/db/dexie_db";
import { isDevelopment } from "@/utils/environment";
import {
  SyncStatus,
  syncStates as syncStatuses,
} from "@/worker/SyncWorkerTypes";

export const SYNC_STATUS_KEY = "sync_status";
const STAT_SYNC_STATUS_KEY = "stat_sync_status";
const OBLITERATION_FLAG_KEY = "obliterate-sync";

export type JournalSyncStatus =
  | "NOT_SELECTED"
  | "SYNCING"
  | "IDLE"
  | "ERROR"
  | "WAITING_INITIAL_SYNC";

export class SyncStateRepository {
  constructor(
    private db: DODexie,
    private kv: KeyValueStore,
  ) {}

  async getSyncCursorById(id: string) {
    const result = await this.db.sync_states.get(id);
    return result || null;
  }

  updateSyncCursorById(id: string, cursor: number | string) {
    const newCursor = cursor === 0 ? "" : cursor + "";
    return this.db.sync_states.put({
      id,
      cursor: newCursor,
      can_decrypt: 1,
      error: "",
      last_sync: Date.now(),
    });
  }

  setJournalCursor(cursor: number) {
    return this.updateSyncCursorById("journalsCursor", cursor);
  }

  setTemplateCursor(cursor: string) {
    return this.updateSyncCursorById("templatesCursor", cursor);
  }

  setContentKeysCursor(cursor: string) {
    return this.updateSyncCursorById("contentKeysCursor", cursor);
  }

  setPresetsCursor(cursor: string) {
    return this.updateSyncCursorById("presetsCursor", cursor);
  }

  async getJournalCursor() {
    const cursor = await this.getSyncCursorById("journalsCursor");
    return cursor ? cursor.cursor : "";
  }

  async getTemplateCursor() {
    const cursor = await this.getSyncCursorById("templatesCursor");
    return cursor ? cursor.cursor : "";
  }

  async getContentKeysCursor() {
    const cursor = await this.getSyncCursorById("contentKeysCursor");
    return cursor ? cursor.cursor : "";
  }

  async getNotificationsCursor() {
    const cursor = await this.getSyncCursorById("notificationsCursor");
    return cursor ? cursor.cursor : "";
  }

  async getPresetsCursor() {
    const cursor = await this.getSyncCursorById("presetsCursor");
    return cursor ? cursor.cursor : "";
  }

  setNotificationsCursor(cursor: string) {
    return this.updateSyncCursorById("notificationsCursor", cursor);
  }

  async getCommentsCursor(journalId: string, entryId: string) {
    const cursor = await this.getSyncCursorById(
      `${journalId}-${entryId}:commentsCursor`,
    );
    return cursor ? cursor.cursor : "";
  }

  setCommentsCursor(journalId: string, entryId: string, cursor: string) {
    return this.updateSyncCursorById(
      `${journalId}-${entryId}:commentsCursor`,
      cursor,
    );
  }

  async getUserSettingsCursor() {
    const cursor = await this.getSyncCursorById("userSettingsCursor");
    return cursor ? cursor.cursor : "";
  }

  setUserSettingsCursor(cursor: string) {
    return this.updateSyncCursorById("userSettingsCursor", cursor);
  }

  async getSyncStatus(): Promise<SyncStatus> {
    return (await this.kv.get(SYNC_STATUS_KEY)) || syncStatuses.IDLE;
  }

  async setSyncStatus(state: SyncStatus) {
    await this.kv.set(SYNC_STATUS_KEY, state);
  }

  subscribeToSyncStatus(cb: (state: SyncStatus | undefined) => void) {
    return this.kv.subscribe(SYNC_STATUS_KEY, cb);
  }

  async getStatSyncStatus(): Promise<SyncStatus> {
    return (await this.kv.get(STAT_SYNC_STATUS_KEY)) || syncStatuses.IDLE;
  }

  async setStatSyncStatus(state: SyncStatus) {
    await this.kv.set(STAT_SYNC_STATUS_KEY, state);
  }

  async getPBCTemplateCategoryCursor() {
    const cursor = await this.getSyncCursorById("pbcTemplateCategoryCursor");
    return cursor ? cursor.cursor : "";
  }

  async setPBCTemplateCategoryCursor(cursor: string) {
    return this.updateSyncCursorById("pbcTemplateCategoryCursor", cursor);
  }

  async getPBCTemplateCursor() {
    const cursor = await this.getSyncCursorById("pbcTemplateCursor");
    return cursor ? cursor.cursor : "";
  }

  async setPBCTemplateCursor(cursor: string) {
    return this.updateSyncCursorById("pbcTemplateCursor", cursor);
  }

  async getPBCPromptCursor() {
    const cursor = await this.getSyncCursorById("pbcPromptCursor");
    return cursor ? cursor.cursor : "";
  }

  async setPBCPromptCursor(cursor: string) {
    return this.updateSyncCursorById("pbcPromptCursor", cursor);
  }

  subscribeToStatSyncStatus(cb: (state: SyncStatus | undefined) => void) {
    return this.kv.subscribe(STAT_SYNC_STATUS_KEY, cb);
  }

  subscribeToSyncedJournals(cb: (journalIds: string[]) => void) {
    const stream = liveQuery(async () => {
      const results = await this.db.journal_sync_info.toArray();
      return results.reduce((acc: string[], x) => {
        if (x.hasCompleted) {
          acc.push(x.journal_id);
        }
        return acc;
      }, []);
    }).subscribe(
      (journalIds) => {
        cb(journalIds);
      },
      (err) => {
        Sentry.captureException(err);
      },
    );
    return () => {
      stream.unsubscribe();
    };
  }

  async getJournalSyncInfo(journalId: string) {
    const existing = await this.db.journal_sync_info.get(journalId);
    return existing;
  }

  async setJournalSyncStatus(
    journalId: string,
    status: JournalSyncStatus,
    hasCompleted?: boolean,
  ) {
    if (!hasCompleted) {
      const existing = await this.db.journal_sync_info.get(journalId);
      if (existing && existing.hasCompleted) {
        hasCompleted = true;
      }
    }
    await this.db.journal_sync_info.put({
      journal_id: journalId,
      status,
      hasCompleted,
    });
  }

  async enableSyncForJournal(journalId: string) {
    const existing = await this.db.journal_sync_info.get(journalId);
    // If the journal is waiting for initial sync, update the
    // last clicked date so the sync process knows which journal to
    // sync next
    if (existing && existing.status != "WAITING_INITIAL_SYNC") {
      return false;
    }

    await this.db.journal_sync_info.put({
      journal_id: journalId,
      last_clicked: Date.now(),
      status: "WAITING_INITIAL_SYNC",
    });
    return true;
  }

  async subscribeToJournalSyncStatuses(
    callback: (status: Record<string, JournalSyncStatus>) => void,
  ) {
    const sub = liveQuery(async () => {
      const all = await this.db.journal_sync_info.toArray();
      return all.reduce<Record<string, JournalSyncStatus>>((acc, cur) => {
        acc[cur.journal_id] = cur.status;
        return acc;
      }, {});
    }).subscribe(callback, (err) => Sentry.captureException(err));
    return () => {
      sub.unsubscribe();
    };
  }

  async isSyncEnabledForJournal(journalId: string) {
    const existing = await this.db.journal_sync_info.get(journalId);
    return !!existing;
  }

  async disableSyncForJournal(journalId: string) {
    await this.db.journal_sync_info.delete(journalId);
    await this.db.sync_states.where("id").equals(journalId).delete();
  }

  async disableSyncForJournals(journalIds: string[]) {
    await Promise.all(
      journalIds.map((journalId) => {
        this.disableSyncForJournal(journalId);
      }),
    );
  }

  // Sync wants to pull the most recently clicked
  // new journal, one at a time. We give it the next
  // one in line here.
  async getIdForNextQueuedInitialSync(): Promise<string | undefined> {
    const all = await this.db.journal_sync_info
      .where("status")
      .equals("WAITING_INITIAL_SYNC")
      .sortBy("last_clicked");
    return all[0]?.journal_id;
  }

  async getIdsForSyncingJournals(): Promise<string[]> {
    const x = await this.db.journal_sync_info.orderBy("journal_id").keys();
    return x as string[];
  }

  // Sets a flag that sync will check for after a page reload.
  // If the flag is there, and recently set, sync will obliterate
  // all of the stored data.
  // This only works in dev, and it's designed to make sync
  // quick to abort and clear without having to log out and back in.
  async setObliterationFlag() {
    if (isDevelopment()) {
      await this.kv.set(OBLITERATION_FLAG_KEY, new Date().getTime());
    }
  }

  async shouldObliterateSync() {
    if (isDevelopment()) {
      const obliterate = await this.kv.get<number | undefined | null>(
        OBLITERATION_FLAG_KEY,
      );
      // We give a six-second window to reload the page after clickign the
      // obliterate button for sync to run.
      return obliterate && obliterate > Date.now() - 1000 * 6;
    } else {
      return false;
    }
  }

  async clearObliterationFlag() {
    if (isDevelopment()) {
      await this.kv.set(OBLITERATION_FLAG_KEY, null);
    }
  }

  async setLock() {
    await this.kv.set("sync_lock", new Date().getTime());
  }

  async clearLock() {
    await this.kv.set("sync_lock", null);
  }

  async isSyncLocked() {
    const lock = await this.kv.get<number | undefined | null>("sync_lock");
    // Sync locks last a few seconds, and should be refreshed by the sync
    // process regularly during the sync.
    return lock && lock > Date.now() - 1000 * 15;
  }
}
