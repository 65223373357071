import { store as blockEditorStore } from "@wordpress/block-editor";
import { Popover, createSlotFill } from "@wordpress/components";
import { debounce } from "@wordpress/compose";
import { useSelect } from "@wordpress/data";
import { useEffect, useMemo, useState, useRef } from "react";

import { ContextFormattingToolbar } from "@/components/Editor/components/ContextFormattingToolbar";
import { SLOT_NAME } from "@/components/Editor/utils/editor-popover-slot-name";
import { isCurrentSelectionWithinEditor } from "@/components/Editor/utils/selection";

export const CONTEXT_TOOLBAR_SLOT_FILL = createSlotFill(
  "dayone/context-toolbar",
);

export const ContextToolbar = () => {
  const [selectionBounds, setSelectionBounds] = useState<DOMRect | null>(null);
  const isMounted = useRef(true);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const debouncedSelectionHandler = debounce(() => {
      const selection = window.getSelection();
      const mounted = isMounted.current;

      if (selection?.anchorNode) {
        const range = selection?.getRangeAt(0);

        if (range?.getBoundingClientRect) {
          const bounds = range.getBoundingClientRect();

          if (range && range.endOffset - range.startOffset < 1 && mounted) {
            setSelectionBounds(null);
          } else if (bounds && mounted) {
            setSelectionBounds(bounds);
          }
        }
      } else if (mounted) {
        setSelectionBounds(null);
      }
    }, 150);

    const updateSelectionBoundsForScroll = () => {
      const mounted = isMounted.current;
      // don't thrash re-positioning of the popover
      window.requestAnimationFrame(() => {
        const selection = window.getSelection();

        if (selection?.anchorNode) {
          const range = selection?.getRangeAt(0);
          const bounds = range?.getBoundingClientRect();
          if (bounds && mounted) {
            setSelectionBounds(bounds);
          }
        } else if (mounted) {
          setSelectionBounds(null);
        }
      });
    };

    // on scroll make sure the popover is correctly placed.
    document.addEventListener("scroll", updateSelectionBoundsForScroll, true);
    document.addEventListener(
      "selectionchange",
      debouncedSelectionHandler,
      true,
    );

    return () => {
      isMounted.current = false;
      document.removeEventListener("scroll", updateSelectionBoundsForScroll);
      document.removeEventListener(
        "selectionchange",
        debouncedSelectionHandler,
      );
    };
  }, []);

  const isMultiCharacterSelection = useSelect(
    (select) => {
      // @ts-ignore - The type defs are out of date.
      const { getSelectionStart, getSelectionEnd, getMultiSelectedBlocks } =
        select(blockEditorStore);
      const selectEnd = getSelectionEnd();
      const selectBegin = getSelectionStart();
      const multiSelectedBlocks = getMultiSelectedBlocks();

      const beginOffset = selectBegin?.offset;
      const endOffset = selectEnd?.offset;

      if (beginOffset !== undefined && endOffset !== undefined) {
        return endOffset > beginOffset && !multiSelectedBlocks.length;
      }

      return false;
    },
    [selectionBounds],
  );

  const popoverAnchor = useMemo(() => {
    // 60 is a magic number. Just found by experimenting and doing what feels right.
    const heightOffset = 60;
    if (!selectionBounds) {
      return undefined;
    }
    return {
      getBoundingClientRect() {
        const width = selectionBounds.width;
        const height = selectionBounds.height;
        const x = selectionBounds.x;
        const y = selectionBounds.y - heightOffset;
        return new window.DOMRect(x, y, width, height);
      },
    };
  }, [selectionBounds]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        contentRef.current &&
        !contentRef.current.contains(event.target as Node)
      ) {
        setSelectionBounds(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [contentRef]);

  return isMultiCharacterSelection &&
    selectionBounds &&
    isCurrentSelectionWithinEditor() ? (
    // We can't pass emotion styles into popover, so we're forced to target child styles
    // to ensure this displays correctly. This override also exists in Gberg's BlockTools.
    <CONTEXT_TOOLBAR_SLOT_FILL.Fill>
      <div
        sx={{
          ".components-popover__content": {
            overflowY: "visible",
            width: "max-content",
            border: "none",
            outline: "none",
          },
          "&&& .components-toolbar-group": {
            paddingLeft: 0,
          },
        }}
      >
        <Popover
          ref={contentRef}
          __unstableSlotName={SLOT_NAME}
          focusOnMount={false}
          animate={false}
          anchor={popoverAnchor}
          sx={{ "&& .components-popover__content": { width: "fit-content" } }}
        >
          <ContextFormattingToolbar />
        </Popover>
      </div>
    </CONTEXT_TOOLBAR_SLOT_FILL.Fill>
  ) : null;
};
