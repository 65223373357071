import { MomentDBRow } from "@/data/db/migrations/moment";
import { MomentModel } from "@/data/models/MomentModel";
import { Outbox } from "@/data/models/Outbox";
import {
  MomentCounts,
  MomentRepository,
} from "@/data/repositories/MomentRepository";
import { isValidMedia } from "@/data/utils/moments/media";
import { makeDebugLogger } from "@/utils/debugLog";

const debugLog = makeDebugLogger("MomentStore.ts", false);

export class MomentStore {
  constructor(
    private momentRepository: MomentRepository,
    private outbox: Outbox,
  ) {}

  public makeModel(data: MomentDBRow) {
    return new MomentModel(data);
  }

  async getMomentData(moment: MomentModel): Promise<Uint8Array | null> {
    debugLog("Feching moment data for md5", moment.md5_body);

    const maybeMedia = await this.momentRepository.getMediaForMoment(
      moment.id,
      moment.md5_body,
      moment.md5_envelope,
      moment.journalId,
      { entryId: moment.entryId },
      moment.data.type,
    );
    if (isValidMedia(maybeMedia)) {
      return maybeMedia as Uint8Array;
    } else {
      return null;
    }
  }

  async getMomentThumbnail(moment: MomentModel): Promise<Uint8Array | null> {
    if (!moment.thumbnail.md5_body) {
      return null;
    }
    const maybeMedia = await this.momentRepository.getMediaForMoment(
      moment.id,
      moment.thumbnail.md5_body,
      moment.thumbnail.md5_envelope,
      moment.journalId,
      { entryId: moment.entryId },
      "thumbnail",
    );
    if (isValidMedia(maybeMedia)) {
      return maybeMedia as Uint8Array;
    } else {
      return null;
    }
  }

  async getThumbnailUrl(journalId: string, entryId: string, momentId: string) {
    return this.momentRepository.getThumbnailUrl(journalId, entryId, momentId);
  }

  async getMomentById(journalId: string, entryId: string, momentId: string) {
    const moment = await this.momentRepository.getMomentById(
      journalId,
      entryId,
      momentId,
    );

    return moment ? new MomentModel(moment) : null;
  }

  async createLocalMomentFromFile(
    journalId: string,
    entryId: string,
    file: File,
  ) {
    return this.momentRepository.createLocalMomentFromFile(
      file,
      entryId,
      journalId,
    );
  }

  async deleteForEntry(journalId: string, entryId: string) {
    this.outbox.removeItemsForEntry(journalId, entryId);
    await this.momentRepository.removeLocalMomentsForEntries(journalId, [
      entryId,
    ]);
  }

  subscribeToMomentCountsByJournal(
    callback: (momentCounts: MomentCounts) => void,
    journalId: string,
  ) {
    return this.momentRepository.subscribeToMomentCountByJournal(
      callback,
      journalId,
    );
  }

  async addThumbnailToMoment(
    moment: MomentDBRow,
    thumbnail: {
      data: ArrayBuffer;
      width: number;
      height: number;
      contentType: string;
      md5: string;
    },
  ) {
    return this.momentRepository.addThumbnailToMoment(moment, thumbnail);
  }
}
