import { RichText, useBlockProps } from "@wordpress/block-editor";
import { escapeLessThan, escapeAmpersand } from "@wordpress/escape-html";
import { useI18n } from "@wordpress/react-i18n";

import { createParagraphBlock } from "@/components/Editor/rtj2gb/rtj2gb";

// this modifies Gutenberg's CodeEdit copied from
// packages/block-library/src/code/edit.js
// to disableFormats
export function CustomCodeEdit({
  attributes,
  setAttributes,
  onRemove,
  insertBlocksAfter,
}) {
  const { __ } = useI18n();
  const blockProps = useBlockProps();
  const content = attributes.content.toHTMLString
    ? attributes.content.toHTMLString({ preserveWhiteSpace: true })
    : attributes.content;

  const clean = escapeLessThan(escapeAmpersand(content));

  return (
    <pre {...blockProps}>
      <RichText
        tagName="code"
        value={clean}
        onChange={(content) => setAttributes({ content })}
        onRemove={onRemove}
        placeholder={__("Write code…")}
        aria-label={__("Code")}
        preserveWhiteSpace
        __unstablePastePlainText
        __unstableDisableFormats
        __unstableOnSplitAtDoubleLineEnd={() =>
          insertBlocksAfter(createParagraphBlock(""))
        }
      />
    </pre>
  );
}
