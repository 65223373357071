import { PBCPromptAPICaller } from "@/data/api_callers/PBCPromptAPICaller";
import { PBCPromptRepository } from "@/data/repositories/PBCPromptRepository";

export class PBCPromptController {
  constructor(
    private pbcPromptAPICaller: PBCPromptAPICaller,
    private pbcPromptRepository: PBCPromptRepository,
  ) {}

  async sync() {
    const pbcPrompts = await this.pbcPromptAPICaller.fetchPBCPrompts();
    if (pbcPrompts.length > 0) {
      this.pbcPromptRepository.savePBCPrompts(pbcPrompts);
    }
  }

  async getPBCPromptById(id: string) {
    return await this.pbcPromptRepository.getPBCPromptById(id);
  }

  async getPBCPromptsByIds(ids: string[]) {
    return await this.pbcPromptRepository.getPBCPromptsByIds(ids);
  }
}
