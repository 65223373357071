import { ThemeUICSSObject } from "@theme-ui/css";
import { Icon } from "@wordpress/components";

const styles: ThemeUICSSObject = {
  cursor: "pointer",
  backgroundColor: "black",
  borderRadius: "xs",
  height: "24px",
  opacity: "0.5",
  "> svg": { fill: "white" },
};

export const FullscreenButton = ({
  onClick,
  icon,
  className,
  label,
}: {
  onClick: () => void;
  icon: JSX.Element;
  className?: string;
  label?: string;
}) => {
  return (
    <div
      role="button"
      sx={styles}
      onClick={onClick}
      className={className}
      aria-label={label}
    >
      <Icon icon={icon} />
    </div>
  );
};
