import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { getEntryPreview } from "@/components/EditorPanel/GutenbergEntryTools";
import { ConcealedEntry } from "@/components/EntryPreview/ConcealedEntry";
import { EntryMedia } from "@/components/EntryPreview/EntryMedia";
import { EntryFooter } from "@/components/Search/EntryFooter";
import { EntryPreview } from "@/components/Search/EntryPreview";
import { SharedEntryInfoPreview } from "@/components/SharedJournals/SharedEntryInfoPreview";
import { MaybeLink } from "@/components/TimelineView/MaybeLink";
import { ENTRY_LIST_ITEM_HEIGHT } from "@/components/TimelineView/constants";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { ReactionDBRow } from "@/data/db/migrations/reaction";
import { EntryModel } from "@/data/models/EntryModel";
import { primaryViewState, searchViewState } from "@/view_state/ViewStates";

type EntryListItemProps = {
  globalEntryID: GlobalEntryID;
  height?: number;
  link?: boolean;
  baseURL: string;
  isEntryRevealed: (globalEntryId: GlobalEntryID) => boolean;
};

export const EntryListItem: React.FC<EntryListItemProps> = observer(
  ({
    globalEntryID,
    isEntryRevealed,
    baseURL,
    height = ENTRY_LIST_ITEM_HEIGHT,
    link = true,
  }) => {
    const { id: entryId, journal_id: journalId } = globalEntryID;
    const { getJournalById } = primaryViewState;
    const entryJournal = getJournalById(journalId);
    if (!entryJournal) {
      return null;
    }
    const [entry, setEntry] = useState<EntryModel | null>(null);
    const [reactions, setReactions] = useState<ReactionDBRow[] | null>(null);
    const { reactionRepository, entryStore } = d1Classes;
    const conceal = !isEntryRevealed(globalEntryID);

    const { searchString } = searchViewState;
    const entryText = entry ? getEntryPreview(entry, searchString) : "";
    const hasText = entryText.length > 0;

    const invertedText = false;

    const journalColor = "surfaceActive";

    const bgColor = "transparent";

    useEffect(() => {
      const getReactions = async () => {
        const _reactions = await reactionRepository.getReactionsForEntry(
          journalId,
          entryId,
        );
        setReactions(_reactions);
      };

      const getEntry = async () => {
        const _entry = await entryStore.getEntryById(journalId, entryId);
        if (_entry) {
          setEntry(_entry);
        }
      };
      getEntry();
      getReactions();
    }, [journalId, entryId]);

    return (
      <MaybeLink
        baseURL={baseURL}
        entry={entry}
        height={height}
        link={link}
        bgColor={bgColor}
        journalColor={journalColor}
        is_unread={false}
        invertedText={invertedText}
      >
        {entry ? (
          <>
            <div
              sx={{ display: "flex", width: "100%", alignItems: "flex-start" }}
            >
              {!conceal ? (
                <>
                  <div
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                      overflow: "hidden",
                    }}
                  >
                    {hasText ? (
                      <EntryPreview entry={entry} entryText={entryText} />
                    ) : (
                      <EntryMedia
                        className="search-entry-media-list"
                        entry={entry}
                        bgColor={bgColor}
                        entryJournal={entryJournal!}
                        mediaOnly
                        mediaAmount={10}
                        sx={{
                          ml: 0,
                          "&::after": { right: 3 },
                        }}
                      />
                    )}
                    <div>
                      <EntryFooter entry={entry} entryJournal={entryJournal} />
                      {entryJournal?.is_shared && !conceal && (
                        <SharedEntryInfoPreview
                          creatorId={entry.creatorUserId}
                          reactions={reactions || []}
                          bgColor={bgColor}
                        />
                      )}
                    </div>
                  </div>
                  {hasText && (
                    <EntryMedia
                      className="search-entry-media-list"
                      entry={entry}
                      bgColor={bgColor}
                      entryJournal={entryJournal!}
                      mediaAmount={10}
                      sx={{
                        ml: 3,
                      }}
                    />
                  )}
                </>
              ) : (
                <ConcealedEntry showDate={true} />
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </MaybeLink>
    );
  },
);
