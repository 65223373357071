import { useI18n } from "@wordpress/react-i18n";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";
import { ToggleSetting } from "@/components/Settings/ToggleSetting";

export const DebugLogging: React.FC = () => {
  const { __ } = useI18n();
  const [debugLoggingEnabled, setDebugLoggingEnabled] = useState(true);
  d1Classes.keyValueStore.subscribe<boolean>("debug-logging", (value) => {
    setDebugLoggingEnabled(value || false);
  });

  return (
    <ToggleSetting
      label={__("Debug Logging")}
      checked={debugLoggingEnabled}
      onChange={() => {
        d1Classes.keyValueStore.set("debug-logging", !debugLoggingEnabled);
      }}
    />
  );
};
