import { Flex, FlexItem } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { FullScreenLoadingMessage } from "@/components/FullScreenLoadingMessage";
import { FooterLink } from "@/components/LoggedOut/FooterLink";
import { Heading } from "@/components/LoggedOut/Heading";
import { Message } from "@/components/LoggedOut/Message";
import { encryptMasterKey } from "@/crypto/DOCrypto/utils";
import { LoggedOut } from "@/layouts/LoggedOut";
import { setDocumentTitle } from "@/utils/set-document-title";
import { primaryViewState } from "@/view_state/ViewStates";

const CompleteQRLogin = observer(() => {
  setDocumentTitle("Complete Login");

  const { __ } = useI18n();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const searchParams = new URLSearchParams(document.location.search);
  const secret = searchParams.get("token") ?? "";
  const key = decodeURIComponent(document.location.hash.split("#")[1]);
  const masterKey = primaryViewState.masterKeyString;

  useEffect(() => {
    if (!secret || !key) {
      setIsLoading(false);
      return;
    }

    const sendData = async () => {
      setIsLoading(true);

      const payload = masterKey ? await encryptMasterKey(masterKey, key) : null;
      const res = await d1Classes.fetchWrapper.fetchAPI(
        "/v2/users/login/qr",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ secret, payload }),
        },
        {
          // If the server returns a 403, it means fail to login, we want to keep user on this page
          // otherwise, it will be redirected to login page by AuthProvider
          expectedStatusCodes: [403],
        },
      );

      if (res.ok) {
        setIsLoginSuccess(true);
      }

      setIsLoading(false);
    };

    sendData();
  }, [key, secret, masterKey]);

  return (
    <LoggedOut background="surface_light1_dark1">
      <div
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Flex sx={{ flexDirection: "column", justifyContent: "center" }}>
          {isLoading && <FullScreenLoadingMessage />}
          {!isLoading &&
            (!isLoginSuccess ? (
              <>
                <div sx={{ width: "320px", maxWidth: "100%" }}>
                  <Heading>{__("Unable to Log In to Day One")}</Heading>
                  <Message sx={{ mt: 6 }}>
                    {__(
                      "To log in with the QR code make sure to scan it on a mobile device where you are currently logged in to the Day One app.",
                    )}
                  </Message>
                </div>
                <div>
                  <FooterLink href="/login">
                    ← {__("Back to log in")}
                  </FooterLink>
                  <FooterLink external href="https://dayoneapp.com">
                    ← {__("Back to dayoneapp.com")}
                  </FooterLink>
                </div>
              </>
            ) : (
              <FlexItem>{__("Login successful")}</FlexItem>
            ))}
        </Flex>
      </div>
    </LoggedOut>
  );
});

export default CompleteQRLogin;
