import { useBlockProps } from "@wordpress/block-editor";
import { Disabled } from "@wordpress/components";
import { video } from "@wordpress/icons";
import { useState } from "react";

import { FailedMedia } from "@/components/Editor/components/FailedMedia";
import { LoadingMediaPlaceholder } from "@/components/Editor/components/LoadingMediaPlaceholder";
import { TopRightButtonWrapper } from "@/components/SyncableImage/TopRightButton";
import { useMoment } from "@/data/hooks/moments";
import { dayOneBlue, Z_INDEX_GALLERY_MEDIA_BUTTONS } from "@/styles/theme";
import { getMediaWidth } from "@/utils/gallery";

export type BlobVideoProps = {
  journalId: string;
  entryId: string;
  clientId: string;
  aspectRatio?: number;
  isSelected: boolean;
  galleryHeight?: number;
  blockClientId: string;
};

// Display a syncable video by MD5
export const SyncableVideo: React.FC<BlobVideoProps> = ({
  journalId,
  entryId,
  clientId,
  aspectRatio,
  isSelected,
  galleryHeight,
  blockClientId,
}) => {
  const { blob, isLoading, moment } = useMoment(journalId, entryId, clientId);
  const blockProps = useBlockProps({
    style: {
      border: "none",
      height: galleryHeight ? `${galleryHeight}px` : "auto",
      width:
        aspectRatio && galleryHeight
          ? `${getMediaWidth(aspectRatio, galleryHeight)}px`
          : undefined,
      boxShadow:
        aspectRatio !== undefined
          ? `0 0 0 1px ${isSelected ? dayOneBlue : "transparent"}`
          : undefined,
    },
  });
  const [isHovered, setIsHovered] = useState(false);

  if (isLoading) {
    if (aspectRatio) {
      return (
        <LoadingMediaPlaceholder
          styles={{
            flexBasis: 0,
            flexGrow: aspectRatio,
            aspectRatio,
          }}
        />
      );
    }

    if (moment) {
      return (
        <LoadingMediaPlaceholder
          key={moment.id}
          height={moment.height || undefined}
          width={moment.width || undefined}
        />
      );
    }

    return <LoadingMediaPlaceholder />;
  }

  if (blob && moment) {
    return (
      <div
        {...blockProps}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        data-momentid={moment?.id}
        sx={{
          "& video": {
            p: "1px",
            borderRadius: aspectRatio ? undefined : "3px",
            border: aspectRatio ? undefined : "3px solid",
            borderColor: isSelected ? dayOneBlue : "transparent",
            objectFit: "cover",
            height: "100%",
          },
          // This makes sure the "Disabled" component shows our video fully
          " > div": {
            display: "flex",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          },
          ...(isHovered && {
            "&::before": {
              pointerEvents: "none",
              display: "block",
              position: "absolute",
              width: "100%",
              height: "100%",
              content: "''",
              bg: "white",
              opacity: 0.2,
              // This is to make sure the draggable button is still visible
              zIndex: Z_INDEX_GALLERY_MEDIA_BUTTONS - 1,
            },
          }),
        }}
      >
        <Disabled isDisabled={!isSelected}>
          <video controls>
            <source src={URL.createObjectURL(blob)} />
          </video>

          <TopRightButtonWrapper
            blockClientId={blockClientId}
            showDragButton={!!galleryHeight && isHovered}
          />
        </Disabled>
      </div>
    );
  }

  if (aspectRatio) {
    return (
      <div
        {...blockProps}
        sx={{
          div: {
            my: 0,
          },
        }}
      >
        <FailedMedia icon={video} moment={moment} />
      </div>
    );
  }

  return <FailedMedia icon={video} moment={moment} />;
};
