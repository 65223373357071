import { registerBlockType, unregisterBlockType } from "@wordpress/blocks";
import { useDispatch } from "@wordpress/data";
import { observer } from "mobx-react-lite";
import { useEffect, useLayoutEffect, useState } from "react";

// TODO - it would be nice if we could extract this dependency on components from the main application.
import {
  IMAGE_BLOCK_ID,
  ImageBlockEditProps,
} from "@/components/Editor/blocks/constants";
import { GalleryMediaDropZone } from "@/components/Editor/components/GalleryMediaDropZone";
import { useUpload } from "@/components/Editor/hooks/mediaUpload";
import { blockIsRegistered } from "@/components/Editor/utils/register-blocks";
import { SyncableImage } from "@/components/SyncableImage/SyncableImage";
import { mediaTransforms } from "@/utils/block-helper";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

export const ImageBlock: React.FC<ImageBlockEditProps> = observer(
  ({ attributes, setAttributes, isSelected, clientId: blockClientId }) => {
    const globalEntryID = primaryViewState.selectedGlobalEntryID;
    const [fileNotRegistered, setFileNotRegistered] = useState(false);
    const { removeBlock } = useDispatch("core/block-editor");

    const { uploadFile } = useUpload(
      globalEntryID,
      viewStates,
      attributes,
      setAttributes,
      setFileNotRegistered,
    );

    // Upload a temporary file on mount.
    useEffect(() => {
      uploadFile();
    }, []);

    useLayoutEffect(() => {
      if (fileNotRegistered) {
        removeBlock(blockClientId);
      }
    }, [fileNotRegistered]);

    const ImageComponent = (
      <SyncableImage
        journalId={attributes.journalId}
        entryId={attributes.entryId}
        clientId={attributes.clientId}
        aspectRatio={attributes.aspectRatio}
        isSelected={isSelected}
        galleryHeight={attributes.galleryHeight}
        blockClientId={blockClientId}
      />
    );

    // Make sure we only add the drop zone if we are in a gallery
    if (!attributes.galleryHeight) {
      return ImageComponent;
    }
    return (
      <GalleryMediaDropZone blockId={blockClientId}>
        {ImageComponent}
      </GalleryMediaDropZone>
    );
  },
);

const { imageTransform: transforms } = mediaTransforms;

export const register = () => {
  if (!blockIsRegistered(IMAGE_BLOCK_ID)) {
    // @ts-ignore - seems types are not inline with code here.
    registerBlockType(IMAGE_BLOCK_ID, {
      edit: (props: ImageBlockEditProps) => <ImageBlock {...props} />,
      title: "Image",
      category: "media",
      textdomain: "default",
      description: "Day One Image Block",
      apiVersion: 2,
      supports: {
        lock: false,
        html: false,
        inserter: false,
      },
      transforms,
      attributes: {
        journalId: {
          type: "string",
        },
        entryId: {
          type: "string",
        },
        clientId: {
          type: "string",
        },
        src: {
          type: "string",
          source: "attribute",
          selector: "img",
          attribute: "src",
          role: "content",
        },
        aspectRatio: {
          type: "number",
        },
        galleryHeight: {
          type: "number",
        },
      },
    });
  }
};

export const unregister = () => {
  if (blockIsRegistered(IMAGE_BLOCK_ID)) {
    return unregisterBlockType(IMAGE_BLOCK_ID);
  }
  return false;
};
