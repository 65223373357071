import { Spinner } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useState } from "react";

type FullScreenLoadingMessageProps = {
  message?: string;
  // Milliseconds before the message is shown.
  delay?: number;
};

export const FullScreenLoadingMessage: React.FC<
  FullScreenLoadingMessageProps
> = ({ message, delay }) => {
  const [show, setShow] = useState(delay ? false : true);
  const { __ } = useI18n();
  if (!message) message = __("Loading...");

  useEffect(() => {
    if (delay) {
      const timeout = setTimeout(() => setShow(true), delay);
      return () => clearTimeout(timeout);
    }
  }, []);

  return show ? (
    <div
      sx={{
        display: "flex",
        width: "100%",
        height: "100dvh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner />
      <div>{message}</div>
    </div>
  ) : (
    <></>
  );
};
