import { JournalPresetAPICaller } from "@/data/api_callers/JournalPresetAPICaller";
import { JournalPresetRepository } from "@/data/repositories/JournalPresetRepository";

export class JournalPresetController {
  constructor(
    private journalPresetAPICaller: JournalPresetAPICaller,
    private journalPresetRepository: JournalPresetRepository,
  ) {}

  async sync() {
    const presets = await this.journalPresetAPICaller.fetchJournalPresets();
    if (presets.length > 0) {
      this.journalPresetRepository.savePresets(presets);
    }
  }

  async getJournalPresetById(id: string) {
    const presets = await this.journalPresetRepository.getJournalPresetById(id);
    return presets;
  }
}
