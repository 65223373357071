import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";

import { GAP } from "@/components/TimelineView/EntryListItem";
import { LinkWrapper } from "@/components/TimelineView/LinkWrapper";
import { EntryModel } from "@/data/models/EntryModel";

type Props = {
  link: boolean;
  baseURL: string;
  entry: EntryModel | null;
  height: number;
  bgColor: string;
  journalColor: string | null;
  is_unread?: boolean;
  invertedText?: boolean;
  selected?: boolean;
};

export const MaybeLink: React.FC<PropsWithChildren<Props>> = observer(
  ({
    children,
    link,
    baseURL,
    entry,
    height,
    bgColor,
    journalColor,
    is_unread,
    invertedText,
    selected = false,
  }) =>
    link ? (
      <LinkWrapper
        baseURL={baseURL}
        entry={entry}
        selected={selected}
        height={height}
        bgColor={bgColor}
        journalColor={journalColor}
        is_unread={is_unread}
        invertedText={invertedText}
      >
        {children}
      </LinkWrapper>
    ) : (
      <div
        sx={{
          height: `${height - GAP}px`,
        }}
      >
        {children}
      </div>
    ),
);
MaybeLink.displayName = "MaybeLink";
