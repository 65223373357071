import { DODexie } from "@/data/db/dexie_db";
import { PBCPromptDBRow } from "@/data/db/migrations/pbc_prompts";

export class PBCPromptRepository {
  constructor(protected db: DODexie) {}

  async savePBCPrompts(prompts: PBCPromptDBRow[]) {
    await this.db.pbc_prompts.bulkPut(prompts);
  }

  async getPBCPromptById(id: string) {
    return this.db.pbc_prompts.where("id").equals(id).first();
  }

  async getPBCPromptsByIds(iDs: string[]) {
    return this.db.pbc_prompts.where("id").anyOf(iDs).toArray();
  }
}
