import Dexie, { Table } from "dexie";

import {
  runMigration9,
  runMigration10,
  runMigration14,
  runMigration15,
  runMigration16,
  runMigration17,
  runMigration18,
  runMigration19,
  runMigration21,
  runMigration22,
  runMigration23,
  runMigration24,
  runMigration25,
  runMigration32,
  runMigration33,
  runMigration34,
  runMigration38,
  runMigration39,
  runMigration40,
  runMigration42,
  runMigration43,
  runMigration45,
  runMigration47,
  runMigration48,
  runMigration51,
  resyncJournals,
  runMigration55,
  runMigration56,
  runMigration57,
  runMigration58,
  runMigration59,
  runMigration66,
  runMigration67,
} from "@/data/db/db_migrations";
import { CommentDBRow } from "@/data/db/migrations/comment";
import { ContentKeysDBRow } from "@/data/db/migrations/content_keys";
import { EntryDBRow, EntryMoveDBRow } from "@/data/db/migrations/entry";
import { ImportExportDBRow } from "@/data/db/migrations/import_export";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { JournalCoverDBRow } from "@/data/db/migrations/journal_cover";
import { JournalParticipantDBRow } from "@/data/db/migrations/journal_participant";
import { JournalSyncInfo } from "@/data/db/migrations/journal_sync_info";
import { MediaDBRow, MomentDBRow } from "@/data/db/migrations/moment";
import { NotificationDBRow } from "@/data/db/migrations/notification";
import { PartialMediaDBRow } from "@/data/db/migrations/partial_media";
import { PBCPromptDBRow } from "@/data/db/migrations/pbc_prompts";
import { JournalPresetDBRow } from "@/data/db/migrations/presets";
import {
  CommentReactionDBRow,
  ReactionDBRow,
} from "@/data/db/migrations/reaction";
import { SearchDBRow } from "@/data/db/migrations/search";
import { SyncStateDBRow } from "@/data/db/migrations/sync-state";
import { TagDBRow } from "@/data/db/migrations/tag";
import {
  TemplateDBRow,
  PBCTemplateCategory,
  PBCTemplateDBRow,
} from "@/data/db/migrations/template";
import { UserDBRow } from "@/data/db/migrations/user";
import { UserKeysDBRow } from "@/data/db/migrations/user_keys";
import { VaultDBRow } from "@/data/db/migrations/vault";
import { OutboxItem } from "@/data/models/OutboxTypes";

// Indexes for querying a specific journal's entries from the entry list:
const undeletedEntriesByJournal = `[journal_id+is_deleted+date+id]`;
const undeletedEntriesByJournal_editDate = `[journal_id+is_deleted+user_edit_date+id]`;
// Now, for "all entries". The same as above, but drop the journal_id at the end so that entries
// from all journals get integrated when sorting by date, but we can still include the journal ID in the result.
const undeletedEntriesByJournal_allEntries = `[hide_all_entries+is_deleted+date+journal_id+id]`;
const undeletedEntriesByJournal_editDate_allEntries = `[is_deleted+user_edit_date+journal_id+id]`;
// As above but including if the entry is shared
// Indexes for querying a specific journal's entries from the entry list:
const undeletedEntriesByJournal_shared = `[journal_id+is_deleted+date+id+is_shared+is_pinned]`;
const undeletedEntriesByJournal_editDate_shared = `[journal_id+is_deleted+user_edit_date+id+is_shared+is_pinned]`;
// Now, for "all entries". The same as above, but drop the journal_id at the end so that entries
// from all journals get integrated when sorting by date, but we can still include the journal ID in the result.
const undeletedEntriesByJournal_allEntries_shared = `[hide_all_entries+is_deleted+date+journal_id+id+is_shared+is_pinned]`;
// Index to get unread entries in shared journals
const sharedUnreadEntriesByJournal = `[is_shared+journal_id+id+unread_marker_id]`;
// Index to get entries by user on shared journal
const sharedEntriesByUserJournal = `[is_shared+journal_id+creator_user_id]`;
// Index to get entries with promptID
const entriesByPromptID = "promptID";
const journalEntriesByPrompt = `[is_deleted+journal_id+promptID]`;
// Index to get reactions by user on shared journal
const sharedReactionsByUserJournal = `[journal_id+user_id]`;
// Indexes matching moments to medias and find orphaned medias.
const momentsByThumbnailMd5 = `thumbnail_md5_body`;
const momentsByMd5 = `md5_body`;

export type MomentsPk = readonly [
  string /*journal_id*/,
  string /*entry_id*/,
  string /*moment_id*/,
];

export type EntryCounts = {
  journal_id: string;
  count: number;
  photos: number;
  videos: number;
  audio: number;
};

export class DODexie extends Dexie {
  entries!: Table<EntryDBRow>;
  moments!: Table<MomentDBRow, MomentsPk>;
  medias!: Table<MediaDBRow>;
  journals!: Table<JournalDBRow>;
  users!: Table<UserDBRow>;
  user_keys!: Table<UserKeysDBRow>;
  vaults!: Table<VaultDBRow>;
  sync_states!: Table<SyncStateDBRow>;
  kv!: Table<{ key: string; value: any }>;
  outbox_items!: Table<OutboxItem>;
  entry_counts_cache!: Table<EntryCounts>;
  journal_sync_info!: Table<JournalSyncInfo>;
  templates!: Table<TemplateDBRow>;
  tags!: Table<TagDBRow>;
  reactions!: Table<ReactionDBRow>;
  participants!: Table<JournalParticipantDBRow>;
  notifications!: Table<NotificationDBRow>;
  comments!: Table<CommentDBRow>;
  comment_reactions!: Table<CommentReactionDBRow>;
  content_keys!: Table<ContentKeysDBRow>;
  search!: Table<SearchDBRow>;
  journal_covers!: Table<JournalCoverDBRow>;
  journal_presets!: Table<JournalPresetDBRow>;
  pending_entry_updates!: Table<EntryMoveDBRow>;
  pbc_prompts!: Table<PBCPromptDBRow>;
  pbc_template_categories!: Table<PBCTemplateCategory>;
  pbc_templates!: Table<PBCTemplateDBRow>;
  partial_media!: Table<PartialMediaDBRow>;
  import_export!: Table<ImportExportDBRow>;

  constructor(dbName = "DODexie") {
    super(dbName);

    this.version(69).stores({
      import_export: `[type+id]`,
    });

    this.version(68).stores({
      partial_media: `[id+chunk_number]`,
    });

    this.version(67).upgrade((tx) => runMigration67(tx.db as DODexie));

    this.version(66)
      .stores({
        pbc_prompts: `id`,
        prompts: null,
      })
      .upgrade((tx) => runMigration66(tx.db as DODexie));

    this.version(65).stores({
      pbc_template_categories: `id, sort_idx`,
      pbc_templates: `id`,
      template_gallery_categories: null,
      template_gallery_items: null,
    });

    this.version(64).stores({
      outbox_items: `id, userModifiedAt, [journalId+entryId], [entryId+action], [journalId+entryId+action], entryMoveId`,
      pending_entry_updates: `id`,
    });

    this.version(63).stores({
      outbox_items: `id, userModifiedAt, [journalId+entryId], [entryId+action], [journalId+entryId+action]`,
    });

    this.version(62)
      .stores({
        entries: `[journal_id+id], ${undeletedEntriesByJournal}, ${undeletedEntriesByJournal_editDate}, ${undeletedEntriesByJournal_allEntries}, ${undeletedEntriesByJournal_editDate_allEntries}, ${sharedUnreadEntriesByJournal}, ${sharedEntriesByUserJournal}, ${undeletedEntriesByJournal_shared}, ${undeletedEntriesByJournal_editDate_shared}, ${undeletedEntriesByJournal_allEntries_shared}, ${entriesByPromptID}, ${journalEntriesByPrompt}`,
      })
      .upgrade((tx) => resyncJournals(tx.db as DODexie));

    this.version(61).stores({
      journal_presets: `id`,
      prompts: `id`,
    });

    this.version(60).upgrade((tx) => resyncJournals(tx.db as DODexie));

    this.version(59)
      .stores({
        entries: `[journal_id+id], ${undeletedEntriesByJournal}, ${undeletedEntriesByJournal_editDate}, ${undeletedEntriesByJournal_allEntries}, ${undeletedEntriesByJournal_editDate_allEntries}, ${sharedUnreadEntriesByJournal}, ${sharedEntriesByUserJournal}, ${undeletedEntriesByJournal_shared}, ${undeletedEntriesByJournal_editDate_shared}, ${undeletedEntriesByJournal_allEntries_shared}, ${entriesByPromptID}`,
      })
      .upgrade((tx) => runMigration59(tx));

    this.version(58).upgrade((tx) => runMigration58(tx));

    this.version(57)
      .stores({
        journal_covers: `journal_id`,
      })
      .upgrade((tx) => runMigration57(tx));

    // This new version creates a new table to keep search text to keep it separate from the entry table
    // We run a migration to remove the old searchText field from the entries table.
    this.version(56)
      .stores({
        search: `[journal_id+entry_id]`,
      })
      .upgrade((tx) => runMigration56(tx));

    // This migration is ran to update the secure key value store to use a new derived key instead of one
    // coming from an environment variable. We need to get all the existing values that are encrypted,
    // decrypt them, then encrypt them again with the new key
    this.version(55).upgrade((tx) => runMigration55(tx.db as DODexie));

    this.version(54).upgrade((tx) => resyncJournals(tx.db as DODexie));

    this.version(53).upgrade((tx) => resyncJournals(tx.db as DODexie));

    this.version(52).stores({
      entries: `[journal_id+id], ${undeletedEntriesByJournal}, ${undeletedEntriesByJournal_editDate}, ${undeletedEntriesByJournal_allEntries}, ${undeletedEntriesByJournal_editDate_allEntries}, ${sharedUnreadEntriesByJournal}, ${sharedEntriesByUserJournal}, ${undeletedEntriesByJournal_shared}, ${undeletedEntriesByJournal_editDate_shared}, ${undeletedEntriesByJournal_allEntries_shared}, ${entriesByPromptID}`,
    });
    this.version(51).upgrade((tx) => runMigration51(tx));

    this.version(50).upgrade((tx) => {
      return tx.table("sync_states").delete("templatesCursor");
    });

    this.version(49).stores({
      entries: `[journal_id+id], ${undeletedEntriesByJournal}, ${undeletedEntriesByJournal_editDate}, ${undeletedEntriesByJournal_allEntries}, ${undeletedEntriesByJournal_editDate_allEntries}, ${sharedUnreadEntriesByJournal}, ${sharedEntriesByUserJournal}, ${undeletedEntriesByJournal_shared}, ${undeletedEntriesByJournal_editDate_shared}, ${undeletedEntriesByJournal_allEntries_shared},  ${entriesByPromptID}`,
    });

    this.version(48).upgrade((tx) => runMigration48(tx));

    this.version(47).upgrade((tx) => runMigration47(tx));

    this.version(46).stores({
      pending_journals: null,
    });

    this.version(45).upgrade((tx) => runMigration45(tx.db as DODexie));

    this.version(44).stores({
      moments: `[journal_id+entry_id+id], ${momentsByMd5}, ${momentsByThumbnailMd5}, journal_id, id`,
    });

    this.version(43)
      .stores({ templates: `clientId`, templatestemp: null })
      .upgrade((tx) => runMigration43(tx));

    this.version(42)
      .stores({ templatestemp: `clientId`, templates: null })
      .upgrade((tx) => runMigration42(tx));

    this.version(41).stores({ content_keys: `fingerprint` });

    this.version(40).upgrade((tx) => runMigration40(tx.db as DODexie));

    this.version(39).upgrade((tx) => runMigration39(tx));

    this.version(38)
      .stores({
        template_gallery_categories: `id`,
        template_gallery_items: `id`,
      })
      .upgrade((tx) => runMigration38(tx));

    this.version(37).stores({
      entries: `[journal_id+id], ${undeletedEntriesByJournal}, ${undeletedEntriesByJournal_editDate}, ${undeletedEntriesByJournal_allEntries}, ${undeletedEntriesByJournal_editDate_allEntries}, ${sharedUnreadEntriesByJournal}, ${sharedEntriesByUserJournal}, ${undeletedEntriesByJournal_shared}, ${undeletedEntriesByJournal_editDate_shared}, ${undeletedEntriesByJournal_allEntries_shared}`,
    });
    // Add some new indexes to the existing tables for faster removal when entries are deleted
    this.version(36).stores({
      comment_reactions: `[journal_id+entry_id+comment_id+user_id], [journal_id+user_id], [journal_id+entry_id]`,
      notifications: `id, created_date, read_date, seen_date, deleted_date, [metadata.journal_id+metadata.entry_id]`,
    });

    this.version(35).stores({
      entries: `[journal_id+id], ${undeletedEntriesByJournal}, ${undeletedEntriesByJournal_editDate}, ${undeletedEntriesByJournal_allEntries}, ${undeletedEntriesByJournal_editDate_allEntries}, ${sharedUnreadEntriesByJournal}, ${sharedEntriesByUserJournal}, ${undeletedEntriesByJournal_shared}, ${undeletedEntriesByJournal_editDate_shared}, ${undeletedEntriesByJournal_allEntries_shared}`,
    });

    this.version(34)
      .stores({
        comment_reactions: `[journal_id+entry_id+comment_id+user_id], [journal_id+user_id]`,
      })
      .upgrade((tx) => runMigration34(tx));

    this.version(33)
      .stores({
        notifications: `id, created_date, read_date, seen_date, deleted_date`,
      })
      .upgrade((tx) => runMigration33(tx));

    this.version(32).upgrade((tx) => runMigration32(tx.db as DODexie));

    this.version(31).stores({
      pending_journals: `id, owner_id`,
    });

    this.version(30).stores({
      comments: `id, [journal_id+entry_id], [journal_id+entry_id+created_at], [journal_id+entry_id+updated_at], [journal_id+entry_id+author_id], [journal_id+entry_id+deleted_at]`,
    });

    this.version(29).stores({
      entries: `[journal_id+id], ${undeletedEntriesByJournal}, ${undeletedEntriesByJournal_editDate}, ${undeletedEntriesByJournal_allEntries}, ${undeletedEntriesByJournal_editDate_allEntries}, ${sharedUnreadEntriesByJournal}, ${sharedEntriesByUserJournal}`,
      reactions: `[journal_id+entry_id+user_id], ${sharedReactionsByUserJournal}`,
    });
    this.version(28).stores({
      entries: `[journal_id+id], ${undeletedEntriesByJournal}, ${undeletedEntriesByJournal_editDate}, ${undeletedEntriesByJournal_allEntries}, ${undeletedEntriesByJournal_editDate_allEntries}, ${sharedUnreadEntriesByJournal}`,
    });
    this.version(27).stores({
      notifications: `id, created_date, read_date, seen_date`,
    });
    this.version(26).stores({
      participants: `user_id`,
      reactions: `[journal_id+entry_id+user_id]`,
    });
    this.version(25)
      .stores({ cached_keys: null })
      .upgrade((tx) => runMigration25(tx));

    this.version(24).upgrade((tx) => runMigration24(tx.db as DODexie));

    this.version(23).upgrade(async (tx) => runMigration23(tx.db as DODexie));

    this.version(22).upgrade((tx) => runMigration22(tx.db as DODexie));

    this.version(21)
      .stores({
        tags: `[tag+journal_id+entry_id], [journal_id+entry_id]`,
      })
      .upgrade(async (tx) => runMigration21(tx.db as DODexie));
    this.version(20).stores({
      entries: `[journal_id+id], ${undeletedEntriesByJournal}, ${undeletedEntriesByJournal_editDate}, ${undeletedEntriesByJournal_allEntries}, ${undeletedEntriesByJournal_editDate_allEntries}`,
    });

    this.version(19).upgrade(async (tx) => runMigration19(tx.db as DODexie));
    this.version(18).upgrade(async (tx) => runMigration18(tx.db as DODexie));
    this.version(17).upgrade(async (tx) => runMigration17(tx.db as DODexie));

    this.version(16).upgrade(async (tx) => runMigration16(tx.db as DODexie));

    this.version(15).upgrade(async (tx) => runMigration15(tx.db as DODexie));

    this.version(14).upgrade(async (tx) => runMigration14(tx.db as DODexie));

    this.version(13).upgrade((tx) => {
      tx.table("sync_states").delete("journalsCursor");
    });
    this.version(12).stores({ templates: `id, journalSyncID` });
    this.version(11).stores({ feature_flags: `name` });
    this.version(10).upgrade((tx) => runMigration10(tx.db as DODexie));
    this.version(9)
      .stores({
        entries: `[journal_id+id], ${undeletedEntriesByJournal}, ${undeletedEntriesByJournal_editDate}, ${undeletedEntriesByJournal_allEntries}, ${undeletedEntriesByJournal_editDate_allEntries}`,
        moments: `[journal_id+entry_id+id], ${momentsByMd5}, ${momentsByThumbnailMd5}`,
        medias: `md5`,
        journals: `id`,
        pending_journals: `id`,
        users: `local_id`,
        user_keys: `local_id`,
        vaults: `journal_id`,
        sync_states: `id`,
        kv: `key`,
        outbox_items: `id, userModifiedAt, [journalId+entryId]`,
        entry_counts_cache: `journal_id`,
        journal_sync_info: `journal_id, status`,
        cached_keys: `id`,
      })
      .upgrade((tx) => runMigration9(tx.db as DODexie));
  }
}

export const dexieMaxKey = () => {
  // This is very strange, but in our test environment (on top of a mock IndexedDB implementation)
  // passing the the same copy of a nested empty array (which is what Dexie.maxKey is) to Dexie's
  // where() method for multiple keys causes the query to fail. So we need to create a new copy of
  // the array every time. In prod, it works just fine, but in tests, it fails.
  if (process.env.NODE_ENV === "test") {
    return [[]];
  } else {
    return Dexie.maxKey;
  }
};
