import {
  EmbeddableContentNode,
  EmbeddableNode,
  Meta,
  RTJNode,
  TextNode,
} from "types/rtj-format";

import { RichTextJSON } from "@/../types/rtj-format";
import { MomentDBRow } from "@/data/db/migrations/moment";

export function isTextNode(node: RTJNode): node is TextNode {
  return !("embeddedObjects" in node);
}

export function momentToRTJ(moment: MomentDBRow): EmbeddableContentNode {
  let embedded: EmbeddableNode;
  switch (moment.type) {
    case "image":
      embedded = { identifier: moment.id, type: "photo" };
      break;
    case "audio":
      embedded = { identifier: moment.id, type: "audio" };
      break;
    case "video":
      embedded = { identifier: moment.id, type: "video" };
      break;
    case "pdfAttachment":
      embedded = { identifier: moment.id, type: "pdfAttachment" };
      break;
    default:
      throw new Error("Encountered invalid moment type", moment.type);
  }
  return {
    embeddedObjects: [embedded],
  };
}

export const decodeRichTextJson = (
  richText?: string | RichTextJSON | Record<string, never>,
) => {
  let json;
  const empty = { contents: [] } as RichTextJSON;
  if (!richText) return empty;

  if (typeof richText === "string") {
    try {
      json = JSON.parse(richText) as RichTextJSON;
      if (!json.contents) {
        json.contents = [];
      }
    } catch (e) {
      // Android doesn't send a richTextJSON field currently only a body.
      // Quick hack so we can at least list entries created on Android
      json = empty;
    }
  } else {
    json = richText as RichTextJSON;
  }

  return json;
};

export const getCommentContentFromRTJ = (rtj: RichTextJSON) => {
  return rtj.contents.length > 0 && isTextNode(rtj.contents[0])
    ? rtj.contents[0].text
    : "";
};

export const getAppCode = () => {
  if (!process.env.APP_CODE) {
    return 1;
  }
  const appCodeAsDate = new Date(process.env.APP_CODE);
  if (isNaN(appCodeAsDate.valueOf())) {
    return 1;
  }
  return Math.round(appCodeAsDate.getTime() / 1000);
};

const metaDefaults = {
  version: 1,
  created: {
    version: getAppCode(),
    platform: "webapp",
  },
  "small-lines-removed": true,
};

export const createRichTextJsonString = (content: RTJNode[], meta?: Meta) => {
  return JSON.stringify({
    contents: content,
    meta: meta ?? metaDefaults,
  });
};
