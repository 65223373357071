import { journalColorValues } from "@/styles/JournalColorValues";
import { i18n } from "@/utils/i18n";

type JournalColor = {
  name: string;
  color: string;
  label: string;
};

const freeJournalColors: JournalColor[] = [
  {
    name: "dayOneBlue",
    color: journalColorValues.dayOneBlue,
    label: i18n.__("Day One Blue"),
  },
  { name: "aqua", color: journalColorValues.aqua, label: i18n.__("Aqua") },
  { name: "green", color: journalColorValues.green, label: i18n.__("Green") },
  { name: "honey", color: journalColorValues.honey, label: i18n.__("Honey") },
  {
    name: "watermelon",
    color: journalColorValues.watermelon,
    label: i18n.__("Watermelon"),
  },
  {
    name: "salmon",
    color: journalColorValues.salmon,
    label: i18n.__("Salmon"),
  },
  {
    name: "journalBlue",
    color: journalColorValues.journalBlue,
    label: i18n.__("Blue"),
  },
  {
    name: "pewter",
    color: journalColorValues.pewter,
    label: i18n.__("Pewter"),
  },
  {
    name: "tortilla",
    color: journalColorValues.tortilla,
    label: i18n.__("Tortilla"),
  },
  { name: "fire", color: journalColorValues.fire, label: i18n.__("Fire") },
  {
    name: "lavender",
    color: journalColorValues.lavender,
    label: i18n.__("Lavender"),
  },
  {
    name: "journalRed",
    color: journalColorValues.journalRed,
    label: i18n.__("Red"),
  },
];

const premiumJournalColors: JournalColor[] = [
  {
    name: "charcoal",
    color: journalColorValues.charcoal,
    label: i18n.__("Charcoal"),
  },
  { name: "stone", color: journalColorValues.stone, label: i18n.__("Stone") },
  { name: "lime", color: journalColorValues.lime, label: i18n.__("Lime") },
  {
    name: "hotPink",
    color: journalColorValues.hotPink,
    label: i18n.__("Hot Pink"),
  },
  {
    name: "magenta",
    color: journalColorValues.magenta,
    label: i18n.__("Magenta"),
  },
  { name: "iris", color: journalColorValues.iris, label: i18n.__("Iris") },
];

export const journalColorList = [...freeJournalColors, ...premiumJournalColors];
export const journalColors = { freeJournalColors, premiumJournalColors };
