import { ThemeUICSSObject } from "@theme-ui/core";
import {
  BlockList,
  BlockEditorProvider,
  // @ts-ignore
  BlockTools,
} from "@wordpress/block-editor";
import { SlotFillProvider } from "@wordpress/components";
// @ts-ignore - no types for this exist yet.
import { ShortcutProvider } from "@wordpress/keyboard-shortcuts";
import { useEffect, useRef } from "react";

import "@/styles/gutenberg.css";

import { AdditionalEntryInfo } from "@/components/Editor/components/AdditionalEntryInfo";
import { EditorThemeWrapper } from "@/components/Editor/theme/EditorThemeWrapper";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { EntryAuthorInfo } from "@/components/SharedJournals/EntryAuthorInfo";
import { useDevice } from "@/data/hooks/LayoutProvider";
import { Blocks } from "@/view_state/ActiveEntryViewState";

type PreviewerProps = {
  creatorId?: string | null;
  blocks: Blocks;
  height?: string;
  tags?: string[];
  showAdditionaInfo?: boolean;
  multiEntryView?: boolean;
  footer?: React.ReactNode;
  className?: string;
};
// P2 on the logic for using a partially disabled editor as a non-editable previewer
// https://wp.me/pdr0wL-2iO

export const Previewer: React.FC<PreviewerProps> = ({
  creatorId,
  blocks,
  height = "auto",
  showAdditionaInfo = true,
  multiEntryView,
  footer = null,
  className,
}) => {
  const { isMobile } = useDevice();
  const isDisabled = useRef(false);
  const _sx: ThemeUICSSObject = {
    height: height,
    overflow: "hidden",
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    padding: isMobile ? 4 : 6,
    "& .block-editor-block-list__insertion-point": {
      display: "none !important",
    },
    "& .invalidBlockMessage": {
      borderRadius: "xl",
      backgroundColor: "surface_light3_dark3",
      p: 4,
      my: 4,
    },
  };

  // Set all blocks to be non-editable
  useEffect(() => {
    isDisabled.current = false;
    let intervalTries = 0;
    const interval = setInterval(() => {
      const editable = document.querySelectorAll(
        ".editor-previewer [contenteditable=true]",
      );
      if (editable.length > 0 && !isDisabled.current) {
        editable.forEach((el) => {
          el.setAttribute("contenteditable", "false");
        });

        document.querySelectorAll(".editor-previewer input").forEach((el) => {
          el.setAttribute("disabled", "true");
        });
        isDisabled.current = true;
        clearInterval(interval);
      }
      intervalTries++;
      // Try for a max of 5 seconds (5000 / 100 = 50)
      if (intervalTries > 50) {
        clearInterval(interval);
      }
    }, 100);
  }, [blocks]);

  blocks = blocks.map((block) => {
    return {
      ...block,
      attributes: {
        ...block.attributes,
        lock: { remove: true, move: true },
      },
    };
  });

  return (
    <ErrorBoundary>
      <EditorThemeWrapper>
        <SlotFillProvider>
          <BlockEditorProvider
            value={blocks}
            settings={{ bodyPlaceholder: "", hasFixedToolbar: true }}
          >
            <div sx={_sx} className={className}>
              {creatorId && <EntryAuthorInfo creatorId={creatorId} />}
              {/* Shortcut provider produces a div we need to style */}
              <ShortcutProvider
                style={{
                  display: "flex",
                  justifyContent: multiEntryView ? "flex-start" : "center",
                  flexGrow: 1,
                }}
              >
                <BlockTools
                  className="blockTools editor-previewer"
                  sx={{
                    display: "flex",
                    ".block-list-appender.wp-block": { display: "none" },
                  }}
                >
                  <BlockList renderAppender={() => <></>} />
                </BlockTools>
              </ShortcutProvider>
              {showAdditionaInfo && (
                <AdditionalEntryInfo hideEntryTags={multiEntryView} />
              )}
            </div>
          </BlockEditorProvider>
        </SlotFillProvider>
        {footer}
      </EditorThemeWrapper>
    </ErrorBoundary>
  );
};
