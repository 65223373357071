import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";
import { Link } from "wouter";

import { GAP } from "@/components/TimelineView/EntryListItem";
import { EntryModel } from "@/data/models/EntryModel";
import { getIsToday, getIsYesterday, getIsThisYear } from "@/utils/date-helper";
import { viewStates } from "@/view_state/ViewStates";

type Props = {
  baseURL: string;
  entry: EntryModel | null;
  selected: boolean;
  height: number;
  bgColor: string;
  journalColor: string | null;
  is_unread?: boolean;
  invertedText?: boolean;
};

export const LinkWrapper: React.FC<PropsWithChildren<Props>> = observer(
  ({
    baseURL,
    entry,
    selected,
    height,
    bgColor,
    is_unread,
    journalColor,
    invertedText,
    children,
  }) => {
    const { __, _x } = useI18n();
    const getAriaLabel = () => {
      if (!entry) {
        return "";
      }
      const date = new Date(entry.date);
      const isToday = getIsToday(date);
      const isYesterday = getIsYesterday(date);
      const isThisYear = getIsThisYear(date);
      let label = " ";
      if (isToday) {
        label = __("Today");
      } else if (isYesterday) {
        label = __("Yesterday");
      } else if (isThisYear) {
        label = format(entry.date, "EEEE MMMM do");
      } else {
        label = format(entry.date, "EEEE MMMM do, yyyy");
      }
      label += ". ";

      const { window, getIndexForEntryId } = viewStates.timeline;
      const { totalEntryCount } = window;
      const index = getIndexForEntryId(entry.journalId, entry.id);
      label += sprintf(
        _x(
          "Entry %1$d of %2$d. ",
          "This is for screen readers to tell you where you are in the entry timeline, eg: Entry 12 of 200.",
        ),
        index + 1,
        totalEntryCount,
      );

      label += _x(
        "Move focus up and down with arrow keys.",
        "This is for screen readers to tell you how to move focus in the entry timeline.",
      );

      return label;
    };

    const thisEntryID = entry?.id;
    const thisJournalID = entry?.journalId;

    return (
      <Link asChild href={`${baseURL}/${thisEntryID}`}>
        <a
          // Normally you're not supposed to set tabIndex to -1, but
          // this is a special case where we want the focus to always
          // be on the VirtualFocusElement, but we want to be able to
          // right/middle click on the EntryListItem and open it in a new tab
          // We don't want to do this in search though
          tabIndex={selected ? undefined : -1}
          // data-is-entry-list-item is used by the ArrowKeyHandler to determine if the
          // active element is an entry list item
          data-is-entry-list-item="true"
          id={`entry-list-item-${thisEntryID}`}
          data-entry-id={thisEntryID}
          data-journal-id={thisJournalID}
          aria-label={getAriaLabel()}
          draggable={false}
          sx={{
            color: invertedText ? "textPrimaryInverse" : "textPrimary",
            display: "block",
            bg: bgColor,
            paddingRight: 2,
            height: `${height - GAP}px`,
            marginBottom: `${GAP}`,
            cursor: "pointer",
            overflow: "hidden",
            // focus shadow on the inside
            "&:focus": {
              boxShadow: "focusInner",
            },
            "&:focus-visible": {
              outline: 0,
            },
            "&:hover": {
              bg: selected ? bgColor : "surfaceHover",
            },
            "&:hover #reaction-summary svg": {
              boxShadow: (theme) =>
                `0 0 0 2px ${
                  selected ? bgColor : theme?.colors?.surfaceHover
                }, 0 0 0 2px ${theme?.colors?.surface_light1_dark1}`,
            },
            "& .entry-media:before": {
              content: `""`,
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              background: "transparent",
              borderRadius: "sm",
              boxShadow: selected
                ? "inset 0px 0px 0px 1px rgba(0,0,0,0.1)"
                : "none",
            },
            "&:hover .entry-media-list:after": {
              background: (theme) =>
                `linear-gradient(270deg, ${selected ? bgColor : theme?.colors?.surface_light2_dark2} 70%, rgba(255, 255, 255, 0.00) 100%)`,
            },
            "&:hover .entry-media-thumbnail": {
              bg: "surface_light1_dark1",
            },
            "&&:before": {
              content: "''",
              position: "absolute",
              top: 0,
              left: 0,
              width: 1,
              borderRadius: "0 2px 2px 0",
              height: "100%",
              bg: is_unread ? journalColor : "transparent",
              zIndex: 1,
            },
          }}
        >
          {children}
        </a>
      </Link>
    );
  },
);
LinkWrapper.displayName = "LinkWrapper";
