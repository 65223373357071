import { Button, Spinner } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { D1Modal } from "@/components/D1Modal";
import { IncludeMedia } from "@/components/Export/IncludeMedia";
import { LoadingModal } from "@/components/LoadingModal";
import { useExport } from "@/hooks/useExport";
import { useShowHideModal } from "@/hooks/useShowHideModal";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

type Props = {
  journalId: string;
  handleClose(): void;
};

export const ExportModal: React.FC<Props> = observer(
  ({ journalId, handleClose }) => {
    const { __ } = useI18n();

    const [journalHasMedia, setJournalHasMedia] = useState(false);
    const [showAskAboutMedia, setShowAskAboutMedia] = useState(false);
    const [journalHasBeenSynced, setJournalHasBeenSynced] = useState<
      "NOT_STARTED" | "SYNCING" | "DONE"
    >("NOT_STARTED");

    const journalSyncState = primaryViewState.getSyncStateForJournal(journalId);
    const journalIsSyncing =
      (journalSyncState !== "NOT_SELECTED" &&
        journalHasBeenSynced !== "DONE") ||
      journalSyncState === "SYNCING";

    const { generateZip, cancel, setCancel } = useExport();

    const { showModal, hideModal, shouldShowModal } = useShowHideModal();

    const exportFinished = () => {
      handleClose();
      hideModal();
      viewStates.snackbar.newMessage(__("Journal exported successfully"));
      d1Classes.importExportRepository.deleteExport(`journal-${journalId}`);
    };

    const cancelExport = () => {
      handleClose();
      hideModal();
      viewStates.snackbar.newMessage(__("Export cancelled"));
      d1Classes.importExportRepository.deleteExport(`journal-${journalId}`);
    };

    useEffect(() => {
      d1Classes.syncStateRepository
        .getJournalSyncInfo(journalId)
        .then((info) => {
          if (info?.hasCompleted) {
            setJournalHasBeenSynced("DONE");
          }
        });
    }, [journalSyncState]);

    useEffect(() => {
      if (journalHasBeenSynced === "DONE") {
        d1Classes.journalStore.journalHasMedia(journalId).then((result) => {
          if (!result) {
            return;
          }
          setJournalHasMedia(true);
        });
      }
    }, [journalHasBeenSynced]);

    const journal = primaryViewState.getJournalById(journalId);

    const handleExportingJournal = async () => {
      if (!journal) {
        return;
      }
      d1Classes.importExportRepository.setExport(`journal-${journal.id}`, {
        format: "json",
        type: "journal",
        journalId: journal.id,
        includeMedia: false,
        totalMediaSize: 0,
        downloadedMediaSize: 0,
      });
      const cancelFn = await generateZip(
        journal,
        exportFinished,
        showModal,
        cancelExport,
        true,
      );
      setCancel({ fn: cancelFn });
    };

    const supportsStreamingExport = "showSaveFilePicker" in window;

    return (
      <>
        <D1Modal
          title={`${__("Export Journal")} ${journal?.name}`}
          onRequestClose={handleClose}
        >
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              maxWidth: "420px",
              "& p": {
                lineHeight: "body",
                mb: 3,
              },
              "&& svg": {
                mt: 0,
              },
            }}
          >
            {!supportsStreamingExport && (
              <p>
                {__(
                  "For large journals exporting may work better in a Chromium based browser such as Google Chrome or Microsoft Edge",
                )}
              </p>
            )}
            {journalHasBeenSynced !== "DONE" && (
              <>
                <p>
                  {__(
                    "This journal has not yet been synced to this device. It must be synced before it can be exported.",
                  )}
                </p>
                <Button
                  variant="primary"
                  disabled={journalIsSyncing}
                  onClick={() => {
                    setJournalHasBeenSynced("SYNCING");
                    d1Classes.entryStore.syncOneJournal(journalId).then(() => {
                      setJournalHasBeenSynced("DONE");
                    });
                  }}
                >
                  {journalIsSyncing && (
                    <>
                      <Spinner /> {__("Syncing journal...")}
                    </>
                  )}
                  {!journalIsSyncing && __("Sync journal now")}
                </Button>
              </>
            )}
            <Button
              sx={{
                "&& svg": {
                  mt: 0,
                },
              }}
              disabled={journalHasBeenSynced !== "DONE"}
              variant="primary"
              onClick={() => {
                if (journalHasMedia) {
                  setShowAskAboutMedia(true);
                } else {
                  handleExportingJournal();
                }
              }}
            >
              {__("Export journal JSON file")}
            </Button>
          </div>
        </D1Modal>
        {showAskAboutMedia && (
          <IncludeMedia
            journalId={journalId}
            handleClose={() => {
              setShowAskAboutMedia(false);
              hideModal();
              handleClose();
            }}
          />
        )}
        {shouldShowModal && (
          <LoadingModal
            title={__("Exporting Journal")}
            handleClose={hideModal}
            action={cancel?.fn}
            actionLabel={__("Cancel Export")}
          />
        )}
      </>
    );
  },
);
