import { BlockInstance, getBlockContent } from "@wordpress/blocks";
import { RTJNode } from "types/rtj-format";
import xss from "xss";

import {
  markdownToBlocks,
  markdownToHTML,
} from "@/components/Editor/gb2rtj/md2gb";
import { convertChecklistNodesToHTML } from "@/components/Editor/rtj2gb/format-node";
import {
  isCheckListNode,
  isEmbeddableContentNode,
  isEmbeddedContactNode,
  isEmbeddedGenericMediaNode,
  isEmbeddedPodcastNode,
  isEmbeddedSongNode,
  isEmbeddedStateOfMindNode,
} from "@/components/Editor/rtj2gb/rtj-type-checks";
import {
  convertRTJToBlocks,
  groupSiblingNodes,
} from "@/components/Editor/rtj2gb/rtj2gb";
import { ZERO_WIDTH_NO_BREAK_SPACE } from "@/components/Editor/rtj2gb/text2paragraph";
import { MomentDBRow } from "@/data/db/migrations/moment";
import { EntryModel } from "@/data/models/EntryModel";
import { momentToRTJ } from "@/utils/rtj";
import { BACKWARD, findWordBoundary, getWords } from "@/utils/strings";

export const PREVIEW_NODE_LIMIT = 3;
const PREVIEW_MAX_LENGTH = 60;
const INITIAL_WORD_BEFORE_MATCH = 2;

const NO_PARENT_TAG = null;

interface NodeInfo {
  node: Node;
  parentTag: string | null;
}

const emptyLineRegex = new RegExp(
  `<p>[\\s${ZERO_WIDTH_NO_BREAK_SPACE}]*?</p>`,
  "g",
);

export function getEntryMarkdownAsBlocks(entry: EntryModel) {
  return markdownToBlocks(entry.body || "", entry.journalId, entry.id);
}

export function getEntryBlocksWithMissingMoments(
  entry: EntryModel,
  moments?: MomentDBRow[],
  forceMarkdown?: boolean,
) {
  const useMd = forceMarkdown || !entry.entryContents.length;

  // Check to see if we're missing any moments from the entry contents
  // If we are, add them to the end of the entry contents
  const missingMoments = [];
  if (moments && moments.length) {
    const momentsFromRTJSon = entry.entryContents
      .map((node) => {
        if (!isEmbeddableContentNode(node)) {
          return null;
        }
        if (isEmbeddedContactNode(node)) {
          return node.embeddedObjects?.map((obj) => obj.photoIdentifier);
        }
        if (isEmbeddedPodcastNode(node)) {
          return node.embeddedObjects?.map((obj) => obj.artworkIdentifier);
        }
        if (isEmbeddedSongNode(node)) {
          return node.embeddedObjects?.map((obj) => obj.artworkIdentifier);
        }
        if (isEmbeddedGenericMediaNode(node)) {
          return node.embeddedObjects?.map((obj) => obj.iconIdentifier);
        }
        if (isEmbeddedStateOfMindNode(node)) {
          return node.embeddedObjects?.map((obj) => obj.iconIdentifier);
        }
        return node.embeddedObjects?.map((obj) => obj.identifier);
      })
      .flat()
      .filter((id) => id) as string[];

    for (const moment of moments) {
      if (useMd) {
        // Check the markdown string for the moment ID prefixed by a slash
        // push it onto missingMoments if it's not there
        if (!entry.body.includes(`/${moment.id})`)) {
          missingMoments.push(moment);
        }
      } else {
        // Check the rich text json for an embedded object with the id
        // push it onto missingMoments if it's not there
        if (!momentsFromRTJSon.find((id) => id === moment.id)) {
          missingMoments.push(moment);
        }
      }
    }
  }

  // For all of the moments that belong to the entry but aren't in the rich text json
  // or markdown string, append them to the end of the entry before generating blocks.
  if (useMd) {
    for (const moment of missingMoments) {
      const maybeMomentType = moment.type === "image" ? "" : moment.type;
      entry.body += `![](dayone-moment:/${maybeMomentType}/${moment.id})\n`;
    }
  } else {
    // For all missingMoments, add them to the end of the rich text json
    // then call convertRTJToBlocks on the new rich text json
    for (const moment of missingMoments) {
      entry.entryContents.push(momentToRTJ(moment));
    }
  }
  return getEntryBlocks(entry, forceMarkdown);
}

export function getEntryBlocks(entry: EntryModel, forceMarkdown?: boolean) {
  const useMd = forceMarkdown || !entry.entryContents.length;

  if (useMd) {
    return getEntryMarkdownAsBlocks(entry);
  } else {
    return convertRTJToBlocks(
      entry.entryContents || [],
      entry.id,
      entry.journalId,
    );
  }
}

function removeCommentTags(html: string) {
  const commentTagRegex = /<!--.*?-->/g;
  return html.replace(commentTagRegex, "");
}

const getBlocksHTML = (blocks: BlockInstance[]) => {
  const html = blocks
    .map((block) => {
      return getBlockContent(block);
    })
    .join("")
    .replaceAll(emptyLineRegex, "") // remove empty lines
    .replaceAll(ZERO_WIDTH_NO_BREAK_SPACE, "");

  return removeCommentTags(html);
};

export const getRTJPreview = (
  nodes: RTJNode[],
  entryID = "",
  journalID = "",
  nodeLimit = PREVIEW_NODE_LIMIT,
  searchTerm = "",
) => {
  if (!nodes || nodes.length === 0) {
    return "";
  }

  const { html: draftHTML, searchTermFound } = getPreviewNodesHTML(
    nodes,
    nodeLimit,
    entryID,
    journalID,
    searchTerm,
  );

  let html = draftHTML;

  html = sliceHTMLNodes(draftHTML, searchTerm, PREVIEW_MAX_LENGTH, nodeLimit);

  if (searchTermFound && searchTerm.length > 0) {
    html = highlightTermInHTML(html, searchTerm);
  }

  return html;
};

export const getEntryPreview = (
  entry: EntryModel,
  searchTerm = "",
  nodeLimit = PREVIEW_NODE_LIMIT,
) => {
  let html = "";
  // html can be derived from rtj nodes in entry.entryContents or markdown in entry.body
  if (entry.entryContents.length) {
    html = getRTJPreview(
      entry.entryContents,
      entry.id,
      entry.journalId,
      nodeLimit,
      searchTerm,
    );
  } else if (entry.body) {
    // fallback: convert from entry.body if the RTJ nodes are not present
    html = getEntryBodyPreviewHTML(entry, searchTerm, nodeLimit);
  }
  // mitigate xss after conversion
  return xss(html, {
    onIgnoreTag: (tag) => {
      // this strips out divs completely rather than escaping them
      // @todo this is a workaround for Gutenberg's getBlockContent returning inner divs
      // ideally we would get Gutenberg to not do that or strip them out earlier
      if (tag === "div") {
        return "";
      }
    },
    whiteList: {
      // text formatting
      b: [],
      del: [],
      em: [],
      mark: ["style"],
      s: [],
      strong: [],

      // lists
      li: ["class"],
      ol: [],
      ul: [],

      // checklists
      input: ["type", "checked", "disabled"],

      // headings
      h1: [],
      h2: [],
      h3: [],
      h4: [],
      h5: [],
      h6: [],

      // other allowed elements
      //
      // for a11y, we strip out the href attribute from links
      // so they aren't focusable
      a: [],
      blockquote: [],
      br: [],
      code: [],
      hr: [],
      img: [],
      p: [],
      pre: [],
      span: [],
    },
  });
};

function getEntryBodyPreviewHTML(
  entry: EntryModel,
  searchTerm: string,
  nodeLimit: number,
) {
  let toConvert = entry.body;

  // Split by lines and apply filters
  const lines = toConvert.split("\n").filter(
    (line) =>
      !["\n", "", "<br>"].includes(line) && // remove blank lines from preview
      !line.startsWith("![](dayone-moment:/"),
  );

  toConvert = lines.join("\n");

  // Convert markdown checklist items to HTML
  toConvert = toConvert.replace(
    /^(\s*- \[[X]]\s+?)(.+?)$/gm,
    (_match, p1, p2) => {
      return `${p1}<span>${p2}</span>\n`;
    },
  );

  let convertedHTML = markdownToHTML(toConvert);

  convertedHTML = sliceHTMLNodes(
    convertedHTML,
    searchTerm,
    PREVIEW_MAX_LENGTH,
    nodeLimit,
  );

  convertedHTML = highlightTermInHTML(convertedHTML, searchTerm);

  return convertedHTML;
}

function removeTags(html: string) {
  const tagRegex = /<[^>]*>/g;
  const textOnly = html.replace(tagRegex, "");
  return textOnly;
}

export function removeEmptyTags(html: string): string {
  // Regex to match empty tags
  const emptyTagRegex = /<(\w+)(?:\s[^>]*)?>\s*<\/\1>/g;
  const nonEmptyTags = html.replace(emptyTagRegex, "");
  return nonEmptyTags;
}

export function slicePreviewTextNode(
  node: Node,
  searchTerm: string,
  previewMaxLength: number,
  maximumWordBoundariesBeforeMatch = INITIAL_WORD_BEFORE_MATCH + 1,
): void {
  const words = getWords(searchTerm.toLowerCase());
  const searchTermIndices = words.map((word) =>
    node.textContent!.toLowerCase().indexOf(word),
  );
  const smallestSearchTermIndex = Math.min(...searchTermIndices);
  const word = words[searchTermIndices.indexOf(smallestSearchTermIndex)];

  if (smallestSearchTermIndex + word.length > previewMaxLength) {
    const fullText = node.textContent!;
    const wordBoundaryIndex = findWordBoundary(
      fullText,
      smallestSearchTermIndex,
      maximumWordBoundariesBeforeMatch,
      BACKWARD,
    );
    const partialText = fullText.slice(wordBoundaryIndex).trim();

    if (partialText.length < previewMaxLength) {
      slicePreviewTextNode(
        node,
        searchTerm,
        previewMaxLength,
        maximumWordBoundariesBeforeMatch + 1,
      );
      return;
    }

    node.textContent =
      wordBoundaryIndex === 0 ? partialText : `...${partialText}`;
  }
}

export function getNearestNodeIndexForSearchTerm(
  textNodes: Node[],
  searchTerm: string,
) {
  const words = getWords(searchTerm.toLowerCase());
  for (let i = 0; i < textNodes.length; i++) {
    const nodeValue = textNodes[i].nodeValue;
    if (nodeValue) {
      const nodeValueLower = nodeValue.toLowerCase();
      if (words.some((word) => nodeValueLower.includes(word))) {
        return i;
      }
    }
  }

  return -1;
}

function removeFormattingTags(html: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const formattingTags = [
    "s",
    "em",
    "strong",
    "mark",
    "blockquote",
    "pre",
    "code",
    "a",
  ];
  formattingTags.forEach((tagName) => {
    const elements = doc.getElementsByTagName(tagName);
    for (let i = elements.length - 1; i >= 0; i--) {
      const element = elements[i];
      const textNode = doc.createTextNode(element.textContent || "");
      element.parentNode?.replaceChild(textNode, element);
    }
  });

  return doc.body.innerHTML;
}

export function sliceHTMLNodes(
  html: string,
  searchTerm = "",
  previewMaxLength = PREVIEW_MAX_LENGTH,
  nodeLimit = PREVIEW_NODE_LIMIT,
): string {
  const unformattedHTML = removeFormattingTags(html);
  const doc = getHTMLDoc(unformattedHTML);
  const textNodes = getAllNonEmptyTextNodes(doc);

  // get first text node together with parent tag (if any)
  // we use the parent tag to determine if we should use a <p> or <h> tag
  // later
  const firstTextNodeWithParentTag = textNodes.shift();
  if (textNodes.length === 0 || !firstTextNodeWithParentTag || !searchTerm) {
    return html;
  }

  const nearestNodeIndex = getNearestNodeIndexForSearchTerm(
    textNodes.map((nodeInfo) => nodeInfo.node),
    searchTerm,
  );

  // we're always keeping the first text node, so get (nodeLimit - 1) more
  const reducedNodeLimit = nodeLimit - 1;
  const endIndex = Math.max(nearestNodeIndex + 1, reducedNodeLimit);
  const startIndex = Math.max(endIndex - reducedNodeLimit, 0);
  const textNodesToKeep = textNodes.slice(startIndex, endIndex);

  const trimmedHTML = getPreviewTrimmedHTML(
    firstTextNodeWithParentTag,
    textNodesToKeep,
    searchTerm,
    previewMaxLength,
  );
  return trimmedHTML;
}

function getPreviewTrimmedHTML(
  firstTextNodeWithParentTag: NodeInfo,
  textNodesToKeep: NodeInfo[],
  searchTerm: string,
  previewMaxLength: number,
) {
  const firstNode = createHeaderOrPTagNodeWithContent(
    firstTextNodeWithParentTag,
  );
  const nodes = [
    firstNode,
    ...createParagraphNodes(
      textNodesToKeep.map((nodeInfo) => nodeInfo.node.textContent || ""),
    ),
  ];
  const doc = document.implementation.createHTMLDocument("New Document");

  nodes.forEach((node) => {
    slicePreviewTextNode(
      node,
      searchTerm,
      previewMaxLength,
      INITIAL_WORD_BEFORE_MATCH + 1,
    );
    doc.body.appendChild(node);
  });

  const htmlWithoutEmptyTags = doc.body
    ? removeEmptyTags(doc.body.innerHTML)
    : "";
  return htmlWithoutEmptyTags;
}

function createParagraphNodes(textContents: string[]): Node[] {
  return textContents.map((textContent) => {
    const paragraph = document.createElement("p");
    paragraph.textContent = textContent;
    return paragraph;
  });
}

function createHeaderOrPTagNodeWithContent(nodeInfo: NodeInfo) {
  const headerTags = ["H1", "H2", "H3", "H4", "H5", "H6"];

  const { parentTag } = nodeInfo;
  const tag = !parentTag || !headerTags.includes(parentTag) ? "p" : parentTag;

  const node = document.createElement(tag);
  node.textContent = nodeInfo.node.textContent;
  return node;
}

function getAllNonEmptyTextNodes(doc: Document): NodeInfo[] {
  const walker = document.createTreeWalker(doc.body);
  const textNodes: NodeInfo[] = [];
  while (walker.nextNode()) {
    const node = walker.currentNode;
    // Skip nodes that contain only newline characters
    //@ts-ignore for some reason typescript doesn't think data is a property of node
    if (node.data && node.data.trim() !== "") {
      textNodes.push({
        node,
        parentTag: node.parentNode?.nodeName || NO_PARENT_TAG,
      });
    }
  }
  return textNodes;
}

function getHTMLDoc(html: string): Document {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  return doc;
}

export function getPreviewNodesHTML(
  nodes: RTJNode[],
  nodeLimit: number,
  entryID: string,
  journalID: string,
  searchTerm: string,
) {
  // get a deep copy of entryContents so we don't change the entry content >:(
  const nodesToConvert = structuredClone(nodes).filter(
    (node) => !isEmbeddableContentNode(node),
  );
  const groupedNodes = groupSiblingNodes(nodesToConvert);

  let searchTermFound = !searchTerm ? true : false;

  const htmls = [];
  for (const group of groupedNodes) {
    // group here is an rtj node and it is not html node
    // an rtj node can contain multiple html nodes
    // we are using htmls.length to approximate the amount of html nodes
    if (htmls.length >= nodeLimit && searchTermFound) break;
    const { html, textCount } = getRTJNodesHTML_TextCount(
      group,
      entryID,
      journalID,
    );

    if (textCount > 0) {
      if (!searchTermFound) {
        const searchTerms = getWords(searchTerm.toLowerCase());
        searchTermFound = searchTerms.some((term) =>
          html.toLowerCase().includes(term),
        );
      }
      htmls.push(html);
    }
  }

  const rtjNodesHTML = htmls.join("");

  return { html: rtjNodesHTML, searchTermFound };
}

function getRTJNodesHTML_TextCount(
  nodes: RTJNode[],
  entryID: string,
  journalID: string,
) {
  let html = "";
  // group checklist nodes together so we don't lose indentation
  if (nodes.every(isCheckListNode)) {
    html += convertChecklistNodesToHTML(nodes);
  } else {
    const _html = getRTJNodesHtml(nodes, entryID, journalID);
    html += _html;
  }
  const text = removeTags(html);
  const textCount = text.length;
  return { html, text, textCount };
}

function getRTJNodesHtml(nodes: RTJNode[], entryID: string, journalID: string) {
  const blocks = convertRTJToBlocks(nodes, entryID, journalID);
  const html = getBlocksHTML(blocks);
  return html;
}

export function highlightTermInHTML(html: string, searchTerm: string) {
  if (!searchTerm) {
    return html;
  }

  const words = getWords(searchTerm);

  // Join the words into a single regex pattern, matching any of the words
  const regexPattern = words.join("|");

  // Using a lookahead and lookbehind to ensure we are not inside a tag
  const regex = new RegExp(
    `(?<!<[^<>]*)(${regexPattern})(?![^<mark>]*>)`,
    "gi",
  );

  const highlightedHTML = html.replace(
    regex,
    (match: string) => `<mark>${match}</mark>`,
  );

  return highlightedHTML;
}
