import { Sentry } from "@/Sentry";
import { AuthToken, deserializeAuthToken } from "@/crypto/utils/authToken";
import { KeyValueStore } from "@/data/db/KeyValueStore";
import { SecureKeyValueStore } from "@/data/db/SecureKeyValueStore";
import { getDeviceHeaders } from "@/data/utils/deviceHeaders";

function valueOrDefault<T>(value: T | null | undefined, defaultValue: T) {
  if (value === null || value === undefined) {
    return defaultValue;
  }
  return value;
}

type options = {
  expectedStatusCodes?: number[];
  requestManualRedirect?: boolean;
  followManualRedirect?: boolean;
  overrideAuthToken?: string;
  referrer?: boolean;
  // If true, the url will be treated as complete
  // and the baseUrl will not be prepended.
  isCompleteUrl?: boolean;
  resumeSize?: number;
};

export class FetchWrapper {
  baseUrl = process.env.VITE_API_HOST;
  basicFetch = fetch;

  constructor(
    private kv: KeyValueStore,
    private secureKeyValueStore: SecureKeyValueStore,
  ) {}

  baseOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  async fetchAPI(
    url: string,
    requestInit: RequestInit | undefined = undefined,
    options: options = {},
  ): Promise<Response> {
    return this.fetchRoot(`/api${url}`, requestInit, options);
  }

  async fetchRedirectLocation(
    url: string,
    options: RequestInit | undefined = undefined,
  ): Promise<string | null> {
    const resp = await this.fetchRoot(url, options, {
      expectedStatusCodes: [],
      requestManualRedirect: true,
      followManualRedirect: false,
    });
    return resp.headers.get("x-manual-redirect-location");
  }

  async fetchRoot(
    url: string,
    requestInit: RequestInit | undefined = undefined,
    options: options = {},
  ): Promise<Response> {
    const expectedStatusCodes = valueOrDefault(options.expectedStatusCodes, []);
    // By default, don't request manual redirects.
    // Media requests should be redirected to S3.
    const requestManualRedirect = valueOrDefault(
      options.requestManualRedirect,
      false,
    );
    // By default, follow manual redirects if they appear. It is possible that
    // the caller will want to handle the redirect manually, just getting the
    // redirect location from the request. In that case, they should set this
    // to false.
    const followManualRedirect = valueOrDefault(
      options.followManualRedirect,
      true,
    );

    if (!requestInit) {
      requestInit = {};
    }

    // Create a headers object that we can extend, no matter what
    // the type of `HeadersInit` is on the incoming requestInit object.
    if (requestInit.headers == undefined) {
      requestInit.headers = new Headers({});
    } else if (
      requestInit.headers instanceof Array ||
      requestInit.headers instanceof Object
    ) {
      requestInit.headers = new Headers(requestInit.headers);
    }

    const { deviceInfo, xUserAgent } = await getDeviceHeaders();
    requestInit.headers.append("X-User-Agent", xUserAgent);

    // add auth header if token exists
    const authToken =
      options.overrideAuthToken ||
      (
        await this.secureKeyValueStore.get<AuthToken>(
          "dayone-auth-token",
          deserializeAuthToken,
        )
      )?.token;
    if (authToken) {
      requestInit.headers.append("authorization", authToken);
      if (requestManualRedirect) {
        // This is a a custom header that our server supports specifically for the Web client.
        // For media, the server normally returns an HTTP redirect to S3 with a presigned URL.
        // However, the browser automatically takes the Authorization header from the initial request,
        // and attaches it to the redirect request. And S3 doesn't like that, because the authorization
        // header conflicts with the auth token in the presigned URL. We've got to strip that header.
        // But, because of security, the browser won't show us any information abuout a redirect response.
        // No body, no headers.
        // So, when we're requesting media from the server, we add this header, which will tell the server to
        // return an http 204 response. The body will be the path to follow, and there will also be a header
        // "X-Manual-Redirect-Location" with the URL to redirect to.
        requestInit.headers.append("x-manual-redirect", "true");
      }

      requestInit.headers.append("Device-Info", deviceInfo);
    }

    // Some requests like ones to webauthn endpoints need to be able to send the referrer
    if (options.referrer) {
      requestInit.referrerPolicy = "origin";
    }

    const fetchUrl = options.isCompleteUrl ? url : `${this.baseUrl}${url}`;
    let res;
    try {
      res = await fetch(fetchUrl, requestInit);
    } catch (err) {
      if (process.env.NODE_ENV === "test") {
        throw new Error(
          `Failed while fetching in a test environment to url ${url}. Did you forget to mock this request?`,
        );
      } else {
        throw err;
      }
    }

    if (res.ok || expectedStatusCodes.includes(res.status)) {
      const manualRedirectLocation = res.headers.get(
        "x-manual-redirect-location",
      );
      // If we received a manual redirect request and we want to follow it,
      // automatically make the subsequent request to the redirect location.
      if (
        manualRedirectLocation &&
        followManualRedirect &&
        requestManualRedirect
      ) {
        // Resubmit the request to a new location
        // Do not include the authentication header
        // Specifically, dropping the authorization header is important for media requests
        // because S3 will throw an error if the header is present. It has its own auth token
        // in the query and gets confused if we include our own auth header.

        // If we are resuming a download
        // Add the Range header to start downloading where we left off
        let init;
        if (options.resumeSize) {
          init = { headers: new Headers() };
          init.headers.append("Range", `bytes=${options.resumeSize}-`);
        }
        const res2 = await fetch(manualRedirectLocation, init);
        return res2;
      }
      return res;
    } else {
      const error = await res.clone().text();
      // Force logout and clearing stored information if the user token sent on an API call is not found on the server
      if (res.status === 403 && error === "Token is invalid") {
        this.kv.set("force-logout", "yes");
      }
      const isLoggingOut = await this.kv.get("is-logging-out");
      if (
        !(isLoggingOut && res.status === 403) &&
        process.env.NODE_ENV !== "test"
      ) {
        Sentry.captureException(
          new Error(
            `ERROR: fetchWrapper ${fetchUrl} returned unexpected status code: ${res.status}. Body: ${error}`,
          ),
        );
      }
      return res;
    }
  }

  async postJson(
    url: string,
    body: any,
    options: options = {},
  ): Promise<Response> {
    return await this.fetchAPI(
      url,
      {
        ...this.baseOptions,
        method: "POST",
        body: JSON.stringify(body),
      },
      options,
    );
  }

  async postJsonAPIRoot(url: string, body: any): Promise<Response> {
    return await this.fetchRoot(url, {
      ...this.baseOptions,
      method: "POST",
      body: JSON.stringify(body),
    });
  }
}
