import { TextControl as WpTextControl } from "@wordpress/components";
import { WordPressComponentProps } from "@wordpress/components/build-types/context";
import { TextControlProps } from "@wordpress/components/build-types/text-control/types";

export const LoggedOutTextControl: React.FC<
  WordPressComponentProps<TextControlProps, "input", false> & {
    isInvalid?: boolean;
    className?: string;
  }
> = (props) => {
  const { isInvalid, className, ...rest } = props;
  return (
    <WpTextControl
      __nextHasNoMarginBottom
      sx={{
        "&&": {
          mt: 4,
        },
        "&& label": {
          color: "textSecondary",
        },
        "&& input::placeholder": {
          color: "textSecondary",
        },
        "&&& .components-text-control__input": {
          backgroundColor: "surface_light1_dark2",
          color: "textPrimary",
          px: 2.5,
          py: 2,
          borderColor: isInvalid ? "red" : "borderPrimary",
          borderWidth: isInvalid ? "2px" : "1px",
          borderRadius: "sm",
          height: "44px",
        },
        "&&& .components-base-control__help": {
          color: "red",
          position: "absolute",
          mt: 0,
        },
      }}
      {...rest}
      className={className}
    />
  );
};
