import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import {
  JournalPreset,
  journalPresetsSchema,
} from "@/data/api_callers/schemas/JournalPresetAPISchemas";
import { safelyDecodeAPIResponse } from "@/data/api_callers/utils/safelyDecodeAPIResponse";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";

export class JournalPresetAPICaller {
  constructor(
    private fetchWrapper: FetchWrapper,
    private syncStateRepository: SyncStateRepository,
    private sentry: Sentry,
  ) {}

  async fetchJournalPresets(): Promise<JournalPreset[]> {
    const storedCursor = await this.syncStateRepository.getPresetsCursor();
    const res = await this.fetchWrapper.fetchAPI(
      "/v2/journal-presets" + (storedCursor ? `?cursor=${storedCursor}` : ""),
      {},
      { expectedStatusCodes: [200] },
    );

    if (!res.ok) {
      this.sentry.captureException(
        new Error(
          `Failed to fetch Journal Presets: ${res.status} - ${res.statusText}`,
        ),
      );
      throw new Error("Failed to fetch Journal Presets");
    }

    const { data, cursor } = await res.json();
    await this.syncStateRepository.setPresetsCursor(cursor);
    return safelyDecodeAPIResponse(journalPresetsSchema, data);
  }
}
