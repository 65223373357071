import {
  Button,
  Notice,
  Spinner,
  __experimentalConfirmDialog as ConfirmDialog,
} from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useState } from "react";

type Props = {
  blogUrl: string;
  disconnectBlog: (blogUrl: string) => Promise<void | "failed">;
};

export const WPConnectDisconnectButton: React.FC<Props> = ({
  blogUrl,
  disconnectBlog,
}: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const { __ } = useI18n();
  const handleDisconnect = async () => {
    setError(null);
    setLoading(true);
    const result = await disconnectBlog(blogUrl);
    if (result === "failed") {
      setError(__("Failed to disconnect blog. Please try again."));
    }
    setLoading(false);
  };

  return (
    <div
      sx={{
        ".components-modal__frame": {
          backgroundColor: "red !important",
        },
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      {error && (
        <Notice
          status="error"
          isDismissible={false}
          sx={{
            mb: -1,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            pb: 3,
          }}
        >
          {error}
        </Notice>
      )}
      <ConfirmDialog
        isOpen={showConfirmation}
        onConfirm={handleDisconnect}
        onCancel={() => setShowConfirmation(false)}
      >
        <p>{__("Are you sure you want to disconnect this blog?")}</p>
      </ConfirmDialog>
      <Button
        isDestructive
        onClick={() => setShowConfirmation(true)}
        sx={{ borderRadius: "6px !important" }}
      >
        {loading ? <Spinner /> : "Disconnect"}
      </Button>
    </div>
  );
};

WPConnectDisconnectButton.displayName = "WPConnectDisconnectButton";
