import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useState } from "react";

import { D1Modal } from "@/components/D1Modal";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Reauth } from "@/components/Settings/Account/Reauth";
import { useAuth } from "@/data/hooks/AuthProvider";
import { useNeedsAuthRefresh } from "@/data/hooks/userAccount/useNeedsAuthRefresh";

type Props = {
  onRequestClose: () => void;
};

export const RemoveSyncDataModal: React.FC<Props> = ({ onRequestClose }) => {
  const { __ } = useI18n();
  const { needsRefresh } = useNeedsAuthRefresh();
  const { removeSyncData } = useAuth();

  const [error, setError] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const handleRemoveSyncData = async () => {
    setIsDeleting(true);
    const result = await removeSyncData();
    if (result.success) {
      setIsComplete(true);
      setError("");
    } else {
      setIsComplete(false);
      setError(result.error);
    }
    setIsDeleting(false);
  };

  return (
    <D1Modal
      onRequestClose={onRequestClose}
      title={__("Confirm removing sync data")}
      sx={{ maxWidth: "500px" }}
    >
      <div
        sx={{
          a: { textDecoration: "underline", color: "dayOneBlue" },
          "& p": { lineHeight: "body", mb: 2 },
        }}
      >
        {error && <ErrorMessage message={error} />}
        {!needsRefresh && !isComplete && (
          <>
            <p>
              {__(
                "Are you sure you want to remove all of your sync data from the Day One Servers? It won't be recoverable. If you're absolutely sure you want to continue, click the Remove Sync Data button below.",
              )}
            </p>
            <div
              sx={{
                display: "flex",
                mt: 2,
                justifyContent: "flex-end",
                width: "100%",
                gap: "1rem",
              }}
            >
              <Button
                variant="secondary"
                onClick={onRequestClose}
                disabled={isDeleting}
              >
                {__("Cancel")}
              </Button>
              <Button
                variant="primary"
                isDestructive={true}
                onClick={handleRemoveSyncData}
                sx={{ "&&&": { backgroundColor: "red" } }}
                disabled={isDeleting}
              >
                {isDeleting ? __("Deleting...") : __("Remove Sync Data")}
              </Button>
            </div>
          </>
        )}
        {!needsRefresh && isComplete && (
          <>
            <p>
              {__("Your data has now been removed from the Day One Servers.")}
            </p>
            <div sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="primary" onClick={onRequestClose}>
                {__("Close")}
              </Button>
            </div>
          </>
        )}
        {needsRefresh && <Reauth />}
      </div>
    </D1Modal>
  );
};
