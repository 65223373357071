import { Notice, Spinner } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { isEmail } from "@wordpress/url";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Link, useLocation } from "wouter";

import { d1Classes } from "@/D1Classes";
import { d1MainThreadClasses } from "@/D1MainThreadClasses";
import { ErrorMessage } from "@/components/ErrorMessage";
import { LoggedOutTextControl } from "@/components/Form/LoggedOutTextControl";
import { GoogleLogin } from "@/components/GoogleLogin";
import { Button } from "@/components/LoggedOut/Button";
import { FormCard } from "@/components/LoggedOut/FormCard";
import { Heading } from "@/components/LoggedOut/Heading";
import { Message } from "@/components/LoggedOut/Message";
import { OrSeparator } from "@/components/OrSeparator";
import { SignInWithApple } from "@/components/SignInWithApple";
import { getRedirectURL, maybeAddNextToURL } from "@/data/URLFunctions";
import { useDevice } from "@/data/hooks/LayoutProvider";
import { useLogin } from "@/data/hooks/userAccount/useLogin";
import { useClassInstance } from "@/hooks/d1_hooks";
import { LoggedOut } from "@/layouts/LoggedOut";
import { PasskeyLoginViewState } from "@/pages/login/PasskeyLoginViewState";
import { QRCode } from "@/pages/login/QRCode";
import { handleQRCodeDrop } from "@/pages/login/devLoginQRCodeDropHandler";
import { setDocumentTitle } from "@/utils/set-document-title";
import { primaryViewState } from "@/view_state/ViewStates";

export const QRLogin = observer(() => {
  const user = primaryViewState.user;
  const { auth, credsValid, creds, setCreds, validateEmail } = useLogin();
  const [authenticating, setAuthenticating] = useState(false);

  const { isMobile } = useDevice();
  const [isAuthenticated] = useState(auth.isAuthenticated);
  const [, setLocation] = useLocation();
  const { __ } = useI18n();
  const redirectURL = getRedirectURL();
  setDocumentTitle(__("Login"));

  const passkeyLoginViewState = useClassInstance(
    () =>
      new PasskeyLoginViewState(
        d1Classes.fetchWrapper,
        (resp) => auth.completeLogin(resp),
        d1MainThreadClasses.userLoginService,
      ),
  );

  const signUpURL = maybeAddNextToURL("signup");

  useEffect(() => {
    if (auth.errors.length > 0) {
      setAuthenticating(false);
    }
  }, [auth.errors]);

  useEffect(() => {
    if (isAuthenticated || user) {
      setLocation("/");
    }
  }, [isAuthenticated, user]);

  return (
    !isAuthenticated && (
      <LoggedOut background="surface_light1_dark1">
        <div
          sx={{
            maxWidth: "1080px",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            gap: 9,
            mx: "auto",
          }}
        >
          <div>
            {/* Sneaky! While we're testing passkeys in dev, a command-click on the header will start passkey login. Purposefully not accessible or tabbable.
        Also sneaky, we allow dropping a QR code onto the header to allow test user login. */}
            <div
              onClick={(e) => {
                if (e.metaKey) {
                  passkeyLoginViewState.doPasskeyLogin();
                }
              }}
              onDrop={handleQRCodeDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <Heading>{__("Log in to Day One")}</Heading>
            </div>
            <FormCard
              onSubmit={(e: React.FormEvent) => {
                e.preventDefault();
                if (credsValid) {
                  setAuthenticating(true);
                  auth.attemptLogin(
                    creds.email,
                    creds.password,
                    redirectURL || undefined,
                  );
                }
              }}
            >
              <Message sx={{ mb: 6 }}>
                {__("Don't have an account?")}{" "}
                <Link href={signUpURL}>{__("Create one")}</Link>.
              </Message>
              {auth.errors.length > 0 && (
                <ErrorMessage
                  message={auth.errors[0]}
                  sx={{ "& h3": { display: "none" } }}
                />
              )}

              {
                // if we have a redirect URL in the query string we display a message
                // to the user that they need to log in to continue
                redirectURL && (
                  <Notice
                    isDismissible={false}
                    status="warning"
                    sx={{ margin: "0px", marginBottom: "1.25rem" }}
                  >
                    {redirectURL.includes("accept-journal-invite")
                      ? __("You need to log in to join this shared journal.")
                      : __(
                          "You need to log in to continue to the page you were trying to access.",
                        )}
                  </Notice>
                )
              }

              <LoggedOutTextControl
                label={__("Email address")}
                autoComplete="webauthn username"
                onChange={(value: string) => {
                  let isInvalid = creds.isInvalid;
                  if (isInvalid && (isEmail(value) || value === "")) {
                    isInvalid = false;
                  }
                  setCreds({ ...creds, email: value.trim(), isInvalid });
                }}
                type="email"
                value={creds.email}
                isInvalid={creds.isInvalid}
                help={
                  creds.isInvalid
                    ? __("Enter a valid email address")
                    : undefined
                }
                onBlur={() => {
                  validateEmail(creds);
                }}
                sx={{ "&&": { mb: 5 } }}
              />
              <LoggedOutTextControl
                label={__("Password")}
                autoComplete="current-password"
                onChange={(value: string) => {
                  setCreds({ ...creds, password: value });
                }}
                type="password"
                value={creds.password}
              />
              <Button
                sx={{ "&&": { borderRadius: 3 } }}
                className="button"
                variant="primary"
                type="submit"
                disabled={!credsValid || authenticating}
              >
                {!authenticating ? __("Log In") : <Spinner />}
              </Button>

              <OrSeparator />

              <GoogleLogin action={auth.attemptGoogleLogin} />

              <div sx={{ color: "textPrimary", mb: 4, mt: 3 }}>
                <SignInWithApple action={auth.attemptAppleLogin} />
              </div>
            </FormCard>
          </div>
          {!isMobile && <QRCode />}
        </div>
      </LoggedOut>
    )
  );
});

QRLogin.displayName = "QRLogin";
