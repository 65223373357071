import { createI18n, LocaleData } from "@wordpress/i18n";

import { en } from "@/../languages/en";
import { es } from "@/../languages/es";

const languages: { [key: string]: LocaleData } = { en, es };
const lang = navigator.language.split("-")[0];
const i18n = createI18n(lang in languages ? languages[lang] : languages.en); // default to english

export { i18n };
