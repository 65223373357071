import { ThemeUICSSObject } from "@theme-ui/css";
import { registerBlockType, unregisterBlockType } from "@wordpress/blocks";
import { Disabled } from "@wordpress/components";
import { useEffect, useState } from "react";

import {
  PreviewBlockEditProps,
  PREVIEW_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { CustomEmbed } from "@/components/Editor/blocks/preview/CustomEmbed";
import { ResponsiveIframe } from "@/components/Editor/blocks/preview/ResponsiveIframe";
import { UnsupportedPreview } from "@/components/Editor/blocks/preview/UnsupportedPreview";
import { blockIsRegistered } from "@/components/Editor/utils/register-blocks";
import { dayOneBlue } from "@/styles/theme";
import { supportsCredentialless } from "@/utils/environment";

export type ValidURLs = {
  type: "youtube" | "vimeo" | "spotify-track" | "spotify-playlist";
  regex: RegExp;
  index: number;
  name: "YouTube" | "Vimeo" | "Instagram" | "Spotify";
};

const YouTubeRegex = new RegExp(
  /(https)?(?:www.|m.)?youtu(?:be.com\/watch.*[&?]v=|.be\/)([a-zA-Z0-9_-]*)(&(amp;)?[w?=-]*)*/,
);
const VimeoRegex = new RegExp(/(https)?(?:www.)?vimeo.com\/(\d+)/);
const SpotifyTrackRegex = new RegExp(
  /(https)?(?:open.)?spotify.com\/track\/([A-z0-9]+)/,
);
const SpotifyPlaylistRegex = new RegExp(
  /(https)?(?:open.)?spotify.com\/playlist\/([A-z0-9]+)/,
);
const validURLs: ValidURLs[] = [
  { type: "youtube", regex: YouTubeRegex, index: 2, name: "YouTube" },
  { type: "vimeo", regex: VimeoRegex, index: 2, name: "Vimeo" },
  {
    type: "spotify-track",
    regex: SpotifyTrackRegex,
    index: 2,
    name: "Spotify",
  },
  {
    type: "spotify-playlist",
    regex: SpotifyPlaylistRegex,
    index: 2,
    name: "Spotify",
  },
];

export const PreviewBlock: React.FC<PreviewBlockEditProps> = ({
  attributes,
  isSelected,
}) => {
  const url = attributes.url;
  const [enableEmbeds, setEnableEmbeds] = useState(false);

  const styles: ThemeUICSSObject = {
    border: "3px solid",
    borderColor: isSelected ? dayOneBlue : "transparent",
    overflow: "hidden",
    my: 4,
    "& .components-disabled": {
      overflow: "hidden",
      position: "initial",
    },
    borderRadius: "1rem",
  };

  useEffect(() => {
    supportsCredentialless().then((result) => {
      if (result) {
        setEnableEmbeds(result);
      }
    });
  }, []);

  for (const urlType of validURLs) {
    const matches = url.match(urlType.regex);
    if (matches) {
      const id = matches[urlType.index];
      if (!enableEmbeds) {
        return (
          <CustomEmbed
            url={url}
            type={urlType.type}
            id={id}
            isSelected={isSelected}
          />
        );
      }
      switch (urlType.type) {
        case "youtube":
          return (
            <ResponsiveIframe
              src={`https://www.youtube.com/embed/${id}?rel=0&iv_load_policy=3`}
              isSelected={isSelected}
            />
          );
        case "vimeo":
          return (
            <ResponsiveIframe
              src={`https://player.vimeo.com/video/${id}`}
              isSelected={isSelected}
            />
          );
        case "spotify-track":
          return (
            <div sx={{ height: "238px", ...styles }}>
              <Disabled isDisabled={!isSelected}>
                <iframe
                  src={`https://embed.spotify.com/track/${id}`}
                  width="100%"
                  height="350"
                  allowFullScreen
                  // @ts-ignore only supported in Chromium at this time
                  credentialless=""
                ></iframe>
              </Disabled>
            </div>
          );
        case "spotify-playlist":
          return (
            <div sx={{ height: "158px", ...styles }}>
              <Disabled isDisabled={!isSelected}>
                <iframe
                  src={`https://open.spotify.com/embed/playlist/${id}`}
                  width="100%"
                  height="350"
                  allowFullScreen
                  // @ts-ignore only supported in Chromium at this time
                  credentialless=""
                ></iframe>
              </Disabled>
            </div>
          );
        default:
          return <UnsupportedPreview urlType={urlType} isSelected />;
      }
    }
  }
  return <UnsupportedPreview url={url} isSelected />;
};

export const register = () => {
  if (!blockIsRegistered(PREVIEW_BLOCK_ID)) {
    // @ts-ignore - seems types are not inline with code here.
    registerBlockType(PREVIEW_BLOCK_ID, {
      edit: (props: PreviewBlockEditProps) => {
        return <PreviewBlock {...props} />;
      },
      title: "Preview",
      category: "media",
      textdomain: "default",
      description: "Day One Preview Block",
      attributes: {
        url: {
          type: "string",
        },
      },
      supports: {
        lock: false,
        html: false,
        inserter: false,
      },
    });
  }
};

export const unregister = () => {
  if (blockIsRegistered(PREVIEW_BLOCK_ID)) {
    return unregisterBlockType(PREVIEW_BLOCK_ID);
  }
  return false;
};
