import { Flex, FlexItem, FlexBlock, Notice } from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { Icon, info } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { d1Classes } from "@/D1Classes";
import { d1MainThreadClasses } from "@/D1MainThreadClasses";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Modal } from "@/components/D1Modal";
import { ENTER, ESCAPE } from "@/components/Editor/utils/keycodes";
import { ErrorMessage } from "@/components/ErrorMessage";
import { TextControl } from "@/components/Form/TextControl";
import { BackupHintButton } from "@/components/Settings/Account/MasterKeyModal/BackupHintButton";
import { EditButtons } from "@/components/Settings/EditButtons";
import { EditSection } from "@/components/Settings/EditSection";
import { Label } from "@/components/Settings/Label";
import { LocationOfKey } from "@/data/repositories/UserKeysRepository";
import { useSaveEncryptionKey } from "@/hooks/useSaveEncryptionKey";
import { EncryptionModalOpenMethods } from "@/layouts/ModalRoutes";
import { SnackbarViewState } from "@/view_state/SnackbarViewState";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

type EncryptionKeyModalProps = {
  onClose: (key: string | undefined) => void;
  openedFrom: EncryptionModalOpenMethods;
  isCreatingSharedJournal: boolean;
  snackbar: SnackbarViewState;
};

export const EnterEncryptionKeyModal: React.FC<EncryptionKeyModalProps> =
  observer(({ onClose, snackbar, openedFrom, isCreatingSharedJournal }) => {
    const userKeysStore = d1Classes.userKeysStore;
    const storedMasterKeyString = primaryViewState.masterKeyString;
    const { __ } = useI18n();
    const [inputKey, setInputKey] = useState<null | string>(null);
    const [error, setError] = useState<string | null>(null);
    const [isValid, setIsValid] = useState(true);
    const key = inputKey ?? storedMasterKeyString;
    const { validateAndStoreMasterKey } = useSaveEncryptionKey(
      openedFrom ? "settings" : "sidebar",
      userKeysStore,
      d1MainThreadClasses.syncService,
    );

    const keyIsValid = (key: string) => {
      // Pick up some obvious key errors before we send the master key off to be
      // tested by unlocking the user key.
      return !!(key.length && key.toLowerCase().includes("d1-"));
    };

    const doneMessage = __(
      "Your encryption key was successfully added. You can now view, create and manage end-to-end encrypted journals on this device",
    );

    const _save = async () => {
      await validateAndStoreMasterKey(key?.trim());
      snackbar.newMessage(doneMessage);
      if (!primaryViewState.user?.master_key_storage?.length) {
        // if we don't have record of them saving their key, show the modal
        viewStates.modalRouter.showKeyModal({
          openedFrom: "enter_key_modal",
          isCreatingSharedJournal,
        });
      } else {
        handleClose();
      }
    };
    const doSave = (e: React.MouseEvent | undefined) => {
      e?.preventDefault();
      _save().catch((err) => {
        analytics.tracks.recordEvent(EVENT.encryptionKeyError, {
          method: err.message
            .toLowerCase()
            .replaceAll(",", "")
            .replaceAll(" ", "-"),
        });
        if (err.message.includes("does not match key user ID")) {
          setError(__("Sorry, that key doesn't match this user."));
        } else {
          setError(
            __(
              "Sorry, there was an error saving your key. Please check that it's correct or try again later.",
            ),
          );
        }
      });
    };

    function handleClose() {
      onClose(isValid ? (key ?? undefined) : undefined);
    }

    const DriveButton = (
      <BackupHintButton
        label={__("in Google Drive")}
        isCreatingSharedJournal={isCreatingSharedJournal}
      />
    );
    const ICloudButton = (
      <BackupHintButton
        label={__("in iCloud")}
        isCreatingSharedJournal={isCreatingSharedJournal}
      />
    );

    const storageLocations: Record<string, React.JSX.Element | string> = {
      cloudkit: ICloudButton,
      pdfDownloadWeb: __("in a PDF downloaded from the web"),
      drive: DriveButton,
      driveWeb: DriveButton,
      manualBackup: __("Manually"),
    };

    function handleKeyUp(e: React.KeyboardEvent) {
      switch (e.key) {
        case ENTER:
          if (error === null) {
            doSave(undefined);
          }
          break;
        case ESCAPE:
          handleClose();
          break;
        default:
          break;
      }
    }

    return (
      <>
        <D1Modal
          title={__("Add Encryption Key")}
          onRequestClose={handleClose}
          sx={{ width: "420px" }}
        >
          {isCreatingSharedJournal && (
            <p sx={{ mb: 5, fontSize: 1 }}>
              {__(
                "To create a Shared Journal you need to enter your encryption key first.",
              )}
            </p>
          )}
          {openedFrom === "add_entry" && (
            <p sx={{ mb: 5, fontSize: 1 }}>
              {__(
                "Your journal needs to be unlocked before you can add an entry.",
              )}
            </p>
          )}
          <EditSection>
            <FlexItem sx={{ flex: 1, position: "relative" }}>
              <Label htmlFor="encryption_key">{__("Encryption Key")}</Label>
              {error && <ErrorMessage message={error} />}
              <>
                <Flex
                  sx={{
                    mb: 2,
                    "&& input::placeholder": { color: "textSecondary" },
                  }}
                >
                  <FlexBlock>
                    <TextControl
                      css={{ paddingTop: 8 }}
                      name="encryption_key"
                      id="encryption_key"
                      type="text"
                      placeholder="D1-..."
                      onKeyUp={handleKeyUp}
                      onChange={(value) => {
                        if (keyIsValid(value)) {
                          setIsValid(true);
                          setError(null);
                        } else {
                          setIsValid(false);
                          setError(__("Please provide a valid encryption key"));
                        }

                        setInputKey(value);
                      }}
                      value={key || ""}
                      autoFocus
                    />
                    {primaryViewState.user &&
                      primaryViewState.user?.master_key_storage?.length > 0 && (
                        <Notice
                          isDismissible={false}
                          status="success"
                          sx={{
                            mx: 0,
                          }}
                        >
                          <p>{__("Our records say your key was saved...")}</p>
                          <ul
                            sx={{
                              "& li": {
                                listStyle: "disc",
                                ml: 5,
                                mt: 2,
                                fontSize: 0,
                              },
                            }}
                          >
                            {primaryViewState.user?.master_key_storage.map(
                              (storage: LocationOfKey) => {
                                return (
                                  <li key={storage}>
                                    {storageLocations[storage] ||
                                      sprintf(
                                        __('In a place called "%s"'),
                                        storage,
                                      )}
                                  </li>
                                );
                              },
                            )}
                          </ul>
                        </Notice>
                      )}
                  </FlexBlock>
                  <a
                    sx={{
                      fill: "currentColor",
                      position: "absolute",
                      color: "textSecondary",
                      right: 0,
                      top: 0,
                      mt: -1,
                    }}
                    href="https://dayoneapp.com/guides/day-one-on-the-web/getting-started-on-the-web/#adding-your-encryption-key"
                    target="_blank"
                    rel="noopener"
                    title={__("Where to find your encryption Key")}
                  >
                    <Icon icon={info} />
                  </a>
                </Flex>
                <Flex>
                  <EditButtons
                    disabled={!isValid}
                    doSave={doSave}
                    doCancel={handleClose}
                    isSaving={false}
                    attributeChanged={
                      keyIsValid(key || "") && key !== storedMasterKeyString
                    }
                  />
                </Flex>
              </>
            </FlexItem>
          </EditSection>
        </D1Modal>
      </>
    );
  });
