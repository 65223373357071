import { TabPanel } from "@wordpress/components";
import { IconType } from "@wordpress/components/build-types/icon";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Modal } from "@/components/D1Modal";
import { Account } from "@/components/Settings/Account";
import { Advanced } from "@/components/Settings/Advanced";
import { Appearance } from "@/components/Settings/Appearance";
import { DailyPrompt } from "@/components/Settings/DailyPrompt";
import { DeveloperSettings } from "@/components/Settings/Developer";
import { DevDiv } from "@/components/Settings/Developer/DevDiv";
import { Entries } from "@/components/Settings/Entries";
import { FeatureFlags } from "@/components/Settings/FeatureFlags";
import { Import } from "@/components/Settings/Import";
import { Journals } from "@/components/Settings/Journals";
import { WPConnectSettings } from "@/components/Settings/WPConnect";
import { useDevice } from "@/data/hooks/LayoutProvider";
import { settingsTab } from "@/layouts/ModalRoutes";
import { switchOnValue } from "@/utils/switching";
import {
  featureFlagsViewState,
  userSettingsViewState,
  viewStates,
} from "@/view_state/ViewStates";

type SettingsProps = {
  handleClose: () => void;
  tab?: settingsTab;
};

type Tab = {
  name: string;
  title: string;
  className?: string;
  icon?: IconType;
  disabled?: boolean;
} & Record<any, any>;

export const Settings: React.FC<SettingsProps> = observer(
  ({ handleClose, tab }) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const { isMobile } = useDevice();
    const { __ } = useI18n();
    const panelLayout: {
      orientation: "vertical" | "horizontal";
      direction: "row" | "column";
      width: string;
    } = isMobile
      ? {
          orientation: "horizontal",
          direction: "column",
          width: "100%",
        }
      : {
          orientation: "vertical",
          direction: "row",
          width: "100%",
        };

    const showPanel = () => {
      if (!tab) return <Account />;
      return switchOnValue(tab, {
        account: () => <Account />,
        entries: () => <Entries />,
        journal: () => <Journals modalRef={modalRef} />,
        appearance: () => <Appearance />,
        advanced: () => <Advanced />,
        dailyPrompts: () => <DailyPrompt />,
        featureFlags: () => <FeatureFlags />,
        developer: () => <DeveloperSettings />,
        import: () => {
          analytics.tracks.recordEvent(EVENT.buttonTap, {
            button_identifier: "import_export_importJSON",
          });
          return <Import />;
        },
        wpconnect: () => <WPConnectSettings viewState={viewStates.wpConnect} />,
      });
    };

    useEffect(() => {
      analytics.tracks.recordEvent(EVENT.screenView, {
        screen: "settings",
      });
    }, []);

    const createTabsWithConditionalFeatures = (baseTabs: Tab[]) => {
      const enhancedTabs = [...baseTabs];

      // Adding here for now, once we remove the flag we can add it to the base tabs
      if (featureFlagsViewState.showImportExport) {
        enhancedTabs.push({
          name: "import",
          title: __("Import"),
          className: "import",
        });
      }
      if (featureFlagsViewState.showWPConnect) {
        enhancedTabs.push({
          name: "wpconnect",
          title: __("WordPress"),
          className: "wpconnect",
        });
      }

      if (userSettingsViewState.developerModeEnabled) {
        enhancedTabs.push({
          name: "featureFlags",
          title: __("Feature Flags"),
          className: "feature-flags",
        });
        enhancedTabs.push({
          name: "developer",
          title: __("Developer"),
          className: "developer",
        });
      }

      return enhancedTabs;
    };

    const baseTabs = [
      {
        name: "account",
        title: __("Account"),
        className: "account-tab",
      },
      {
        name: "entries",
        title: __("Entries"),
        className: "entry-tab",
      },
      {
        name: "journal",
        title: __("Journals"),
        className: "journal-tab",
      },
      {
        name: "appearance",
        title: __("Appearance"),
        className: "appearance-tab",
      },
      {
        name: "dailyPrompts",
        title: __("Daily Prompts"),
        className: "daily-prompt",
      },
      {
        name: "advanced",
        title: __("Advanced"),
        className: "advanced-tab",
      },
    ];

    const tabs = createTabsWithConditionalFeatures(baseTabs);

    return (
      <D1Modal
        ref={modalRef}
        onRequestClose={handleClose}
        noLeftPadding
        hideHeader
        sx={{
          // .components-modal__frame
          width: ["100%", "100%", "700px"],
          height: ["100%", "calc(100% - 3rem)", "fit-content"],
          maxHeight: ["none", "calc(100% - 3rem)"],
          borderRadius: [0, 2, "12px"],
          mt: [0, 4, "auto"],
          pl: 0,
          "& .components-modal__content": {
            backgroundColor: "surface_light2_dark2",
            display: "flex",
            py: 0,
            pr: 0,
            mt: 0,
            "& > div:nth-of-type(2)": {
              display: "flex",
              flexGrow: 1,
            },
          },
        }}
      >
        <div
          data-testid="settings-modal"
          id="inside-modal"
          sx={{
            display: "flex",
            flexGrow: 1,
            height: "100%",
            "& .components-tab-panel__tab-content": {
              backgroundColor: "surface_light1_dark5",
            },
            "& > div": {
              display: "flex",
              flexGrow: 1,
              flexDirection: panelLayout.direction,
              height: "100%",

              "& .components-tab-panel__tabs": {
                // 48px for the button and 32px for the overlay margin
                width: ["calc(100vw - 48px)", "calc(100vw - 48px - 32px)", 192],
                pl: 3,
                pr: 3,
                pt: 8,
                flex: "0 0 auto",
                borderRight: "1px solid",
                borderRightColor: "borderPrimary",
              },
              "& .components-tab-panel__tabs button": {
                height: 48,
                fontWeight: "normal",
                borderRadius: "sm",
                "&:before": {
                  top: "-1px",
                  bottom: "-1px",
                  left: "-1px",
                  right: "-1px",
                  borderRadius: "sm",
                  borderColor: "red",
                },
                "&:active": {
                  color: "textPrimary",
                  backgroundColor: "surfaceActive",
                },
              },
              "& .components-tab-panel__tabs-item": {
                marginBottom: 1,
              },
              "& .components-tab-panel__tabs-item:hover": {
                backgroundColor: "surfaceHover",
                color: "inherit",
              },
              "& .components-tab-panel__tabs-item.is-active": {
                boxShadow: "none",
                backgroundColor: "primary",
                color: ["unset", "unset", "textPrimaryLight"],
              },
              " & .components-tab-panel__tabs-item:focus:not(:disabled)": {
                boxShadow: "focusInner",
              },
              "& .components-tab-panel__tab-content": {
                flexGrow: 1,
                maxWidth: panelLayout.width,
                height: "100%",
                overflowY: "auto",
                pl: 0,
                pr: 0,
              },
              ".components-tab-panel__tabs-item::after": {
                display: "none",
              },
              "& .components-tab-panel__tabs[aria-orientation=horizontal]": {
                mt: -7,
                mb: 0,
                pb: 2,
                pl: 0,
                border: "none",
                overflowX: "scroll",
                overflowY: "hidden",
                scrollBarWidth: "none",
                "& button": {
                  borderBottom: 0,
                  backgroundColor: "transparent",
                  mb: 0,
                  mx: 1,
                  py: 1,
                  height: 6,
                  borderRadius: 2,
                  "&:hover": {
                    backgroundColor: "surfaceHover",
                  },
                  "&.is-active, &:active": {
                    backgroundColor: "primary",
                    color: "textPrimaryLight",
                  },
                },
              },
            },
          }}
        >
          <TabPanel
            onSelect={(tabName: string) => {
              if (tabName !== tab) {
                viewStates.modalRouter.openSettings(tabName as settingsTab);
              }
            }}
            initialTabName={tab}
            orientation={panelLayout.orientation}
            tabs={tabs}
          >
            {() => (
              <div
                id="d1-settings-panel__content"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  flexGrow: 1,
                  width: [
                    "100dvw",
                    "calc(100vw - 32px)",
                    "calc(700px - 192px)",
                  ],
                }}
              >
                {showPanel()}
              </div>
            )}
          </TabPanel>
        </div>
        <DevDiv />
      </D1Modal>
    );
  },
);

Settings.displayName = "Settings";
