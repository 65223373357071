import JSZip from "jszip";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { markdownToRTJ } from "@/components/Editor/gb2rtj/md2gb";
import { EntryDBRow } from "@/data/db/migrations/entry";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { ClientMeta } from "@/data/repositories/V2API";
import { getClientMeta } from "@/data/utils/clientMeta";
import { calculateFeatureFlags } from "@/data/utils/entryFeatureFlags";
import { EntryExportJSON } from "@/utils/export";
import { decodeRichTextJson } from "@/utils/rtj";
import { mkEntryID } from "@/utils/uuid";

export type ImportJSONErrorType =
  | "INVALID_ZIP_FILE"
  | "JSON_FILE_NOT_FOUND"
  | "INVALID_JSON"
  | "JOURNAL_SAVE_FAILED"
  | "ENTRIES_FAILED";

export type ImportJSONError = {
  type: ImportJSONErrorType;
  details?: string[];
};

export type ImportJSONResult = {
  status: "success" | "error" | "warning";
  error?: ImportJSONError;
  journalId?: string;
};

export const importJSONZip = async (
  file: File,
  canEncrypt: boolean,
  existingJournals: JournalDBRow[],
): Promise<ImportJSONResult> => {
  const zip = new JSZip();
  let zipFile: JSZip | null = null;
  try {
    zipFile = await zip.loadAsync(file);
  } catch (e: any) {
    return {
      status: "error",
      error: {
        type: "INVALID_ZIP_FILE",
        details: [e.message || e.toString()],
      },
    };
  }

  const files = Object.keys(zipFile.files).map((key) => zipFile.files[key]);
  const jsonFile = files.find((file) => file.name.endsWith(".json"));
  if (!jsonFile) {
    return {
      status: "error",
      error: {
        type: "JSON_FILE_NOT_FOUND",
      },
    };
  }
  const contents = await jsonFile.async("text");
  let jsonContent = null;
  try {
    jsonContent = JSON.parse(contents);
  } catch (e: any) {
    Sentry.captureException(e);
    return {
      status: "error",
      error: {
        type: "INVALID_JSON",
        details: [e.message || e.toString()],
      },
    };
  }

  const journalName = getJournalName(jsonFile.name, existingJournals);
  const journal = {
    ...d1Classes.journalStore.blankJournal,
    name: journalName,
    e2e: canEncrypt ? 1 : 0,
    is_shared: false,
    comments_disabled: 1,
  };
  const journalAfterSave = await d1Classes.journalStore.saveJournal(journal);
  if (!journalAfterSave) {
    return {
      status: "error",
      error: {
        type: "JOURNAL_SAVE_FAILED",
      },
    };
  }
  analytics.tracks.recordEvent(EVENT.journalCreate, {
    shared_journal: journalAfterSave.is_shared,
    conceal: !!journalAfterSave.conceal,
    hide_on_this_day: !!journalAfterSave.hide_on_this_day,
    hide_today_view: !!journalAfterSave.hide_today_view,
    hide_streaks: !!journalAfterSave.hide_streaks,
    add_location_to_entries: journalAfterSave.add_location_to_new_entries,
  });

  const exportEntries = jsonContent.entries as EntryExportJSON[];
  const user = await d1Classes.userStore.getActiveUser();
  const defaultClientMeta = await getClientMeta();

  const entryPromises = exportEntries.map(async (entry) => {
    const entryId = mkEntryID(entry.uuid);
    const rtj = decodeRichTextJson(entry.richText);
    const contents =
      rtj.contents.length > 0
        ? rtj.contents
        : markdownToRTJ(entry.text, journalAfterSave.id, entryId);

    const clientMeta = {
      ...defaultClientMeta,
      creationDevice: entry.creationDevice,
      creationOSName: entry.creationOSName,
      creationOSVersion: entry.creationOSVersion,
      creationDeviceModel: entry.creationDeviceModel,
      creationDeviceType: entry.creationDeviceType,
    } as ClientMeta;

    const entryDBRow = {
      id: entryId,
      journal_id: journalAfterSave.id,
      owner_user_id: null,
      creator_user_id: user?.id ?? null,
      editor_user_id: null,
      body: entry.text,
      activity: entry.userActivity?.activityName,
      steps: entry.userActivity?.stepCount,
      stepIgnore: entry.userActivity?.ignoreStepCount,
      client_meta: JSON.stringify(clientMeta),
      date: new Date(entry.creationDate).getTime(),
      duration: entry.duration,
      edit_date: new Date(entry.modifiedDate).getTime(),
      editing_time: entry.editingTime,
      feature_flags: "10",
      is_all_day: entry.isAllDay ? 1 : 0,
      is_deleted: 0,
      is_pinned: entry.isPinned ? 1 : 0,
      is_starred: entry.starred ? 1 : 0,
      location: entry.location ? JSON.stringify(entry.location) : null,
      timezone: entry.timeZone,
      user_edit_date: new Date(entry.modifiedDate).getTime(),
      last_editing_device_name: clientMeta.deviceName,
      last_editing_device_id: clientMeta.deviceId,
      rich_text_json: entry.richText,
      templateID: entry.template?.uuid ?? null,
      promptID: entry.promptID ?? null,
      weather: entry.weather ? JSON.stringify(entry.weather) : null,
      reactions: [],
      unread_marker_id: null,
      is_shared: journalAfterSave.is_shared ? 1 : 0,
      hide_all_entries: journalAfterSave.hide_all_entries ? 1 : 0,
      music: entry.music,
    } as EntryDBRow;

    /* TODO: Update media feature flag checks once handling media */
    const featureFlags = calculateFeatureFlags(
      entryDBRow,
      {
        nonJpeg: false,
        audio: false,
        video: false,
        pdf: false,
        sketch: false,
        multipleAttachments: false,
      },
      rtj,
    );
    entryDBRow.feature_flags = featureFlags;
    const newEntry = await d1Classes.entryStore.importEntry(
      entryDBRow,
      contents,
      entry.tags || [],
    );
    return {
      uuid: entry.uuid,
      result: newEntry ? "success" : "error",
      id: newEntry?.id ?? null,
    };
  });

  const entryResults = await Promise.all(entryPromises);

  const failedEntries = entryResults.filter(
    (entry) => entry.result === "error",
  );
  if (failedEntries.length > 0) {
    return {
      status: "warning",
      error: {
        type: "ENTRIES_FAILED",
        details: failedEntries.map((entry) => entry.uuid),
      },
    };
  }
  return {
    status: "success",
    journalId: journalAfterSave.id,
  };
};

const getJournalName = (
  journalName: string,
  existingJournals: JournalDBRow[],
) => {
  let newJournalName = journalName.replace(".json", "");

  // Check if the name ends with a number and extract it
  const match = newJournalName.match(/^(.+?)\s*(\d+)$/);
  let baseName = newJournalName;
  let i = 2;

  if (match) {
    baseName = match[1].trim();
    i = parseInt(match[2], 10) + 1;
  }

  while (existingJournals.some((journal) => journal.name === newJournalName)) {
    newJournalName = `${baseName} ${i}`;
    i++;
  }
  return newJournalName;
};
