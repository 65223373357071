import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useRef, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Message } from "@/components/LoggedOut/Message";
import { DayOneLogo } from "@/components/icons/DayOneLogo";
import { getEncodedSymmetricKey } from "@/crypto/DOCrypto/utils";
import { useAuth } from "@/data/hooks/AuthProvider";
import { usePromise } from "@/hooks/d1_hooks";
import { uuid } from "@/utils/uuid";

export const QRCode = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isWindowVisible, setIsWindowVisible] = useState(true);
  const [key, setKey] = useState<CryptoKey | null>(null);
  const [url, setUrl] = useState("");
  const { __ } = useI18n();
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [nonce, setNonce] = useState(uuid());
  const [serverSecret, error, loading] = usePromise(
    () => d1Classes.userStore.getQRLoginSecret(nonce),
    [nonce],
  );
  const auth = useAuth();

  const requestAuth = async () => {
    if (!key || loading) return;
    try {
      const res = await auth.attemptQRLogin(nonce, serverSecret.secret, key);

      if (res === 404) {
        // request timed out, need to stop the interval
        // need to generate a new code
        console.info("Server auth code timed out. We will request a new one");
        setNeedsRefresh(true);
      }
      if (res === 408) {
        requestAuth();
      }
    } catch (e) {
      console.error("Catch in QRCode.requestAuth", e);
    }
  };
  useEffect(() => {
    requestAuth();
  }, [nonce, serverSecret, key]);

  useEffect(() => {
    if (isWindowVisible && needsRefresh) {
      reset();
    }
  }, [isWindowVisible, needsRefresh]);

  useEffect(() => {
    const setVisibility = () => {
      if (document.hidden) {
        setIsWindowVisible(false);
      } else {
        setIsWindowVisible(true);
      }
    };
    document.addEventListener("visibilitychange", setVisibility);
    return () => {
      document.removeEventListener("visibilitychange", setVisibility);
    };
  }, []);

  const reset = () => {
    const newNonce = uuid();
    setNonce(newNonce);
    setNeedsRefresh(false);
  };

  const copyUrl = (e: React.MouseEvent) => {
    if (e.detail === 2) {
      e.stopPropagation();
      e.preventDefault();
      // Clear any selection caused by double-clicking
      window.getSelection()?.removeAllRanges();
      navigator.clipboard.writeText(url);
    }
  };

  useEffect(() => {
    const generateQRCode = async () => {
      const QRCode = await import("qrcode");
      const canvas = canvasRef.current;
      const [key, encodedKey] = await getEncodedSymmetricKey();
      setKey(key);
      const urlEncodedKey = encodeURIComponent(encodedKey);
      const url = `${window.location.protocol}//${window.location.host}/qr-login?token=${serverSecret?.secret}#${urlEncodedKey}`;
      setUrl(url);
      QRCode.toCanvas(
        canvas,
        url,
        { errorCorrectionLevel: "H", margin: 0, width: 200 },
        (error) => {
          if (error) {
            console.error("Failed to generate QR code:", error);
          }
        },
      );
    };
    if (serverSecret) {
      generateQRCode();
    }
  }, [serverSecret, nonce]);

  if (error) return <ErrorMessage message="what is wrong?" />;
  return (
    <div
      sx={{
        display: "flex",
        maxWidth: "350px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {needsRefresh ? (
        <Button variant="primary" onClick={reset}>
          Reset QR Code
        </Button>
      ) : (
        <div
          onClick={copyUrl}
          sx={{
            position: "relative",
            ...(url && {
              padding: 3,
              backgroundColor: "#fff",
            }),
          }}
        >
          {url && (
            <div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "50px",
                height: "50px",
                backgroundColor: "#fff",
                borderRadius: "sm",
              }}
            >
              <DayOneLogo filled />
            </div>
          )}

          <canvas ref={canvasRef} />
        </div>
      )}
      <Message
        sx={{ my: 3, fontSize: 4, fontWeight: 700, color: "textPrimary" }}
      >
        {__("Log in with QR Code")}
      </Message>
      <Message>
        {__(
          "Scan this on any mobile device where you have the Day One app installed to log in and add your encryption key instantly.",
        )}
      </Message>
    </div>
  );
};
