import { makeAutoObservable, reaction } from "mobx";

import {
  ENTRY_LIST_ITEM_HEIGHT,
  EntryElement,
  HeaderElement,
  SHARED_ENTRY_LIST_ITEM_HEIGHT,
  TimeLineElement,
} from "@/components/TimelineView/constants";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import {
  EntryRepository,
  FullTimelineEntryID,
} from "@/data/repositories/EntryRepository";
import {
  normalizeDateForComparison,
  roundDateToMonth,
} from "@/utils/date-helper";
import { isDeepEqual } from "@/utils/is-equal";
import {
  journalId_AllEntries,
  PrimaryViewState,
} from "@/view_state/PrimaryViewState";
import { WindowHeightViewState } from "@/view_state/WindowHeightViewState";

export class TimelineViewState {
  constructor(
    private entryRepository: EntryRepository,
    private windowHeight: WindowHeightViewState,
    private primaryViewState: PrimaryViewState,
  ) {
    makeAutoObservable(
      this,
      {
        cancelIdsSubscription: false,
        getIndexForEntryId: false,
        getPositionForEntryId: false,
        getNextEntryId: false,
        getPrevEntryId: false,
        focusFirstEntry: false,
        fixScrollPosition: false,
      },
      { autoBind: true },
    );

    reaction(
      () => this.primaryViewState.selectedJournal,
      (selectedJournal) => {
        const journalId = selectedJournal?.id || journalId_AllEntries;

        const sortMethod = selectedJournal?.sort_method || "entryDate";
        if (this.journalId == journalId && this.sortMethod === sortMethod) {
          return;
        }
        this.loadNewJournal(journalId, sortMethod);
      },
      { name: "TimelineViewState_watchSelectedJournal", fireImmediately: true },
    );

    reaction(
      () => this.primaryViewState.selectedGlobalEntryID,
      (selectedGlobalEntryID, prevSelectedGlobalEntryID) => {
        if (
          selectedGlobalEntryID?.id &&
          selectedGlobalEntryID?.id !== prevSelectedGlobalEntryID?.id
        ) {
          this.focusFirstEntry();
        }
      },
    );

    reaction(
      () => this.allTimelineElements,
      () => {
        this.fixScrollPosition();
      },
      { name: "TimelineViewState_watchAllElements", fireImmediately: true },
    );
  }

  cancelIdsSubscription: null | (() => void) = null;
  allEntryIds: FullTimelineEntryID[] = [];
  allTimelineElements: TimeLineElement[] = [];
  journalId: string | undefined = undefined;

  scrollTop = 0;
  loading = true;
  sortMethod = "entryDate";

  // Actions!
  setScrollTop = (scrollTop: number) => {
    this.scrollTop = scrollTop;
  };

  // Actions that run in response to an update from the database
  // usually start with the text "got", like `gotBlah...`
  private loadNewJournal(journalId: string, sortMethod: string) {
    this.loading = true;
    this.journalId = journalId;
    this.allEntryIds = [];
    this.allTimelineElements = [];
    this.sortMethod = sortMethod;
    this.scrollTop = 0;
    this.cancelIdsSubscription?.();

    this.cancelIdsSubscription = this.entryRepository.subToAllDatedIDs(
      (ids) => this.gotNewEntryIds(journalId, ids),
      sortMethod,
      journalId,
    );
  }

  private gotNewEntryIds(
    journalId: string | undefined,
    ids: FullTimelineEntryID[],
  ) {
    // The entryIDs subscription might be long-lived. The user could
    // have changed the window height since the initial subscription was
    // made. There's also the technical possibility that we could get
    // a new set of entry IDs in this callback after the user had already
    // changed journals. Though that's unlikely since we cancel the
    // subscription above. But that depends on Dexie's implementation, and
    // whether they allow reults to come in after the subscription is cancelled.
    // We'll be defensive here and make some checks before setting state.
    // Initially we were memoizing too much state in here, and that caused
    // the entry list to go blank sometimes after scrolling, editing, or sitting idle.
    // We were never able to reliably reproduce the bug. But we think the fix was
    // to be more defensive this function, and not memoize old state (like the window height).
    if (journalId != this.journalId) {
      // Just in case the user changed journals while we waited
      // for the entryIDs subscription to return.
      return;
    }

    this.loading = false;

    // If we get the same set of IDs that we had before, skip. Sync will trigger
    // this function if there was a change to an existing document. So we might
    // get an ID set that's the same as it was before.
    // But we only do the comparison if there are IDs in the new set. On initial page
    // load when no journals are synced, we'll get an empty array here, which matches
    // the default state of _ids (an empty array). We don't want to shortcut in that
    // case, so that the loading state gets properly cleared.
    if (ids.length > 1 && isDeepEqual(ids, this.allEntryIds)) {
      return;
    }
    this.allEntryIds = ids;
    this.allTimelineElements = getTimelineElementsFromEntryIds(
      ids,
      journalId,
      this.sortMethod,
    );
    this.focusFirstEntry();
  }

  // Computed Views
  get window() {
    const padding = 12;
    const skip = getElementsToSkip(
      this.allTimelineElements,
      this.scrollTop,
      padding,
    );

    const minNumberToShow = Math.min(
      Math.ceil(this.windowHeight.height / 28), // 28 is the smallest size an item in the timeline could be
      this.allTimelineElements.length,
    );
    const take = minNumberToShow + padding;

    const slice = this.allTimelineElements.slice(
      skip,
      Math.min(skip + take, this.allTimelineElements.length),
    );

    return {
      totalEntryCount: this.onlyEntries.length,
      fullHeightInPx: getFullHeightInPx(this.allTimelineElements),
      visibleSlice: slice,
      entriesSkipped: skip,
    };
  }

  get firstEntry() {
    for (let i = 0; i < this.allTimelineElements.length; i++) {
      if (this.allTimelineElements[i].type !== "header") {
        return this.allTimelineElements[i] as EntryElement;
      }
    }
    return null;
  }

  get firstEntryId() {
    for (let i = 0; i < this.allTimelineElements.length; i++) {
      if (this.allTimelineElements[i].type !== "header") {
        const e = this.allTimelineElements[i] as EntryElement;
        return { journal_id: e.journal_id, id: e.id };
      }
    }
    return null;
  }
  get lastEntryId() {
    for (let i = this.allTimelineElements.length - 1; i >= 0; i--) {
      if (this.allTimelineElements[i].type !== "header") {
        const e = this.allTimelineElements[i] as EntryElement;
        return { journal_id: e.journal_id, id: e.id };
      }
    }
    return null;
  }

  get onlyEntries() {
    return this.allTimelineElements.filter((x) => x.type !== "header");
  }

  // Utility functions, these should be set to "false"
  // in the makeAutoObservable call above.
  focusFirstEntry() {
    if (!this.primaryViewState.selectedGlobalEntryID && this.firstEntry) {
      const entryElementId = `entry-list-item-${this.firstEntry.id}`;
      const interval = setInterval(() => {
        const element = document.getElementById(entryElementId);
        if (element && this.firstEntry) {
          this.primaryViewState.focusEntry({
            journal_id: this.firstEntry.journal_id,
            id: this.firstEntry.id,
          });
          clearInterval(interval);
        }
      }, 200);
    }
  }

  fixScrollPosition() {
    if (this.primaryViewState.selectedGlobalEntryID) {
      const entryVisible = this.window.visibleSlice.find(
        (el) =>
          el.type === "entry" &&
          el.journal_id ===
            this.primaryViewState.selectedGlobalEntryID?.journal_id &&
          el.id === this.primaryViewState.selectedGlobalEntryID.id,
      );
      const entryPosition = this.getPositionForEntryId(
        this.primaryViewState.selectedGlobalEntryID?.journal_id,
        this.primaryViewState.selectedGlobalEntryID?.id,
      );

      if (
        !entryVisible ||
        entryPosition.position < this.scrollTop ||
        entryPosition.position >
          this.scrollTop + this.windowHeight.height - entryPosition.height
      ) {
        this.setScrollTop(entryPosition.position);
      }
    }
  }

  getIndexForEntryId = (
    journalId: string | undefined,
    entryId: string | undefined,
  ) => {
    const idx = this.onlyEntries.findIndex(
      (x) =>
        ["entry", "shared"].includes(x.type) &&
        x.journal_id === journalId &&
        x.id === entryId,
    );

    return idx;
  };

  getPositionForEntryId = (
    journalId: string | undefined,
    entryId: string | undefined,
  ) => {
    const entry = this.allTimelineElements.find(
      (x) =>
        x.type !== "header" && x.journal_id === journalId && x.id === entryId,
    );
    if (!entry) {
      return { position: 0, height: 0 };
    }
    return { position: entry.offset, height: entry.height }; // idx * this.entryHeight;
  };

  getNextEntryId = (journalId?: string, entryId?: string) => {
    if (!journalId || !entryId) return null;
    const idx = this.allTimelineElements.findIndex(
      (x) =>
        x.type !== "header" && x.journal_id === journalId && x.id === entryId,
    );
    // If it's not found, or we're already on the last one, return null
    if (idx === -1 || idx === this.allTimelineElements.length - 1) {
      return null;
    }
    for (let i = idx + 1; i < this.allTimelineElements.length; i++) {
      if (this.allTimelineElements[i].type !== "header") {
        return this.allTimelineElements[i] as EntryElement;
      }
    }
    return null;
  };

  getPrevEntryId = (journalId?: string, entryId?: string) => {
    if (!journalId || !entryId) {
      return this.firstEntry;
    }
    const idx = this.allTimelineElements.findIndex(
      (x) =>
        x.type !== "header" && x.journal_id === journalId && x.id === entryId,
    );
    if (idx === -1 || idx === 0) {
      return null;
    }
    for (let i = idx - 1; i >= 0; i--) {
      if (this.allTimelineElements[i].type !== "header") {
        return this.allTimelineElements[i] as EntryElement;
      }
    }
    return null;
  };

  getGlobalEntryIDForIndex = (index: number) => {
    if (
      this.allTimelineElements[index] &&
      this.allTimelineElements[index].type !== "header"
    ) {
      return this.allTimelineElements[index] || null;
    } else {
      return null;
    }
  };

  getGlobalEntryIdsBetweenIndex = (
    startIndex: number,
    endIndex: number,
  ): GlobalEntryID[] => {
    const ids = [];
    const entries = this.allTimelineElements
      .filter((x) => x.type !== "header")
      .slice(startIndex, endIndex + 1);
    for (let i = 0; i <= endIndex; i++) {
      const entry = entries[i];

      if (entry) {
        ids.push({
          journal_id: entry.journal_id,
          id: entry.id,
        });
      }
    }
    return ids;
  };
}

function getTimelineElementsFromEntryIds(
  ids: FullTimelineEntryID[],
  journalId?: string,
  sortMethod?: string,
) {
  let previousMonth = 0;
  let previousDay = "";
  let offset = 0;

  const [pinned, rest] =
    journalId === journalId_AllEntries || !journalId
      ? [[], [...ids]]
      : ids.reduce(
          (acc, entry) => {
            if (entry.is_pinned) {
              acc[0].push(entry);
            } else {
              acc[1].push(entry);
            }
            return acc;
          },
          [[], []] as [FullTimelineEntryID[], FullTimelineEntryID[]],
        );

  const timelineItems = [];

  if (pinned.length > 0) {
    timelineItems.push({
      type: "header",
      label: "Pinned",
      offset,
      height: 28,
    } as HeaderElement); //pinned header
    offset += 28;
    for (const [i, entry] of pinned.entries()) {
      timelineItems.push({
        type: entry.is_shared ? "shared" : "entry",
        id: entry.id,
        journal_id: entry.journal_id,
        offset,
        height: entry.is_shared
          ? SHARED_ENTRY_LIST_ITEM_HEIGHT
          : ENTRY_LIST_ITEM_HEIGHT,
        showDate: true,
        isFirstInBlock: i === 0,
      } as EntryElement);
      offset += entry.is_shared
        ? SHARED_ENTRY_LIST_ITEM_HEIGHT
        : ENTRY_LIST_ITEM_HEIGHT;
    }
  }

  if (rest.length > 0 && sortMethod === "editDate") {
    timelineItems.push({
      type: "header",
      label: "Recent",
      offset,
      height: 28,
    } as HeaderElement); //pinned header
    offset += 28;
  }

  const restEntries = rest.reduce((acc, entry, i: number) => {
    const month = roundDateToMonth(entry.date);
    const day = normalizeDateForComparison(entry.date, entry.timezone);
    const isFirstInBlock =
      (month !== previousMonth && sortMethod === "entryDate") || i === 0;

    if (month !== previousMonth && sortMethod === "entryDate") {
      const date = new Date(month);
      acc.push({
        type: "header",
        label: date.toLocaleString("default", {
          month: "long",
          year: "numeric",
        }),
        offset,
        height: 28,
      } as HeaderElement);
      previousMonth = month;
      offset += 28;
    }
    acc.push({
      type: entry.is_shared ? "shared" : "entry",
      id: entry.id,
      journal_id: entry.journal_id,
      offset,
      height: entry.is_shared
        ? SHARED_ENTRY_LIST_ITEM_HEIGHT
        : ENTRY_LIST_ITEM_HEIGHT,
      showDate: day !== previousDay,
      isFirstInBlock,
    } as EntryElement);
    previousDay = day;
    offset += entry.is_shared
      ? SHARED_ENTRY_LIST_ITEM_HEIGHT
      : ENTRY_LIST_ITEM_HEIGHT;

    return acc;
  }, [] as TimeLineElement[]);
  return [...timelineItems, ...restEntries];
}

function getElementsToSkip(
  allElements: TimeLineElement[],
  scrollTop: number,
  padding: number,
) {
  if (scrollTop === 0) {
    return 0;
  }
  for (let i = 0; i < allElements.length; i++) {
    if (
      allElements[i].offset <= scrollTop &&
      allElements[i].offset + allElements[i].height > scrollTop
    ) {
      return Math.max(0, i - padding);
    }
  }
  return 0;
}

function getFullHeightInPx(allElements: TimeLineElement[]): number {
  if (allElements.length === 0) {
    return 0;
  }

  return (
    allElements[allElements.length - 1].offset +
    allElements[allElements.length - 1].height
  );
}
