// There's a good bit of content that appears in a D1 modal, and is addressable through
// the query string. This component renders the appropriate content based on the query string.

import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { ReactElement } from "react";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { RecentPromptsModal } from "@/components/DailyPrompts/RecentPromptsModal";
import { MetadataModal } from "@/components/Editor/components/MetadataModal/MetadataModal";
import { ExportModal } from "@/components/Export/ExportModal";
import { JournalPickerModal } from "@/components/JournalPickerModal";
import { EditJournal } from "@/components/JournalSettings/EditJournal";
import { ManageMemberModal } from "@/components/JournalSettings/ManageMemberModal";
import { NewJournal } from "@/components/JournalSettings/NewJournal";
import { SharedJournalInviteLinkModal } from "@/components/JournalSettings/SharedJournalInviteLinkModal";
import { SharedJournalSettingsModal } from "@/components/JournalSettings/SharedJournalSettingsModal";
import { ReportProblemModal } from "@/components/JournalSidebar/ReportProblemModal";
import { NotificationsModal } from "@/components/Notifications/NotificationsModal";
import { PendingApprovalModal } from "@/components/Notifications/PendingApprovalModal";
import { PendingLogout } from "@/components/PendingLogout";
import { SharedJournalsUpgradeModal } from "@/components/PremiumUpgrade/SharedJournalsUpgradeModal";
import { UpgradeModal } from "@/components/PremiumUpgrade/UpgradeModal";
import Search from "@/components/Search";
import { AdvancedFilterModal } from "@/components/Search/AdvancedFilterModal";
import { Settings } from "@/components/Settings";
import { MasterKeyModal } from "@/components/Settings/Account/MasterKeyModal/MasterKeyModal";
import { PasskeysManagerModal } from "@/components/Settings/Account/PasskeysManagerModal";
import { SyncDetailsModal } from "@/components/Settings/Advanced/SyncDetails/SyncDetailsModal";
import { SharedJournalFeaturesModal } from "@/components/SharedJournals/SharedJournalFeaturesModal";
import { SharedJournalsInfoModal } from "@/components/SharedJournals/SharedJournalsInfoModal";
import { ManageTemplates } from "@/components/Templates";
import { useCreateNewEntry } from "@/hooks/useCreateNewEntry";
import { useScrollIntoView } from "@/hooks/useScrollIntoView";
import {
  ModalRouteLayer1,
  ModalRouteLayer2,
  ModalRouteLayer3,
} from "@/layouts/ModalRoutes";
import { switchOnRecordName } from "@/utils/switching";
import { journalId_AllEntries } from "@/view_state/PrimaryViewState";
import {
  primaryViewState as primary,
  activeEntryViewState as activeEntry,
  modalRouterViewState as modalRouter,
  snackbarViewState as snackbar,
  syncViewState as sync,
} from "@/view_state/ViewStates";

export const ModalRouterComponent = observer(() => {
  const route1 = modalRouter.active[1];
  const route2 = modalRouter.active[2];
  const route3 = modalRouter.active[3];

  const journalPickerAnalyticsMethod = "journal_picker";
  const { createNewEntryHandler, validJournals } = useCreateNewEntry(
    journalPickerAnalyticsMethod,
  );
  const { __ } = useI18n();

  useScrollIntoView(
    route1 && route1.name === "settings" ? route1.element : null,
  );

  const component1 =
    route1 === null
      ? null
      : switchOnRecordName<ModalRouteLayer1, ReactElement>(
          route1,
          {
            add_entry_to_journal: ({ journal_disabled }) => {
              const isInAllEntries =
                primary.selectedJournalId === journalId_AllEntries;
              const selectedJournalName = activeEntry.selectedJournal?.name;

              // This should only happen for shared Journals, when a non-owner user
              // tries to add an entry.
              const title =
                !isInAllEntries && selectedJournalName && journal_disabled
                  ? `${selectedJournalName} ${__(" has been deactivated")}`
                  : __("No selected journal");
              const messages =
                !isInAllEntries && journal_disabled
                  ? [
                      __(
                        "This shared journal has been deactivated because the owner's Premium membership has expired.",
                      ),
                      __("Please select another journal to add an entry to:"),
                    ]
                  : [__("Please select a journal to add an entry to:")];
              return (
                <JournalPickerModal
                  title={title}
                  handleClose={() => modalRouter.close()}
                  onPickHandler={createNewEntryHandler}
                  journals={validJournals}
                  messages={messages}
                />
              );
            },
            new_journal: () => (
              <NewJournal
                handleClose={() => modalRouter.close()}
                journalStore={d1Classes.journalStore}
                primaryViewState={primary}
                modalRouterViewState={modalRouter}
              />
            ),
            new_shared_journal: () => (
              <NewJournal
                handleClose={() => modalRouter.close()}
                journalStore={d1Classes.journalStore}
                primaryViewState={primary}
                isShared={true}
                modalRouterViewState={modalRouter}
              />
            ),
            settings: (route) => (
              <Settings
                tab={route.tab}
                handleClose={() => modalRouter.close()}
              />
            ),
            support_form: () => (
              <ReportProblemModal onClose={() => modalRouter.close()} />
            ),
            notifications: () => {
              analytics.tracks.recordEvent(EVENT.screenView, {
                screen: "userNotifications",
              });
              return (
                <NotificationsModal handleClose={() => modalRouter.close()} />
              );
            },
            shared_journal_settings: (route) => (
              <SharedJournalSettingsModal
                journal={primary.getJournalById(route.journal_id) ?? null}
                handleClose={() => modalRouter.close()}
              />
            ),
            template_management: (route) => (
              <ManageTemplates
                tab={route.tab}
                templateId={route.template_id}
                handleClose={() => modalRouter.close()}
              />
            ),
            show_shared_journals_info: (route) => (
              <SharedJournalsInfoModal
                onClose={() => {
                  modalRouter.close();
                }}
                openNewOnClose={route.openNewOnClose}
              />
            ),
            show_search: () => (
              <Search
                onClose={() => {
                  modalRouter.close();
                }}
              />
            ),
            show_advanced_search: () => (
              <AdvancedFilterModal
                onClose={() => {
                  modalRouter.showSearch();
                }}
              />
            ),
            show_recent_prompts: () => (
              <RecentPromptsModal
                onClose={() => {
                  modalRouter.close();
                }}
              />
            ),
            sync_details: () => (
              <SyncDetailsModal
                onClose={() => {
                  modalRouter.close();
                }}
              />
            ),
            entry_metadata: (route) => (
              <MetadataModal
                entryId={route.entry_id}
                journalId={route.journal_id}
                activeEntryViewState={activeEntry}
                handleClose={() => {
                  modalRouter.close();
                }}
              />
            ),
          },
          <></>,
        );

  const component2 =
    route2 === null
      ? null
      : switchOnRecordName<ModalRouteLayer2, ReactElement>(
          route2,
          {
            edit_journal: (route) => (
              <EditJournal
                journalStore={d1Classes.journalStore}
                journalID={route.journal_id}
                handleClose={() => modalRouter.closeLayer2()}
                primaryViewState={primary}
                snackbar={snackbar}
              />
            ),

            premium_upgrade: (route) => {
              return route.type === "shared_journal" ? (
                <SharedJournalsUpgradeModal
                  onClose={() => {
                    modalRouter.closeLayer2();
                  }}
                />
              ) : (
                <UpgradeModal
                  onClose={() => {
                    modalRouter.closeLayer2();
                  }}
                />
              );
            },

            passkeys_manager: () => (
              <PasskeysManagerModal onClose={() => modalRouter.closeLayer2()} />
            ),

            e2ee_masterkey: (route) => {
              const onClose = (key?: string | undefined | null) =>
                !key && route.is_creating_shared_journal
                  ? // If we're creating a shared journal, and the user closes the modal
                    // without entering a key, we need to close the key modal and also
                    // close the journal form modal.
                    modalRouter.close()
                  : // But in any other situation, we just close the key modal.
                    modalRouter.closeLayer2();

              return (
                <MasterKeyModal
                  onClose={onClose}
                  openedFrom={route.opened_from}
                  isCreatingSharedJournal={route.is_creating_shared_journal}
                  agreedToCreateKey={route.agreed_to_create_key}
                  journalStore={d1Classes.journalStore}
                  primaryViewState={primary}
                  snackbar={snackbar}
                  hasDeniedKeyRetrieval={route.deny_retrieve}
                />
              );
            },
          },

          <></>,
        );

  const component3 =
    route3 === null
      ? null
      : switchOnRecordName<ModalRouteLayer3, ReactElement>(
          route3,
          {
            shared_journal_member: (route) => {
              const journal = primary.getJournalById(route.journal_id);
              if (!journal) {
                return <></>;
              }
              const sharedJournalSettings =
                primary.getSharedJournalSettings(journal);
              return (
                <ManageMemberModal
                  journalSettings={sharedJournalSettings}
                  participantId={route.user_id}
                  onClose={() => {
                    modalRouter.closeLayer3();
                  }}
                />
              );
            },

            shared_journal_invite: (route) => {
              const journal = primary.getJournalById(route.journal_id);
              if (!journal) {
                return <>No Journal Found</>;
              }
              analytics.tracks.recordEvent(EVENT.screenView, {
                screen: "sharedJournal_addMembersView",
              });
              return (
                <SharedJournalInviteLinkModal
                  journal={journal}
                  handleClose={() => {
                    modalRouter.closeLayer3();
                  }}
                />
              );
            },

            pending_approvals: () => {
              return (
                <PendingApprovalModal
                  handleClose={() => modalRouter.closeLayer3()}
                />
              );
            },

            shared_journal_features: (route) => {
              return (
                <SharedJournalFeaturesModal
                  user={route.user}
                  onClose={() => modalRouter.closeLayer3()}
                />
              );
            },

            export: (route) => (
              <ExportModal
                journalId={route.journal_id}
                handleClose={() => {
                  modalRouter.closeLayer3();
                }}
              />
            ),
          },
          <></>,
        );

  return (
    <>
      {component1}
      {component2}
      {component3}
      {primary.pendingLogout && <PendingLogout outboxSize={sync.outboxSize} />}
    </>
  );
});
