export const Location: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3541 6.76875C12.5492 6.96383 12.5492 7.28097 12.3541 7.47605C12.1591 7.67113 11.8419 7.67113 11.6469 7.47605C11.4518 7.28097 11.4518 6.96383 11.6469 6.76875C11.8419 6.57367 12.1581 6.57367 12.3541 6.76875"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3118 7.22498V7.22498C16.3118 8.39646 15.7916 9.50593 14.8922 10.2562L13.4786 11.4347C12.9154 11.9049 12.4942 12.5222 12.2621 13.2185L12 14.0008L11.7389 13.2175C11.5068 12.5222 11.0856 11.9039 10.5224 11.4337L9.10879 10.2552C8.20841 9.50493 7.68919 8.39546 7.68919 7.22398V7.22398V7.24599C7.68819 4.55687 9.91612 2.99622 12 2.99622C14.0839 2.99622 16.3118 4.55687 16.3118 7.24699"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99849 12C5.0451 12 4.22375 12.6733 4.03668 13.6087L3.03626 18.6108C2.78816 19.8483 3.73555 21.0037 4.99808 21.0037H19.0029C20.2654 21.0037 21.2128 19.8483 20.9647 18.6108L19.9643 13.6087C19.7772 12.6733 18.9559 12 18.0025 12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
