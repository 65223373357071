import { Draggable, Icon, Popover } from "@wordpress/components";
import { dragHandle, image } from "@wordpress/icons";

import { Z_INDEX_GALLERY_MEDIA_BUTTONS } from "@/styles/theme";

export const TRANSFER_DATA_TYPE = "dayone/draggable-media";

export const DraggableMediaButton = ({
  clientId,
  onDragStart,
  onDragEnd,
  addRightMargin,
}: {
  clientId: string;
  onDragStart: () => void;
  onDragEnd: () => void;
  addRightMargin?: boolean;
}) => {
  return (
    <Popover
      focusOnMount={false}
      animate={false}
      placement="right-start"
      sx={{
        zIndex: Z_INDEX_GALLERY_MEDIA_BUTTONS,
      }}
    >
      <Draggable
        elementId={`image-${clientId}`}
        transferData={{
          clientId,
        }}
        __experimentalDragComponent={
          <Icon
            icon={image}
            color="white"
            sx={{
              backgroundColor: "black",
              borderRadius: "xs",
              height: "24px",
              opacity: "0.5",
              fill: "white",
              "> svg": { fill: "white" },
            }}
          />
        }
        // This prevents pasting the data in the clipboard
        __experimentalTransferDataType={TRANSFER_DATA_TYPE}
      >
        {({ onDraggableStart, onDraggableEnd }) => (
          <div
            role="button"
            className="image-drag-button"
            key={clientId + "button"}
            sx={{
              position: "absolute",
              right: addRightMargin ? "32px" : 0,
              cursor: "pointer",
              backgroundColor: "black",
              borderRadius: "xs",
              height: "24px",
              opacity: "0.5",
              "> svg": { fill: "white" },
            }}
            draggable={true}
            onDragStart={(e) => {
              // Somehow this is the only way to send the data on `transferData`
              e.dataTransfer.clearData();
              onDragStart();
              onDraggableStart(e);
            }}
            onDragEnd={(e) => {
              onDragEnd();
              onDraggableEnd(e);
            }}
          >
            <Icon icon={dragHandle} />
          </div>
        )}
      </Draggable>
    </Popover>
  );
};
