import { Toolbar, createSlotFill } from "@wordpress/components";
import { observer } from "mobx-react-lite";

import { useBlockSelection } from "../hooks/blockSelection";

import { CODE_BLOCK_ID } from "@/components/Editor/blocks/constants";
import { toolbarViewState } from "@/view_state/ViewStates";

export const CONTEXT_FORMAT_TOOLBAR_SLOT_FILL = createSlotFill(
  "dayone/context-format-toolbar",
);

const contextToolbarStyles = {
  background: "contextToolbarBg",
  border: "none",
  alignItems: "center",
  borderRadius: "sm",
  height: 5,
};

export const ContextFormattingToolbar = observer(() => {
  const { getSelectedBlockClientIds, getBlockName } = useBlockSelection();
  // if any of the selected blocks are CodeBlock, don't show the toolbar
  const selectedBlockClientIds = getSelectedBlockClientIds();
  const hasCodeBlock = selectedBlockClientIds.some((clientId: string) => {
    const blockName = getBlockName(clientId);
    return blockName === CODE_BLOCK_ID;
  });

  if (hasCodeBlock || toolbarViewState.isHeadingTransformDropdownOpen) {
    return null;
  }

  return (
    <Toolbar sx={contextToolbarStyles} label="Options">
      {/* Rich text formatting options  */}
      <CONTEXT_FORMAT_TOOLBAR_SLOT_FILL.Slot />
    </Toolbar>
  );
});

ContextFormattingToolbar.displayName = "ContextFormattingToolbar";
