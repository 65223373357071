import { useI18n } from "@wordpress/react-i18n";

import { ItemGroup } from "@/components/ItemGroup";
import { AutoTitleFirstLine } from "@/components/Settings/Entries/AutoTitleFirstLine";
import { Hashtags } from "@/components/Settings/Entries/Hashtags";
import { Temperature } from "@/components/Settings/Entries/Temperature";
import { SettingsPanel } from "@/components/Settings/SettingsPanel";

export const Entries: React.FC = () => {
  const { __ } = useI18n();
  return (
    <SettingsPanel header={<span>{__("Entries")}</span>}>
      <ItemGroup addMargin={true}>
        <Hashtags />
        <AutoTitleFirstLine />
        <Temperature />
      </ItemGroup>
    </SettingsPanel>
  );
};
