import { liveQuery } from "dexie";

import { Sentry } from "@/Sentry";
import { DOCrypto } from "@/crypto/DOCrypto";
import { md5 } from "@/crypto/utils/md5";
import { DODexie } from "@/data/db/dexie_db";
import { JournalCoverDBRow } from "@/data/db/migrations/journal_cover";
import { JournalRepository } from "@/data/repositories/JournalRepository";
import { MomentRepository } from "@/data/repositories/MomentRepository";
import { UserRepository } from "@/data/repositories/UserRepository";
import { JournalCover } from "@/data/repositories/V6API";
import { VaultRepository } from "@/data/repositories/VaultRepository";
import { isValidMedia } from "@/data/utils/moments/media";
import { convertSVGToPNG, loadFileArrayBuffer } from "@/utils/file-helper";
import { uuid } from "@/utils/uuid";

export class JournalCoverRepository {
  constructor(
    protected db: DODexie,
    private userRepository: UserRepository,
    private journalRepository: JournalRepository,
    private vaultRepository: VaultRepository,
    private momentRepository: MomentRepository,
  ) {}

  async getCover(journalId: string) {
    const journal = await this.journalRepository.getById(journalId);

    if (!journal || !journal.cover_photo) {
      return null;
    }
    const haveCover = await this.db.journal_covers.get({
      journal_id: journalId,
    });

    if (haveCover && haveCover.md5 === journal.cover_photo.md5) {
      return haveCover;
    }
    const cover = journal.cover_photo;

    return this.downloadAndSaveCover(cover, journalId);
  }

  async downloadAndSaveCover(cover: JournalCover, journalId: string) {
    const blob = await this.momentRepository.fetchAndDecryptMediaById(
      cover.client_id,
      journalId,
      false,
      `Failed to load media for journal cover ${cover.client_id} for journal ${journalId}`,
    );

    if (!isValidMedia(blob)) {
      return null;
    }
    const file = new File([blob], cover.client_id, {
      type: cover.content_type,
    });
    const arrayBuffer = await loadFileArrayBuffer(file);
    if (!arrayBuffer) {
      return null;
    }
    await this.db.journal_covers.put({
      journal_id: journalId,
      moment_id: cover.client_id,
      md5: cover.md5,
      content_type: cover.content_type,
      moment_type: "image",
      data: new Uint8Array(arrayBuffer),
    });

    return this.db.journal_covers.get({
      journal_id: journalId,
    });
  }

  async removeJournalCover(journalId: string) {
    await this.journalRepository.updateJournalWithCover(journalId, null);
    await this.db.journal_covers.where("journal_id").equals(journalId).delete();
  }

  async updateJournalCover(journalId: string, file: File) {
    const previousCover = await this.db.journal_covers.get({
      journal_id: journalId,
    });
    const journalIsE2EE = await this.journalRepository.isJournalE2EE(journalId);
    const vault = journalIsE2EE
      ? await this.vaultRepository.getVaultByJournalId(journalId)
      : null;
    const cover = await this.createJournalCoverFromFile(file, journalId);

    const user = await this.userRepository.getActiveUser();
    if (!user || !cover) {
      return;
    }
    let data;
    if (vault) {
      data = await DOCrypto.JournalKey.encrypt(
        cover.data,
        vault.vault.keys[0],
        2,
      );
    } else {
      data = cover.data;
    }
    const md5Hash = await md5(data);
    // Upload the cover image to S3
    const s3Url = `${user.sync_upload_base_url}/v2-${journalId}-${md5Hash}`;
    const resp = await fetch(s3Url, {
      method: "put",
      headers: {
        "Content-Type": cover.content_type,
        "x-amz-meta-journal-id": journalId,
        "x-amz-meta-moment-id": cover.moment_id,
        "x-amz-meta-md5": md5Hash,
        "x-amz-acl": "bucket-owner-full-control",
        "x-amz-server-side-encryption": "AES256",
      },
      body: data,
    });

    if (resp.ok) {
      const ok = await this.journalRepository.updateJournalWithCover(
        journalId,
        cover,
      );
      if (!ok) {
        this.restoreCover(journalId, previousCover);
        Sentry.captureMessage(
          `Failed to update journal cover for journal ${journalId}`,
        );
        throw new Error("Failed to update journal cover");
      }
    } else {
      this.restoreCover(journalId, previousCover);
      Sentry.captureMessage(
        `Failed to upload journal cover to S3 for journal ${journalId}`,
      );
      throw new Error("Failed to upload journal cover image");
    }
  }

  restoreCover(journalId: string, previousCover?: JournalCoverDBRow) {
    if (previousCover) {
      this.db.journal_covers.put(previousCover);
    } else {
      this.db.journal_covers.where("journal_id").equals(journalId).delete();
    }
  }

  async createJournalCoverFromFile(file: File, journalId: string) {
    if (file.type.includes("image/svg")) {
      file = await convertSVGToPNG(file);
    }
    const arrayBuffer = await loadFileArrayBuffer(file);
    if (arrayBuffer) {
      const md5Hash = await md5(arrayBuffer);
      const momentId = uuid().split("-").join("").toUpperCase();

      await this.db.journal_covers.put({
        journal_id: journalId,
        moment_id: momentId,
        md5: md5Hash,
        content_type: file.type,
        moment_type: "image",
        data: new Uint8Array(arrayBuffer),
      });

      return this.db.journal_covers.get({
        journal_id: journalId,
      });
    }
    return null;
  }

  removeCoverForJournals(journalIds: string[]) {
    return this.db.journal_covers
      .where("journal_id")
      .anyOf(journalIds)
      .delete();
  }

  subscribeToJournalCover(
    journalId: string,
    callback: (cover: JournalCoverDBRow | undefined) => void,
  ) {
    const sub = liveQuery(async () => {
      const cover = await this.db.journal_covers
        .where("journal_id")
        .equals(journalId)
        .first();

      return cover;
    }).subscribe(callback, (err) => {
      Sentry.captureException(err);
    });
    return () => {
      sub.unsubscribe();
    };
  }
}
