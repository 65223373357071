import { BlockInstance } from "@wordpress/blocks";
import { debounce } from "@wordpress/compose";
import { count } from "@wordpress/wordcount";
import { gzipSync } from "fflate";
import { makeAutoObservable, reaction } from "mobx";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import { convertBlocksToMarkdown } from "@/components/Editor/gb2rtj/gb2md";
import { convertGBBlocksToRTJNodes } from "@/components/Editor/gb2rtj/gb2rtj";
import { MediaBlocks } from "@/components/Editor/utils/register-blocks";
import {
  getEntryBlocks,
  getEntryBlocksWithMissingMoments,
} from "@/components/EditorPanel/GutenbergEntryTools";
import { Utf8 } from "@/crypto/utf8";
import { toBase64 } from "@/crypto/utils";
import { Activity, GlobalEntryID } from "@/data/db/migrations/entry";
import { ReactionDBRow } from "@/data/db/migrations/reaction";
import { EntryModel, globalEntryIDsAreEqual } from "@/data/models/EntryModel";
import { MomentRepository } from "@/data/repositories/MomentRepository";
import { getTagsFromContent } from "@/data/repositories/TagParser";
import { TagRepository } from "@/data/repositories/TagRepository";
import { EntryStore } from "@/data/stores/EntryStore";
import { FeatureFlagsViewState } from "@/data/stores/FeatureFlagViewState";
import { ReactionStore } from "@/data/stores/ReactionStore";
import { TagStore } from "@/data/stores/TagStore";
import { validateEntryFeatureFlags } from "@/data/utils/entryFeatureFlags";
import { createRichTextJsonString } from "@/utils/rtj";
import { uuid } from "@/utils/uuid";
import { PrimaryViewState } from "@/view_state/PrimaryViewState";
import { UserSettingsViewState } from "@/view_state/UserSettingsViewState";

export type Blocks = BlockInstance<{ [key: string]: any }>[];

export type updateSkippedReason =
  | "old_user_edit_date"
  | "null_entry_model"
  | "entry_no_longer_selected";

export class ActiveEntryViewState {
  lastEdit: null | number = null;

  // This is here to help the tests know when the user has
  // actually ignored an update, and why it did so.
  _for_test_skippedLastUpdateBecause: null | updateSkippedReason = null;
  _for_test_forceEntryUpdate = (entryModel: EntryModel | null) => {
    this.gotNewEntry(entryModel);
  };

  // to show the last saved entry blocks in the editor. This is helpful in tests
  _for_test_savedEntryBlocks: Blocks = [];

  // the blocks as loaded from the entry model
  momentCount = 0;
  tags: string[] = [];
  reactions: ReactionDBRow[] = [];
  error:
    | null
    | "entry-not-found"
    | "failed-loading-blocks"
    | "failed-saving-entry" = null;

  entryModel: EntryModel | null = null;
  loading = false;
  mostRecentBlocks: Blocks | null = null;
  currentlyAddingMedia = false;
  isMoving = false;
  allFeatureFlagsValid = true;

  private idleTimer: NodeJS.Timeout | null = null;
  private unsubscribeFromEntryModel: null | (() => void) = null;
  private unsubscribeFromEntryMove: null | (() => void) = null;
  private unsubscribeFromMomentCount: null | (() => void) = null;
  private unsubscribeFromTags: null | (() => void) = null;
  private unsubscribeFromReactions: null | (() => void) = null;

  constructor(
    private primary: PrimaryViewState,
    private entryStore: EntryStore,
    private momentRepository: MomentRepository,
    private tagRepository: TagRepository,
    private tagStore: TagStore,
    private featureFlags: FeatureFlagsViewState,
    private reactionStore: ReactionStore,
    private userSettingsViewState: UserSettingsViewState,
  ) {
    makeAutoObservable(
      this,
      {
        _for_test_forceEntryUpdate: false,
        _for_test_skippedLastUpdateBecause: false,
        lastEdit: false,
      },
      { autoBind: true },
    );
    this.watchForSelectionChanges();

    // If the setting for creating tags from hashtags is changed we enable or disable
    // the hashtag autocompleter from the editor. The way things are set up this change only takes
    // effect for new blocks added to the editor. Existing blocks don't get the updated
    // autocompleters until they are reloaded.
    // This sets up a subscription so that when the setting is changed we can force reload the entry into
    // the editor so all blocks get the updated autocompleters.
    reaction(
      () => {
        return (
          this.userSettingsViewState.settings?.create_tags_from_hashtags ??
          false
        );
      },
      () => {
        this.gotCreateHashtagUpdate();
      },
    );
  }

  get blocksFromEntryModel(): Blocks {
    if (this.entryModel) {
      const blocks = this.getBlocksFromEntry(this.entryModel);
      return blocks === "error" ? [] : blocks;
    }
    return [];
  }

  private gotCreateHashtagUpdate() {
    if (this.primary.selectedGlobalEntryID) {
      this.loadEntry(this.primary.selectedGlobalEntryID);
    }
  }

  private watchForSelectionChanges() {
    // When the selected global entry ID changes, we need to update the editor state.
    reaction(
      () => this.primary.selectedGlobalEntryID,
      (entryGID) => {
        // Do nothing if there's no entry selected _and_ there's no entry model in the state.
        if (!entryGID && !this.entryModel) return;

        // Do nothing if the entry model in the state matches the selected entry.
        if (
          entryGID &&
          this.entryModel?.id === entryGID.id &&
          this.entryModel?.journalId === entryGID.journal_id
        )
          return;

        // If there used to be an entry but there's not now, reset the editor state.
        if (!entryGID) {
          this.resetEditor();
          // Also make sure we unsubscribe from the old selected Entry so we don't react to changes.
          this.unsubscribeFromEntryModel?.();
          return;
        }

        // If there's a new entry selected, load it into the editor state.
        this.loadEntry(entryGID);
      },
      {
        name: "ActiveEntryViewState:watchGlobalEntryID",
        fireImmediately: true,
      },
    );
  }

  // ---------------------------
  // Actions, private and public
  // ---------------------------

  // In charge of loading the entry model onto state initially and
  // reacting to changes from the database over time.
  private loadEntry(entryGID: GlobalEntryID) {
    this.resetEditor({ loading: true });

    this.unsubscribeFromEntryModel?.();
    this.unsubscribeFromEntryModel = this.entryStore.subToEntry(
      entryGID,
      this.gotNewEntry,
    );

    this.unsubscribeFromEntryMove?.();
    this.unsubscribeFromEntryMove = this.entryStore.subToEntryMove(
      entryGID.id,
      (moves) => {
        this.setIsMoving(!!moves?.length);
      },
    );

    this.unsubscribeFromMomentCount?.();
    this.unsubscribeFromMomentCount =
      this.momentRepository.subscribeToCountByEntry(
        (count) => {
          this.setMomentCount(count);
        },
        entryGID.journal_id,
        entryGID.id,
      );
    this.unsubscribeFromTags?.();
    this.unsubscribeFromTags = this.tagRepository.subscribeToTagsByEntry(
      (tags) => {
        this.setTags(tags);
      },
      entryGID.journal_id,
      entryGID.id,
    );
    this.unsubscribeFromReactions?.();
    this.unsubscribeFromReactions =
      this.reactionStore.subscribeToReactionsByEntry(
        (reactions) => {
          this.setReactions(reactions);
        },
        entryGID.journal_id,
        entryGID.id,
      );
  }

  private gotNewEntry = (entryModel: EntryModel | null) => {
    if (!entryModel) {
      this.error = "entry-not-found";
      this.loading = false;
      this._for_test_skippedLastUpdateBecause = "null_entry_model";
      return;
    }

    // When we get a new entry model loaded onto the state, make sure
    // the entry we've loaded up is the one we're supposed to be editing
    // before setting state.
    if (
      !globalEntryIDsAreEqual(
        entryModel?.globalID,
        this.primary.selectedGlobalEntryID,
      )
    ) {
      this._for_test_skippedLastUpdateBecause = "entry_no_longer_selected";
      return;
    }

    if (entryModel.unread_marker_id) {
      this.entryStore.markEntryRead(
        entryModel?.globalID,
        entryModel.unread_marker_id,
      );
    }

    // We're subscribing to an entry here. So we may be getting an update from
    // the database triggered by a change (sync or other). In that case, we
    // need to check if the entry has been edited since the last save. If it
    // has, we'll ignore the update. If it hasn't, we'll update the editor with
    // the new blocks.
    if (this.lastEdit && entryModel.editDate <= this.lastEdit) {
      this._for_test_skippedLastUpdateBecause = "old_user_edit_date";
      return;
    }

    const blocks = this.getBlocksFromEntry(entryModel, true);
    if (blocks === "error") {
      this.setEntryLoadError("failed-loading-blocks", entryModel);
    } else {
      this.mostRecentBlocks = blocks;
      this.error = null;
      this.entryModel = entryModel;
      this.loading = false;
      const entryFeatureFlags = this.entryModel?.featureFlags;
      if (entryFeatureFlags) {
        this.allFeatureFlagsValid =
          validateEntryFeatureFlags(entryFeatureFlags);
      }
    }
  };

  getBlocksFromEntry(
    entryModel: EntryModel,
    addMissingMoments = false,
  ): Blocks | "error" {
    try {
      const { readContentsAsMarkdown } = this.featureFlags;
      let blocks = [];
      if (addMissingMoments) {
        const moments = entryModel.readOnlyMomentsFromDBAtInitialization;
        blocks = getEntryBlocksWithMissingMoments(
          entryModel,
          moments,
          readContentsAsMarkdown,
        );
      }

      blocks = getEntryBlocks(entryModel, readContentsAsMarkdown);

      return blocks;
    } catch (error) {
      Sentry.captureException(error);
      return "error";
    }
  }

  private resetEditor(opts?: { loading?: boolean }) {
    this.lastEdit = null;
    this.mostRecentBlocks = null;
    this.momentCount = 0;
    this.error = null;
    this.entryModel = null;
    this.loading = opts?.loading ? true : false;
    this.idleTimer && clearTimeout(this.idleTimer);
    this.idleTimer = null;
    this.currentlyAddingMedia = false;
    this.isMoving = false;
  }

  // Call this function any time user makes an edit in the Editor
  // If we have 2 browser tabs open with same entry and edit tab1,
  // tab1 will invoke onEditHappened. Tab2 will also invoke onEditHappened
  // due to database update causing a change in the entry model.
  // This will then cause tab1 onEditHappened to be called again, and on and on....
  // as reported in issue #1755
  // So, we unsubscribe from database edits onEditHappened.
  // This also makes sure that the last edit made by the user
  // in the Editor will be the version that's saved to the database.
  onEditHappened = () => {
    this.lastEdit = Date.now();
    if (this.entryModel) {
      const blocks = this.getBlocksFromEntry(this.entryModel);
      if (blocks !== "error") {
        this.mostRecentBlocks = blocks;
      }
    }
    this.unsubscribeFromEntryModel?.();
    this.unsubscribeFromEntryModel = null;
    this.idleTimer && clearTimeout(this.idleTimer);

    // If we've been idle for 6 seconds without any edits we'll resubscribe to the entry model
    // 6 seconds because we wait 5 seconds before syncing then 1 second after that we resubscribe.
    this.idleTimer = setTimeout(() => {
      if (this.entryModel?.globalID) {
        this.unsubscribeFromEntryModel = this.entryStore.subToEntry(
          this.entryModel.globalID,
          this.gotNewEntry,
        );
        this.lastEdit = null;
      }
    }, 6 * 1000); // 6 seconds

    return this.lastEdit;
  };

  // Call this function after debounces have settled and we're ready to
  // save the entry to the database.
  // Notice that this is a generator function, which MobX will automatically
  // turn into a "flow", allowing us to set state after async operations.
  *saveEntry(
    updatedContents: Blocks,
    globalEntryID: GlobalEntryID,
    tags?: string[],
    templateID?: string,
  ) {
    if (!this.entryModel) {
      Sentry.captureException(
        new Error("Entry model not loaded on state while trying to save"),
      );
      return;
    }

    // If the entry model in state doesn't equal the entry we're trying to save,
    // skip the update. This can sometimes occur when quickly switching entries and
    // something like replacing inner blocks triggers a change on the editor.
    if (
      this.entryModel?.globalID.id !== globalEntryID.id ||
      this.entryModel?.globalID.journal_id !== globalEntryID.journal_id
    ) {
      return;
    }

    // For shared journals we prevent entries from being saved to the database when
    // the user is not the entry creator. This is not allowed and causes a lot of errors.
    // This shouldn't happen as in this case they only get a preview of the entry
    // But keeping this here as a safe guard.
    if (
      this.entryModel.isShared &&
      this.entryModel.creatorUserId !== this.primary.user?.id
    ) {
      return;
    }

    const markdown = convertBlocksToMarkdown(updatedContents);
    const contents = convertGBBlocksToRTJNodes(updatedContents);

    const editDate = Date.now();
    this.lastEdit = editDate;

    // We pass in the edit date deliberately here instead of letting
    // the store just use Date.now() so that when we get the entry
    // back from the database, its edit date won't be newer than the
    // last one recorded on state, and the blocks won't be updated,
    // dropping the cursor position and losing editor focus.
    try {
      if (tags) {
        this.tagStore.bulkAddForEntry(tags, this.entryModel.globalID);
      }
      yield this.entryStore.updateEntryContents(
        this.entryModel,
        editDate,
        markdown,
        contents,
        templateID,
      );
      this._for_test_savedEntryBlocks = updatedContents;
    } catch (error) {
      Sentry.captureException(error);
      this.error = "failed-saving-entry";
    }
  }

  *saveTagsOnly() {
    if (!this.entryModel) {
      Sentry.captureException(
        new Error("Entry model not loaded on state while trying to save tags"),
      );
      return;
    }
    try {
      yield this.entryStore.updatedEntryTags(this.entryModel);
    } catch (err) {
      Sentry.captureException(err);
      this.error = "failed-saving-entry";
    }
  }

  *saveTemplateInfoOnly(templateID: string, newTags?: string[]) {
    if (!this.entryModel) {
      Sentry.captureException(
        new Error(
          "Entry model not loaded on state while trying to save template info",
        ),
      );
      return;
    }
    try {
      if (newTags) {
        yield this.tagStore.bulkAddForEntry(newTags, this.entryModel.globalID);
      }
      yield this.entryStore.updateEntryTemplateInfo(
        this.entryModel,
        templateID,
      );
    } catch (err) {
      Sentry.captureException(err);
      this.error = "failed-saving-entry";
    }
  }

  *toggleFavorite() {
    if (!this.entryModel) {
      Sentry.captureException(
        new Error(
          "Entry model not loaded on state while trying to toggle favourite",
        ),
      );
      return;
    }
    try {
      yield this.entryStore.toggleFavorite(this.entryModel);
    } catch (err) {
      Sentry.captureException(err);
      this.error = "failed-saving-entry";
    }
  }

  *updateActivity(activity: Activity) {
    if (!this.entryModel) {
      Sentry.captureException(
        new Error(
          "Entry model not loaded on state while trying to set activity",
        ),
      );
      return;
    }
    try {
      yield this.entryStore.updateActivity(this.entryModel, activity);
    } catch (err) {
      Sentry.captureException(err);
      this.error = "failed-saving-entry";
    }
  }

  *togglePinned() {
    if (!this.entryModel) {
      Sentry.captureException(
        new Error(
          "Entry model not loaded on state while trying to toggle pinned",
        ),
      );
      return;
    }
    try {
      yield this.entryStore.togglePinned(this.entryModel);
    } catch (err) {
      Sentry.captureException(err);
      this.error = "failed-saving-entry";
    }
  }

  setIsMoving = (isMoving: boolean) => {
    this.isMoving = isMoving;
  };

  setMomentCount = (count: number) => {
    this.momentCount = count;
  };

  setTags = (tags: string[]) => {
    this.tags = tags;
  };

  setReactions = (reactions: ReactionDBRow[]) => {
    this.reactions = reactions;
  };

  private setEntryLoadError(error: typeof this.error, entryModel: EntryModel) {
    this.error = error;
    this.entryModel = entryModel;
    this.loading = false;
  }

  // --------------
  // Computed views
  // --------------

  get selectedJournal() {
    return this.primary.selectedEntryJournal || this.primary.selectedJournal;
  }

  get selectedJournalActiveParticipants() {
    return (
      this.selectedJournal?.participants.filter((p) =>
        ["owner", "participant"].includes(p.role),
      ) || []
    );
  }

  // When we make a local change in the editor it fires the onEditHappened function
  // This unsubscribes from the database updates so that we don't get a new entry model
  // to do that we set unsubscribeFromEntryModel to null.
  // So to check if there have been changes made in the editor we can see
  // if unsubscribeFromEntryModel is null or not
  get hasMadeChangesInEditor() {
    return this.currentlyAddingMedia || this.unsubscribeFromEntryModel === null;
  }

  get selectedGlobalEntryID() {
    return this.primary.selectedGlobalEntryID;
  }

  get userCanAddMedia() {
    const attachmentsPerEntry = this.primary.attachmentsPerEntryLimit;
    const canAddMedia =
      !!attachmentsPerEntry && this.momentCount < attachmentsPerEntry.limit;
    return canAddMedia;
  }

  get counts() {
    const initialAcc = { characters: 0, words: 0 };
    function reduceBlocks(acc: typeof initialAcc, block: BlockInstance) {
      function countCharactersAndWords(
        content: string,
        acc: { characters: number; words: number },
      ) {
        acc.characters += count(content, "characters_including_spaces");
        acc.words += count(content, "words");
      }

      block.attributes?.content &&
        countCharactersAndWords(block.attributes.content, acc);

      block.attributes?.item?.content &&
        countCharactersAndWords(block.attributes.item.content, acc);

      if (block.innerBlocks) {
        block.innerBlocks.reduce(reduceBlocks, acc);
      }
      return acc;
    }
    return this.mostRecentBlocks?.reduce(reduceBlocks, initialAcc);
  }

  get linkToPrefilledEntry() {
    if (!this.entryModel || !this.mostRecentBlocks) {
      return;
    }

    // Strip all media blocks first
    const blocks = this.mostRecentBlocks.filter(
      (block) => !MediaBlocks.includes(block.name),
    );

    const id = uuid().split("-")[0];
    const rtJson = convertGBBlocksToRTJNodes(blocks);
    const rtjString = createRichTextJsonString(rtJson);
    const buffer = Utf8.toUintArray(rtjString);
    const gzipped = gzipSync(buffer);
    const base64 = toBase64(gzipped);
    const urlSafe = encodeURIComponent(base64);
    const base = window.location.origin;
    const url = `${base}/newentry?id=${id}&content=${urlSafe}`;
    return url;
  }

  get hashtags() {
    if (!this.userSettingsViewState.createTagsFromHashtags) {
      return [];
    }
    const entryContent = this.entryModel?.entryContents || [];
    return getTagsFromContent(entryContent);
  }

  get userIsJournalOwner() {
    return this.primary?.selectedJournal?.owner_id === this.primary.user?.id;
  }

  get userIsEntryCreator() {
    const creatorUserId = this.entryModel?.creatorUserId;
    const userId = this.primary.user?.id;
    return creatorUserId === userId;
  }

  get userCanEdit() {
    if (!this.primary.selectedEntryJournal) {
      return false;
    }
    if (!this.allFeatureFlagsValid) {
      return false;
    }
    if (this.primary.selectedEntryJournal.is_shared) {
      return this.userIsEntryCreator;
    }
    return true;
  }

  get userCanAddEntry() {
    const journalStore = d1Classes.journalStore;

    const journal = this.selectedJournal;
    return journalStore.userCanAddEntryToJournal(journal);
  }

  get isInSharedJournal() {
    return this.selectedJournal?.is_shared === true;
  }

  changeDateHandler = debounce((date: Date, toggleAllDay?: boolean) => {
    if (!this.entryModel) {
      return;
    }
    if (toggleAllDay !== undefined) {
      d1Classes.entryStore.toggleAllDay(this.entryModel, date);
    } else {
      d1Classes.entryStore.updateEntryDate(this.entryModel, date);
    }
  }, 500);
}
