export const Song: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 17.781V7.96403C8.5 7.54903 8.756 7.17703 9.144 7.03003L17.644 3.79203C18.299 3.54203 19 4.02603 19 4.72603V15.775"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2678 14.0073C19.2441 14.9836 19.2441 16.5665 18.2678 17.5428C17.2915 18.5191 15.7086 18.5191 14.7323 17.5428C13.756 16.5665 13.756 14.9836 14.7323 14.0073C15.7086 13.0309 17.2915 13.0309 18.2678 14.0073"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.76777 16.0073C8.74408 16.9836 8.74408 18.5665 7.76777 19.5428C6.79146 20.5191 5.20855 20.5191 4.23224 19.5428C3.25593 18.5665 3.25593 16.9836 4.23224 16.0073C5.20855 15.0309 6.79146 15.0309 7.76777 16.0073"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
