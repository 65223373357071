import { ThemeUICSSObject } from "@theme-ui/css";

export const mediaStyles: ThemeUICSSObject = {
  // Scope everything to the block list layout
  ".block-editor-block-list__layout": {
    '[data-type="dayone/image"], [data-type="dayone/gallery"], [data-type="dayone/video"], [data-type="dayone/audio"], [data-type="dayone/pdf"]':
      {
        my: 4,
      },
  },
  ".wp-block-dayone-gallery": {
    '[data-type="dayone/image"], [data-type="dayone/gallery"], [data-type="dayone/video"], [data-type="dayone/audio"], [data-type="dayone/pdf"]':
      {
        my: 0,
      },
  },
};
