import { liveQuery } from "dexie";

import { Sentry } from "@/Sentry";
import { DODexie } from "@/data/db/dexie_db";
import { JournalPresetDBRow } from "@/data/db/migrations/presets";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";

export class JournalPresetRepository {
  constructor(
    protected db: DODexie,
    private syncStateRepository: SyncStateRepository,
  ) {}

  async savePresets(presets: JournalPresetDBRow[]) {
    await this.db.journal_presets.bulkPut(presets);
  }

  subscribeToPresetCursor(callback: (cursor: string) => void) {
    const sub = liveQuery(async () => {
      return await this.syncStateRepository.getPresetsCursor();
    }).subscribe(callback, (err) => {
      Sentry.captureException(err);
    });
    return () => {
      sub.unsubscribe();
    };
  }

  async getJournalPresetById(id: string) {
    return this.db.journal_presets.get(id);
  }
}
