import { PBCTemplateAPICaller } from "@/data/api_callers/PBCTemplateAPICaller";
import { PBCTemplateDBRow } from "@/data/db/migrations/template";
import { PBCTemplateRepository } from "@/data/repositories/PBCTemplateRepository";

export class PBCTemplateController {
  constructor(
    private templateGalleryAPICaller: PBCTemplateAPICaller,
    private templateGalleryRepository: PBCTemplateRepository,
  ) {}

  async sync() {
    const categories =
      await this.templateGalleryAPICaller.fetchTemplateCategories();
    if (categories.length > 0) {
      this.templateGalleryRepository.saveTemplateCategories(categories);
    }
    const templates = await this.templateGalleryAPICaller.fetchTemplates();
    if (templates.length > 0) {
      this.templateGalleryRepository.saveTemplates(templates);
    }
  }

  async getCategoriesWithTemplates() {
    const categories = await this.templateGalleryRepository.getCategories();

    const templateIds = [];
    for (const category of categories) {
      templateIds.push(...category.templates);
    }
    const templates =
      await this.templateGalleryRepository.getTemplatesForCategories(
        templateIds,
      );

    const templatesMap = templates.reduce(
      (acc, template) => {
        acc[template.id] = template;
        return acc;
      },
      {} as Record<string, PBCTemplateDBRow>,
    );
    const categoriesWithTemplates = new Map();
    for (const category of categories) {
      categoriesWithTemplates.set(category.id, {
        ...category,
        templates: category.templates.map((id) => templatesMap[id]),
      });
    }
    return categoriesWithTemplates;
  }
}
