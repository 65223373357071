import { Snackbar } from "@wordpress/components";
import { motion, AnimatePresence } from "framer-motion";
import { observer } from "mobx-react-lite";
import { FC } from "react";

import { Z_INDEX_SNACKBAR } from "@/styles/theme";
import { viewStates } from "@/view_state/ViewStates";

export const D1SnackbarGlobal: FC = observer(() => {
  return (
    <motion.div
      sx={{
        position: "absolute",
        zIndex: Z_INDEX_SNACKBAR,
        bottom: 5,
        right: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        overflowX: "hidden",
      }}
      layout
    >
      <AnimatePresence>
        {viewStates.snackbar.snackbars.map((snackbar, index) => (
          <motion.div
            key={snackbar.message + index}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{
              type: "spring",
              stiffness: 500,
              damping: 30,
            }}
            sx={{
              position: "relative",
              "&& .components-snackbar": {
                backgroundColor: "surface_light3_dark3",
                color: "textPrimary",
                "& p": {
                  mr: 3,
                },
                "& .components-snackbar__action.components-button:hover": {
                  color: "textTertiary",
                },
              },
              p: 2,
            }}
          >
            <Snackbar
              actions={snackbar.actions}
              onRemove={() => viewStates.snackbar.dismissSnackbar(snackbar)}
            >
              {snackbar.message}
            </Snackbar>
          </motion.div>
        ))}
      </AnimatePresence>
    </motion.div>
  );
});
