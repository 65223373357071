import {
  AudioNode,
  ChecklistNode,
  CodeBlockNode,
  EmbeddableContentNode,
  EmbeddableNode,
  EmbeddedContentNode,
  HeaderNode,
  HorizontalRuleLineNode,
  ListNode,
  PdfNode,
  PhotoNode,
  PlainTextNode,
  PreviewNode,
  MarkdownNode,
  QuoteNode,
  RTJNode,
  VideoNode,
  TextNode,
  ContactNode,
  LocationNode,
  PodcastNode,
  MotionActivityNode,
  SongNode,
  WorkoutNode,
  SuggestionTypes,
  GenericMediaNode,
  StateOfMindNode,
} from "@/../types/rtj-format";

// For an RTJ node, return a function to check siblings
export const getIsNodeSiblingCheck = (node: RTJNode) => {
  if (isPlainTextNode(node)) {
    return isPlainTextNode;
  } else if (isEmbeddedPhotoNode(node)) {
    return isEmbeddedPhotoNode;
  } else if (isHeaderNode(node)) {
    return isHeaderNode;
  } else if (isCheckListNode(node)) {
    return isCheckListNode;
  } else if (isListNode(node)) {
    return (sibling: RTJNode) =>
      isListNode(sibling) && isValidListSibling(node, sibling);
  } else if (isQuoteNode(node)) {
    return isQuoteNode;
  } else if (isCodeBlockNode(node)) {
    return isCodeBlockNode;
  }
};

export const isEmbeddableContentNode = (
  node: RTJNode,
): node is EmbeddableContentNode => {
  return "embeddedObjects" in node;
};

export const isHeaderNode = (node: RTJNode): node is HeaderNode => {
  return (
    "attributes" in node &&
    node.attributes?.line?.header !== undefined &&
    node.attributes?.line?.header > 0
  );
};

export const isAnyTextNode = (node: RTJNode): node is TextNode => {
  return "text" in node;
};

export const isCodeBlockNode = (node: RTJNode): node is CodeBlockNode => {
  return "attributes" in node && node.attributes?.line?.codeBlock === true;
};

export const isQuoteNode = (node: RTJNode): node is QuoteNode => {
  return "attributes" in node && node.attributes?.line?.quote === true;
};

export const isEmbeddedPhotoNode = (
  node: RTJNode,
): node is EmbeddedContentNode<PhotoNode> => {
  return (
    isEmbeddableContentNode(node) && node.embeddedObjects.every(isPhotoNode)
  );
};

export const isEmbeddedAudioNode = (
  node: RTJNode,
): node is EmbeddedContentNode<AudioNode> => {
  return (
    isEmbeddableContentNode(node) && node.embeddedObjects.every(isAudioNode)
  );
};

export const isAudioNode = (node: EmbeddableNode): node is AudioNode => {
  return "type" in node && node.type === "audio";
};

export const isEmbeddedPdfNode = (
  node: RTJNode,
): node is EmbeddedContentNode<PdfNode> => {
  return "embeddedObjects" in node && node.embeddedObjects.every(isPdfNode);
};

export const isPdfNode = (node: EmbeddableNode): node is PdfNode => {
  return "type" in node && node.type === "pdfAttachment";
};

export const isEmbeddedPreviewNode = (
  node: RTJNode,
): node is EmbeddedContentNode<PreviewNode> => {
  return (
    isEmbeddableContentNode(node) && node.embeddedObjects.every(isPreviewNode)
  );
};

export const isPreviewNode = (node: EmbeddableNode): node is PreviewNode => {
  return "type" in node && node.type === "preview";
};

export const isEmbeddedContactNode = (
  node: RTJNode,
): node is EmbeddedContentNode<ContactNode> => {
  return (
    isEmbeddableContentNode(node) && node.embeddedObjects.every(isContactNode)
  );
};

export const isContactNode = (node: EmbeddableNode): node is ContactNode => {
  return "type" in node && node.type === "contact";
};

export const isEmbeddedLocationNode = (
  node: RTJNode,
): node is EmbeddedContentNode<LocationNode> => {
  return (
    isEmbeddableContentNode(node) && node.embeddedObjects.every(isLocationNode)
  );
};

export const isLocationNode = (node: EmbeddableNode): node is LocationNode => {
  return "type" in node && node.type === "location";
};

export const isEmbeddedPodcastNode = (
  node: RTJNode,
): node is EmbeddedContentNode<PodcastNode> => {
  return (
    isEmbeddableContentNode(node) && node.embeddedObjects.every(isPodcastNode)
  );
};

export const isPodcastNode = (node: EmbeddableNode): node is PodcastNode => {
  return "type" in node && node.type === "podcast";
};

export const isEmbeddedMotionActivityNode = (
  node: RTJNode,
): node is EmbeddedContentNode<MotionActivityNode> => {
  return (
    isEmbeddableContentNode(node) &&
    node.embeddedObjects.every(isMotionActivityNode)
  );
};

export const isMotionActivityNode = (
  node: EmbeddableNode,
): node is MotionActivityNode => {
  return "type" in node && node.type === "motionActivity";
};

export const isEmbeddedSongNode = (
  node: RTJNode,
): node is EmbeddedContentNode<SongNode> => {
  return (
    isEmbeddableContentNode(node) && node.embeddedObjects.every(isSongNode)
  );
};

export const isSongNode = (node: EmbeddableNode): node is SongNode => {
  return "type" in node && node.type === "song";
};

export const isEmbeddedWorkoutNode = (
  node: RTJNode,
): node is EmbeddedContentNode<WorkoutNode> => {
  return (
    isEmbeddableContentNode(node) && node.embeddedObjects.every(isWorkoutNode)
  );
};

export const isWorkoutNode = (node: EmbeddableNode): node is WorkoutNode => {
  return "type" in node && node.type === "workout";
};

export const isGenericMediaNode = (
  node: EmbeddableNode,
): node is GenericMediaNode => {
  return "type" in node && node.type === "genericMedia";
};

export const isEmbeddedGenericMediaNode = (
  node: RTJNode,
): node is EmbeddedContentNode<GenericMediaNode> => {
  return (
    isEmbeddableContentNode(node) &&
    node.embeddedObjects.every(isGenericMediaNode)
  );
};

export const isStateOfMindNode = (
  node: EmbeddableNode,
): node is StateOfMindNode => {
  return "type" in node && node.type === "stateOfMind";
};

export const isEmbeddedStateOfMindNode = (
  node: RTJNode,
): node is EmbeddedContentNode<StateOfMindNode> => {
  return (
    isEmbeddableContentNode(node) &&
    node.embeddedObjects.every(isStateOfMindNode)
  );
};

export const isSuggestionType = (type: SuggestionTypes) =>
  type === "workout" ||
  type === "song" ||
  type === "motionActivity" ||
  type === "podcast" ||
  type === "location" ||
  type === "contact" ||
  type === "genericMedia" ||
  type === "stateOfMind";

export const isSuggestionNode = (
  node: EmbeddableNode,
): node is
  | WorkoutNode
  | SongNode
  | MotionActivityNode
  | PodcastNode
  | LocationNode
  | ContactNode
  | GenericMediaNode
  | StateOfMindNode => {
  return "type" in node && isSuggestionType(node.type as SuggestionTypes);
};

export const isEmbeddedMarkdownNode = (
  node: RTJNode,
): node is EmbeddedContentNode<MarkdownNode> => {
  return (
    isEmbeddableContentNode(node) && node.embeddedObjects.every(isMarkdownNode)
  );
};

export const isMarkdownNode = (node: EmbeddableNode): node is MarkdownNode => {
  return "type" in node && node.type === "markdown";
};

export const isEmbeddedVideoNode = (
  node: RTJNode,
): node is EmbeddedContentNode<VideoNode> => {
  return (
    isEmbeddableContentNode(node) && node.embeddedObjects.every(isVideoNode)
  );
};

export const isVideoNode = (node: EmbeddableNode): node is VideoNode => {
  return "type" in node && node.type === "video";
};

const isVisualMediaNode = (node: EmbeddableNode): node is VideoNode => {
  return isPhotoNode(node) || isVideoNode(node);
};

export const isEmbeddedVisualMediaNode = (
  node: RTJNode,
): node is EmbeddedContentNode<VideoNode> => {
  return (
    isEmbeddableContentNode(node) &&
    node.embeddedObjects.every(isVisualMediaNode)
  );
};

export const isEmbeddedHorizontalLineRuleNode = (
  node: RTJNode,
): node is EmbeddedContentNode<HorizontalRuleLineNode> => {
  return (
    isEmbeddableContentNode(node) &&
    node.embeddedObjects.every(isHorizonalRuleLineNode)
  );
};

export const isHorizonalRuleLineNode = (
  node: EmbeddableNode,
): node is HorizontalRuleLineNode => {
  return "type" in node && node.type === "horizontalRuleLine";
};

export const isPhotoNode = (node: EmbeddableNode): node is PhotoNode => {
  return "type" in node && node.type === "photo";
};

export const isCheckListNode = (node: RTJNode): node is ChecklistNode => {
  if (
    "attributes" in node &&
    node?.attributes !== undefined &&
    "line" in node.attributes &&
    node.attributes.line !== undefined
  ) {
    const listStyle = node.attributes.line.listStyle;
    return listStyle === "checkbox";
  }

  return false;
};

const getLineAttributesFromNode = (node: RTJNode) => {
  if (
    "attributes" in node &&
    node?.attributes !== undefined &&
    "line" in node.attributes &&
    node.attributes.line !== undefined
  ) {
    return node.attributes.line;
  }

  return null;
};

const isValidListSibling = (node: ListNode, sibling: ListNode) => {
  const lineAttributes = getLineAttributesFromNode(node);
  const siblingLineAttributes = getLineAttributesFromNode(sibling);

  const siblingIndentLevel = sibling.attributes?.line?.indentLevel || 0;

  if (
    lineAttributes?.listStyle === siblingLineAttributes?.listStyle ||
    siblingIndentLevel > 1
  ) {
    return true;
  }

  return false;
};

export const isListNode = (node: RTJNode): node is ListNode => {
  const lineAttributes = getLineAttributesFromNode(node);

  if (lineAttributes) {
    return (
      lineAttributes.listStyle === "bulleted" ||
      lineAttributes.listStyle === "numbered"
    );
  }

  return false;
};

const invalidPlainTextKeys = [
  "listStyle",
  "checked",
  "quote",
  "codeBlock",
  "header",
];

export const isPlainTextNode = (node: RTJNode): node is PlainTextNode => {
  if (isEmbeddableContentNode(node)) {
    return false;
  }

  const lineAttributes = getLineAttributesFromNode(node);

  if (lineAttributes) {
    // The mac OS client does sometimes deliver the line header prop set to 0 for plain text nodes.
    if (lineAttributes?.header === 0) {
      delete lineAttributes.header;
    }

    // The checked item is only valid for checklist nodes, if it's not a check list the attribute should not be there
    if (
      lineAttributes?.checked !== undefined &&
      lineAttributes.listStyle !== "checkbox"
    ) {
      delete lineAttributes.checked;
    }

    const containsInvalidKey = invalidPlainTextKeys.some(
      (key) => key in lineAttributes,
    );
    return !containsInvalidKey;
  }

  return true;
};

// Certain embedded nodes represent moments for an entry, not all.
// This function makes it easy to filter out nodes that pertain
// to entry moments only.
export function isMomentNode(node: EmbeddableNode): boolean {
  return (
    isPhotoNode(node) ||
    isVideoNode(node) ||
    isAudioNode(node) ||
    isPdfNode(node) ||
    isContactNode(node) ||
    isPodcastNode(node) ||
    isSongNode(node) ||
    isGenericMediaNode(node) ||
    isStateOfMindNode(node)
  );
}

export function getNodeArtworkIdentifier(node: EmbeddableNode) {
  if (node.type === "song" || node.type === "podcast") {
    return node.artworkIdentifier;
  } else if (node.type === "genericMedia" || node.type === "stateOfMind") {
    return node.iconIdentifier;
  } else if (node.type === "contact") {
    return node.photoIdentifier;
  } else {
    return node.identifier;
  }
}
