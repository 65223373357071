export const IMAGE_BLOCK_ID = "dayone/image";
export const PDF_BLOCK_ID = "dayone/pdf";
export const VIDEO_BLOCK_ID = "dayone/video";
export const AUDIO_BLOCK_ID = "dayone/audio";
export const CHECKLIST_ITEM_BLOCK_ID = "dayone/checklist-item";
export const PARAGRAPH_BLOCK_ID = "core/paragraph";
export const HEADING_BLOCK_ID = "core/heading";
export const LIST_BLOCK_ID = "core/list";
export const LIST_ITEM_BLOCK_ID = "core/list-item";
export const QUOTE_BLOCK_ID = "core/quote";
export const PULL_QUOTE_BLOCK_ID = "core/pullquote";
export const CODE_BLOCK_ID = "core/code";
export const SEPARATOR_BLOCK_ID = "core/separator";
export const CHECKLIST_BLOCK_ID = CHECKLIST_ITEM_BLOCK_ID;
export const GALLERY_BLOCK_ID = "dayone/gallery";
export const HTML_BLOCK_ID = "core/html";
export const DICTATION_BLOCK_ID = "dayone/dictation";
export const PREVIEW_BLOCK_ID = "dayone/preview";
export const CONTACT_BLOCK_ID = "dayone/contact";
export const LOCATION_BLOCK_ID = "dayone/location";
export const PODCAST_BLOCK_ID = "dayone/podcast";
export const ACTIVITY_BLOCK_ID = "dayone/activity";
export const SONG_BLOCK_ID = "dayone/song";
export const WORKOUT_BLOCK_ID = "dayone/workout";
export const GENERIC_MEDIA_BLOCK_ID = "dayone/generic-media";
export const STATE_OF_MIND_BLOCK_ID = "dayone/state-of-mind";

import { BlockEditProps } from "@wordpress/blocks";

export type ContactBlockEditProps = BlockEditProps<{
  type: "contact";
  identifier: string;
  name: string;
  photoIdentifier: string;
  source: string;
  journalId: string;
  entryId: string;
}>;

export type LocationBlockEditProps = BlockEditProps<{
  type: "location";
  identifier: string;
  city?: string;
  placeName?: string;
  place?: string;
  latitude: number;
  longitude: number;
  date: string;
  source: string;
}>;

export type PodcastBlockEditProps = BlockEditProps<{
  type: "podcast";
  identifier: string;
  show: string;
  episode: string;
  artworkIdentifier: string;
  date: string;
  source: string;
  journalId: string;
  entryId: string;
}>;

export type MotionActivityBlockEditProps = BlockEditProps<{
  type: "motionActivity";
  identifier: string;
  startDate: string;
  endDate: string;
  iconIdentifier: string;
  steps: number;
  source: string;
  movementType?: string;
  movementTypeName?: string;
}>;

export type SongBlockEditProps = BlockEditProps<{
  type: "song";
  identifier: string;
  song: string;
  artist: string;
  album: string;
  artworkIdentifier: string;
  date: string;
  source: string;
  journalId: string;
  entryId: string;
}>;

export type WorkoutBlockEditProps = BlockEditProps<{
  type: "workout";
  identifier: string;
  route: [number, number][]; // [latitude, longitude][]
  workoutMetrics: {
    activeEnergyBurned: number;
    averageHeartRate: number;
  };
  activityType: string;
  displayName: string;
  startDate: string;
  endDate: string;
  distance: number; // meters
  source: string;
}>;

export type GenericMediaBlockEditProps = BlockEditProps<{
  type: "genericMedia";
  identifier: string;
  title: string;
  artist: string;
  album: string;
  iconIdentifier: string;
  date: string;
  source: string;
  journalId: string;
  entryId: string;
}>;

export type StateOfMindBlockEditProps = BlockEditProps<{
  type: "stateOfMind";
  identifier: string;
  kind: string;
  kindDisplayName: string;
  valence: number;
  valenceClassification: string;
  valenceClassificationDisplayName: string;
  associations: string[];
  associationsDisplayNames: string[];
  labels: string[];
  labelsDisplayNames: string[];
  lightColor: string;
  darkColor: string;
  iconIdentifier: string;
  source: string;
  journalId: string;
  entryId: string;
}>;

export type PreviewBlockEditProps = BlockEditProps<{
  url: string;
}>;

export type ImageBlockEditProps = BlockEditProps<{
  journalId: string;
  entryId: string;
  clientId: string;
  src: string;
  aspectRatio: number;
  galleryHeight: number;
}>;
export type VideoBlockEditProps = ImageBlockEditProps;
export type AudioBlockEditProps = BlockEditProps<{
  journalId: string;
  entryId: string;
  clientId: string;
  src: string;
}>;
export type PdfBlockEditProps = BlockEditProps<{
  journalId: string;
  entryId: string;
  clientId: string;
  src: string;
}>;
export type JournalEditAttributes = {
  journalId: string;
  entryId: string;
  clientId: string;
  src: string;
};
export type GalleryBlockEditProps = BlockEditProps<{
  ids: { journalId: string; entryId: string; clientId: string; type: string }[];
}>;
