import * as Base64Arraybuffer from "base64-arraybuffer";

import { Symmetric } from "@/crypto/DOCryptoBasics";
export const encryptMasterKey = async (masterKey: string, key: string) => {
  const binaryKey = Base64Arraybuffer.decode(key);
  const symKey = await Symmetric.Key.fromBuffer(binaryKey);
  const payload = await Symmetric.encryptToD1(masterKey, symKey);

  return payload;
};

export const getEncodedSymmetricKey = async (): Promise<
  [CryptoKey, string]
> => {
  const key = await Symmetric.Key.new();
  const uint8Array = await Symmetric.Key.toUintArray(key);
  const encodedKey = Base64Arraybuffer.encode(uint8Array);

  return [key, encodedKey];
};
