import { Button, CheckboxControl } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import {
  getFilterIdAndLabel,
  MultipleFilterOptions,
} from "@/components/Search/FilterPills";
import LineDivider from "@/components/Search/LineDivider";

type Props = {
  filterOptions: MultipleFilterOptions;
  selectedOptions: string[];
  onOptionToggle: (optionId: string) => void;
  onToggleAll?: () => void;
  noOptionsText?: string;
};

export const MultipleOptions: React.FC<Props> = ({
  filterOptions,
  selectedOptions,
  onOptionToggle,
  onToggleAll,
  noOptionsText,
}) => {
  const { __ } = useI18n();
  if (filterOptions.options.length === 0) {
    return (
      <div
        sx={{
          p: 3,
          fontSize: 1,
          textAlign: "center",
          color: "textPrimary",
          minWidth: "max-content",
        }}
      >
        {noOptionsText ?? __("No filters available.")}
      </div>
    );
  }
  const isAllSelected = filterOptions.options.length === selectedOptions.length;
  return (
    <div
      sx={{
        display: "block",
        px: 2.5,
        button: {
          py: 0,
          px: 2,
          display: "flex",
          color: "textPrimary",
          width: "100%",
          alignItems: "center",
          height: "36px",
          borderRadius: 3,
          "& .components-base-control__field": {
            mb: 0,
          },
        },
      }}
    >
      <Button
        variant="tertiary"
        onClick={() => {
          onToggleAll?.();
          analytics.tracks.recordEvent(
            isAllSelected
              ? EVENT.searchFilterRemoved
              : EVENT.searchFilterApplied,
            {
              filter_type: filterOptions.type,
              filter_action: isAllSelected ? "deselect_all" : "select_all",
            },
          );
        }}
        sx={{
          "&&&": {
            py: 0,
            "&:hover": {
              backgroundColor: "surfaceHover",
              color: "textPrimary",
            },
          },
        }}
      >
        <span>{isAllSelected ? __("Deselect All") : __("Select All")}</span>
      </Button>

      <LineDivider styles={{ mx: 0 }} />

      {filterOptions.options.map((option) => {
        const { optionId, label } = getFilterIdAndLabel(
          filterOptions.type,
          option,
          __,
        );
        const isSelected = selectedOptions.includes(optionId);

        return (
          <Button
            key={optionId}
            sx={{
              justifyContent: "flex",

              "&&&:hover": {
                backgroundColor: "surfaceHover",
                color: "textPrimary",
              },
            }}
            onClick={() => {
              if (optionId) {
                onOptionToggle(optionId);
              }
              analytics.tracks.recordEvent(
                isSelected
                  ? EVENT.searchFilterRemoved
                  : EVENT.searchFilterApplied,
                {
                  filter_type: filterOptions.type,
                },
              );
            }}
          >
            <CheckboxControl
              __nextHasNoMarginBottom
              sx={{
                pt: 0,
                mr: 2,
                span: {
                  mx: 0,
                },
              }}
              checked={isSelected}
              onChange={() => {}}
            />
            <div
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "center",
                overflow: "hidden",
                mr: 4,
              }}
            >
              <div
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: 0,
                  lineHeight: 2,
                }}
              >
                {label}
              </div>
            </div>
            <span>{option.entryCount}</span>
          </Button>
        );
      })}
    </div>
  );
};
