import { unregisterBlockType, registerBlockType } from "@wordpress/blocks";

import {
  PODCAST_BLOCK_ID,
  PodcastBlockEditProps,
} from "@/components/Editor/blocks/constants";
import { SuggestionWrapper } from "@/components/Editor/blocks/suggestions/SuggestionWrapper";
import { blockIsRegistered } from "@/components/Editor/utils/register-blocks";
import { Podcast } from "@/components/icons/suggestions/Podcast";
import { useMomentThumb } from "@/data/hooks/moments";

export const PodcastBlock: React.FC<PodcastBlockEditProps> = ({
  attributes,
  clientId,
  isSelected,
}) => {
  const { url } = useMomentThumb(
    attributes.journalId,
    attributes.entryId,
    attributes.artworkIdentifier,
  );

  return (
    <SuggestionWrapper
      imageURL={url}
      title={attributes.episode}
      body={attributes.show}
      fallBackIcon={<Podcast />}
      clientId={clientId}
      isSelected={isSelected}
    />
  );
};

export const register = () => {
  if (!blockIsRegistered(PODCAST_BLOCK_ID)) {
    // @ts-ignore - seems types are not inline with code here.
    registerBlockType(PODCAST_BLOCK_ID, {
      edit: (props: PodcastBlockEditProps) => {
        return <PodcastBlock {...props} />;
      },
      title: "Podcast",
      category: "media",
      textdomain: "default",
      description: "Day One Podcast Block",
      attributes: {
        type: {
          type: "string",
        },
        identifier: {
          type: "string",
        },
        show: {
          type: "string",
        },
        episode: {
          type: "string",
        },
        artworkIdentifier: {
          type: "string",
        },
        date: {
          type: "string",
        },
        source: {
          type: "string",
        },
        journalId: {
          type: "string",
        },
        entryId: {
          type: "string",
        },
      },
      supports: {
        lock: false,
        html: false,
        inserter: false,
      },
    });
  }
};

export const unregister = () => {
  if (blockIsRegistered(PODCAST_BLOCK_ID)) {
    return unregisterBlockType(PODCAST_BLOCK_ID);
  }
  return false;
};
