import { z } from "zod";

export const templateCategorySchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  sort_idx: z.number(),
  templates: z.array(z.string()),
});

export const templateSchema = z.object({
  id: z.string(),
  color: z.string().nullable(),
  content: z.string(),
  icon_url: z.string().nullable(),
  icon_background_image: z.string().nullable().optional(),
  name: z.string(),
});

export const templateCategoriesSchema = z.array(templateCategorySchema);
export const templatesSchema = z.array(templateSchema);

export type TemplateCategory = z.infer<typeof templateCategorySchema>;
export type Template = z.infer<typeof templateSchema>;
