import { Snackbar } from "@wordpress/components";
import { NoticeAction } from "@wordpress/components/build-types/notice/types";
import { sprintf } from "@wordpress/i18n";
import { makeAutoObservable } from "mobx";

import { KeyValueStore } from "@/data/db/KeyValueStore";
import { FailedMove } from "@/data/repositories/EntryRepository";
import { i18n } from "@/utils/i18n";
import { isDeepEqual } from "@/utils/is-equal";

export const FAILED_MOVES_KEY_STRING = "failed_moves";

type Snackbar = {
  message: string;
  actions?: NoticeAction[];
};

export class SnackbarViewState {
  snackbars: Snackbar[] = [];
  failedMoves: FailedMove[] = [];
  showFailedMovesModal = false;

  constructor(private keyValueStore: KeyValueStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    // Listen to `failed_moves` key in the KV store for changes.
    this.keyValueStore.subscribe(
      FAILED_MOVES_KEY_STRING,
      this.handleStoredMessages,
    );
  }

  dismissSnackbar = (snackbar: Snackbar) => {
    const index = this.snackbars.indexOf(snackbar);
    if (index >= 0) {
      this.snackbars.splice(index, 1);
    }
  };

  newMessage = (message: string, actions: NoticeAction[] = []) => {
    if (
      !this.snackbars.some(
        (s) => s.message === message && isDeepEqual(actions, s.actions),
      )
    ) {
      this.snackbars.push({ message, actions });
    }
  };

  newFailedMove = (failedMove: FailedMove) => {
    const failReasonIndex = this.failedMoves.findIndex(
      (f) => f.reason === failedMove.reason,
    );
    if (failReasonIndex === -1) {
      this.failedMoves.push({
        ...failedMove,
        entryCount: failedMove.entryCount,
      });
    } else {
      const oldFailedMove = this.failedMoves[failReasonIndex];
      this.failedMoves[failReasonIndex] = {
        ...oldFailedMove,
        entryCount: oldFailedMove.entryCount + 1,
      };
    }
  };

  resetFailedMoves = () => {
    this.failedMoves = [];
    this.showFailedMovesModal = false;
    this.keyValueStore.set(FAILED_MOVES_KEY_STRING, undefined);
  };

  handleStoredMessages = (failedMoves?: FailedMove[]) => {
    let failedCount = 0;
    if (failedMoves && failedMoves?.length > 0) {
      for (const failedMove of failedMoves) {
        this.newFailedMove(failedMove);
        failedCount += failedMove.entryCount;
      }
      const message = sprintf(
        i18n._n(
          "1 Entry could not be moved",
          "%d entries could not be moved",
          failedCount,
        ),
        failedCount,
      );
      this.newMessage(message, [
        {
          label: i18n.__("More details"),
          onClick: this.displayFailedMovesModal,
        },
      ]);
      this.keyValueStore.set(FAILED_MOVES_KEY_STRING, undefined);
    }
  };

  displayFailedMovesModal = () => {
    this.showFailedMovesModal = true;
  };

  get shouldShowFailedMovesModal() {
    return this.showFailedMovesModal && this.failedMoves.length > 0;
  }
}
