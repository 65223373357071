import { DODexie } from "@/data/db/dexie_db";
import { mergeUintArrays } from "@/data/utils/buffers";

export const CHUNK_SIZE = 8 * 1024 * 1024; // 8MB chunks

/**
 * This class is used as part of our downloading media process.
 * It is responsible for processing the response body stream
 * for a file download and saving it in chunks to indexedDB
 * so that we can resume the download later from where we left off
 * if the process was interupted.
 * An explanation of the process can be found here https://wp.me/pdr0wL-2EO
 */
export class PartialMediaHandler {
  momentId: string;
  journalId: string;
  isThumbnail: boolean;
  reader: ReadableStreamDefaultReader<Uint8Array> | undefined;
  private db: DODexie;

  constructor(
    momentId: string,
    journalId: string,
    isThumbnail: boolean,
    db: DODexie,
    reader?: ReadableStreamDefaultReader<Uint8Array>,
  ) {
    this.momentId = momentId;
    this.journalId = journalId;
    this.isThumbnail = isThumbnail;
    this.reader = reader;
    this.db = db;
  }

  // Include chunk size in the id field so if we ever change the chunk size,
  // and a user resumes old downloads after loading up the new version of the app,
  // old partial chunks will be ignored and downloads will pick back up from the beginning.
  get fileName() {
    return `${CHUNK_SIZE}-${this.momentId}-${this.journalId}-${this.isThumbnail ? `thumb` : `full`}`;
  }

  async write() {
    if (!this.reader) {
      throw new Error(
        "Tried to read data from provided reader, but found an falsy value instead. Are you sure you passed in a reader object?",
      );
    }
    let receivedBytes = 0;
    let chunksBuffer: Uint8Array[] = [];
    let index = 1;

    // Check existing
    const existing = await this.db.partial_media
      .where("id")
      .equals(this.fileName)
      .primaryKeys();
    if (existing.length) {
      index = existing.length + 1;
    }

    // eslint-disable-next-line no-constant-condition
    while (true) {
      try {
        const { done, value } = await this.reader.read();

        if (done) {
          break;
        }

        // Add the current chunk (value is a Uint8Array) to the buffer
        chunksBuffer.push(value);
        receivedBytes += value.length;

        // If we've collected enough to fill a chunk (8MB), save to IndexedDB
        if (receivedBytes >= CHUNK_SIZE) {
          const full = mergeUintArrays(chunksBuffer);
          const toWrite = full.slice(0, CHUNK_SIZE);
          await this.db.partial_media.put({
            id: this.fileName,
            chunk_number: index,
            data: toWrite,
          });

          // Reset the buffer and chunk size count
          chunksBuffer = [full.slice(CHUNK_SIZE)];
          receivedBytes -= CHUNK_SIZE;
          index++;
        }
      } catch (e: any) {
        return null;
      }
    }

    const partialMedia = await this.db.partial_media
      .where("id")
      .equals(this.fileName)
      .sortBy("chunk_number");
    const partialData = partialMedia.reduce((acc, index) => {
      if (index.data !== null) {
        return [...acc, index.data];
      }
      return acc;
    }, [] as Uint8Array[]);

    const fullBuffer =
      chunksBuffer.length > 0
        ? mergeUintArrays([...partialData, ...chunksBuffer])
        : mergeUintArrays(partialData);

    this.db.partial_media.where("id").equals(this.fileName).delete();

    return fullBuffer.byteLength ? fullBuffer : null;
  }

  async getExisting() {
    const existing = await this.db.partial_media.get(this.fileName);
    if (!existing || !existing.data) {
      return new ArrayBuffer(0);
    }

    return existing.data.buffer;
  }

  async getExistingSize() {
    const existing = await this.db.partial_media
      .where("id")
      .equals(this.fileName)
      .primaryKeys();
    return existing.length * CHUNK_SIZE;
  }
}
