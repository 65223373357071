import JSZip from "jszip";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import { Activity, EntryDBRow, Location } from "@/data/db/migrations/entry";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { MediaDownloadResults } from "@/data/db/migrations/moment";
import { ClientMeta, EntryMusic } from "@/data/repositories/V2API";
import { isValidMedia } from "@/data/utils/moments/media";
import { decodeRichTextJson } from "@/utils/rtj";
import { tagsAsArray } from "@/utils/tags";

export type MediaDownloadStatus =
  | "NOT_STARTED"
  | "HAVE_ALL"
  | "DOWNLOADING"
  | "CANCELLED"
  | "SOME_MISSING";

type Audios = {
  fileSize: number | null;
  orderInEntry: number;
  title?: string;
  creationDevice: string;
  audioChannels: string;
  duration: number | null;
  favorite: boolean;
  identifier: string;
  format: string;
  date: string;
  height: number;
  width: number;
  md5: string;
  sampleRate: string;
  timeZoneName: string;
};

type Videos = {
  favorite: boolean;
  fileSize: number | null;
  orderInEntry: number;
  width: number;
  type: string;
  identifier: string;
  date: string;
  height: number;
  creationDevice: string;
  duration: number | null;
  md5: string;
};

type Photos = {
  fileSize: number | null;
  orderInEntry: number;
  creationDevice: string;
  duration: number | null;
  favorite: boolean;
  type: string;
  identifier: string;
  date: string;
  height: number;
  width: number;
  md5: string;
  isSketch: boolean;
};

type PDFs = {
  favorite: boolean;
  fileSize: number | null;
  orderInEntry: number;
  width: number;
  type: string;
  identifier: string;
  height: number;
  creationDevice: string;
  duration: number | null;
  md5: string;
  pdfName: string | null;
};

type Template = {
  userModifiedDate: string;
  title: string;
  uuid: string;
  tags: string[];
};

export type EntryExportJSON = {
  uuid: string;
  isAllDay: boolean;
  starred: boolean;
  richText: string;
  isPinned: boolean;
  editingTime: number;
  creationDeviceModel: string;
  creationDate: string;
  creationOSVersion: string;
  text: string;
  creationDevice: string;
  timeZone: string;
  duration: number;
  creationDeviceType: string;
  modifiedDate: string;
  creationOSName: string;
  tags?: string[];
  location?: Location;
  weather?: ExportWeather;
  promptID?: string;
  template?: Template;
  userActivity?: {
    activityName?: Activity;
    stepCount?: number;
    ignoreStepCount?: number;
  };
  music?: EntryMusic;
  photos?: Photos[];
  videos?: Videos[];
  audios?: Audios[];
  pdfAttachments?: PDFs[];
};

type ExportJSON = {
  metadata: {
    version: string;
  };
  entries: EntryExportJSON[];
};

type ExportWeather = {
  conditionsDescription: string;
  temperatureCelsius: number;
  moonPhaseCode?: number;
  weatherCode?: number;
  weatherServiceName?: string;
  windBearing?: number;
  sunriseDate?: string;
  pressureMB?: number;
  moonPhase?: string;
  visibilityKM?: number;
  relativeHumidity?: number;
  windSpeedKPH: number;
  sunsetDate?: string;
};

type BlankMoments = {
  photos: Photos[];
  videos: Videos[];
  audios: Audios[];
  pdfs: PDFs[];
};

const getBlankMoments = () =>
  ({
    photos: [],
    videos: [],
    audios: [],
    pdfs: [],
  }) as BlankMoments;

export const formatDateForExport = (timestamp: number) => {
  return new Date(timestamp).toISOString().replace(/\.[\d]+Z/, "Z");
};

export const generateEntryJSON = async (entry: EntryDBRow) => {
  const metadata = JSON.parse(entry.client_meta) as ClientMeta;
  const rtj = decodeRichTextJson(entry.rich_text_json);
  const hasRTJContent = rtj.contents.length > 0;
  const entryJSON = {
    uuid: entry.id,
    isAllDay: !!entry.is_all_day,
    starred: !!entry.is_starred,
    richText: hasRTJContent ? entry.rich_text_json : undefined,
    isPinned: !!entry.is_pinned,
    editingTime: entry.editing_time,
    creationDeviceModel: metadata.creationDeviceModel,
    creationDate: formatDateForExport(entry.date),
    creationOSVersion: metadata.creationOSVersion,
    text: entry.body,
    creationDevice: metadata.creationDevice,
    timeZone: entry.timezone,
    duration: entry.duration,
    creationDeviceType: metadata.creationDeviceType,
    modifiedDate: formatDateForExport(entry.edit_date),
    creationOSName: metadata.creationOSName,
  } as EntryExportJSON;
  if (entry.location) {
    entryJSON.location = entry.location;
  }
  if (entry.weather) {
    const newWeather = {
      moonPhaseCode: entry.weather.moonPhaseCode,
      weatherCode: entry.weather.code,
      weatherServiceName: entry.weather.service,
      temperatureCelsius: entry.weather.tempCelsius,
      windBearing: entry.weather.windBearing,
      sunriseDate: entry.weather.sunriseDate
        ? formatDateForExport(entry.weather.sunriseDate)
        : undefined,
      conditionsDescription: entry.weather.description,
      pressureMB: entry.weather.pressureMb,
      moonPhase: entry.weather.moonPhase,
      visibilityKM: entry.weather.visibilityKm,
      relativeHumidity: entry.weather.relativeHumidity,
      windSpeedKPH: entry.weather.windSpeedKph,
      sunsetDate: entry.weather.sunsetDate
        ? formatDateForExport(entry.weather.sunsetDate)
        : undefined,
    } as ExportWeather;
    entryJSON.weather = newWeather;
  }
  const tags = await d1Classes.tagRepository.getTagsForEntry(
    entry.journal_id,
    entry.id,
  );
  if (tags) {
    entryJSON.tags = tags;
  }
  if (entry.promptID) {
    entryJSON.promptID = entry.promptID;
  }
  if (entry.templateID) {
    const template = await d1Classes.templateRepository.getById(
      entry.templateID,
    );
    if (template) {
      entryJSON.template = {
        userModifiedDate: entryJSON.modifiedDate,
        title: template.title,
        uuid: template.clientId,
        tags: tagsAsArray(template.tags),
      };
    }
  }
  if (entry.activity || entry.steps) {
    entryJSON.userActivity = {
      activityName: entry.activity,
      stepCount: entry.steps?.stepCount,
      ignoreStepCount: entry.steps?.ignore ? 1 : 0,
    };
  }
  if (entry.music) {
    entryJSON.music = entry.music;
  }
  const moments = await d1Classes.momentRepository.getForEntry(
    entry.journal_id,
    entry.id,
  );
  const sortedMoments = moments.reduce((acc, moment, index) => {
    const type = moment.type;
    if (type === "image") {
      acc.photos.push({
        fileSize: moment.metadata?.fileSize || null,
        orderInEntry: index,
        creationDevice: moment.creation_device,
        duration: moment.metadata?.duration || null,
        favorite: !!moment.favorite,
        type: moment.content_type.replace("image/", ""),
        identifier: moment.id,
        date: formatDateForExport(moment.date),
        height: moment.height,
        width: moment.width,
        md5: moment.md5_body,
        isSketch: !!moment.is_sketch,
      } as Photos);
    } else if (type === "video") {
      acc.videos.push({
        favorite: !!moment.favorite,
        fileSize: moment.metadata?.fileSize || null,
        orderInEntry: index,
        width: moment.width,
        type: moment.content_type.includes("mp4") ? "mp4" : "mov",
        identifier: moment.id,
        date: formatDateForExport(moment.date),
        height: moment.height,
        creationDevice: moment.metadata?.recordingDevice || "",
        duration: moment.metadata?.duration || null,
        md5: moment.md5_body,
      } as Videos);
    } else if (type === "audio") {
      acc.audios.push({
        fileSize: moment.metadata?.fileSize || null,
        orderInEntry: index,
        creationDevice: moment.metadata?.recordingDevice || "",
        audioChannels: moment.metadata?.audioChannels || "",
        duration: moment.metadata?.duration || null,
        favorite: !!moment.favorite,
        identifier: moment.id,
        format: moment.metadata?.format || "",
        date: formatDateForExport(moment.date),
        height: moment.height,
        width: moment.width,
        md5: moment.md5_body,
        sampleRate: moment.metadata?.sampleRate || "",
        timeZoneName: moment.metadata?.timeZoneName || "",
      } as Audios);
    } else if (type === "pdfAttachment") {
      acc.pdfs.push({
        favorite: !!moment.favorite,
        fileSize: moment.metadata?.fileSize || null,
        orderInEntry: index,
        width: moment.width,
        type: "pdf",
        identifier: moment.id,
        height: moment.height,
        creationDevice: moment.metadata?.recordingDevice || "",
        duration: moment.metadata?.duration || null,
        md5: moment.md5_body,
        pdfName: moment.metadata?.pdfName || null,
      } as PDFs);
    }
    return acc;
  }, getBlankMoments());
  if (sortedMoments.photos.length) {
    entryJSON.photos = sortedMoments.photos;
  }
  if (sortedMoments.audios.length) {
    entryJSON.audios = sortedMoments.audios;
  }
  if (sortedMoments.videos.length) {
    entryJSON.videos = sortedMoments.videos;
  }
  if (sortedMoments.pdfs.length) {
    entryJSON.pdfAttachments = sortedMoments.pdfs;
  }
  return entryJSON;
};

export const generateJournalJSON = async (journal: JournalDBRow) => {
  const entries = await d1Classes.entryRepository.getEntriesByJournalID(
    journal.id,
  );
  const journalJSON = {
    metadata: {
      version: "1.0",
    },
    entries: [],
  } as ExportJSON;
  const entryJSONArray = await Promise.all(
    entries.map(async (entry) => {
      return generateEntryJSON(entry);
    }),
  );
  journalJSON.entries = entryJSONArray;
  return journalJSON;
};

export const generateZip = async (
  journal: JournalDBRow,
  finished: () => void,
  start: () => void,
  cancelled: () => void,
  includeMedia = true,
  failedMedia: MediaDownloadResults["details"][] = [],
) => {
  const zip = new JSZip();
  const date = new Date().toISOString().split("T")[0];
  const name = `${date}-${journal.name}`;

  const supportsStreamingExport = "showSaveFilePicker" in window;
  let cancel;
  let fs: FileSystemFileHandle | null = null;
  if (supportsStreamingExport) {
    try {
      // @ts-ignore Not all browsers support this yet
      fs = await showSaveFilePicker({
        id: "dayoneexport",
        startIn: "downloads",
        suggestedName: `${name}.zip`,
        types: [
          { description: "Zip file", accept: { "application/zip": [".zip"] } },
        ],
      });
    } catch (e: any) {
      if (e.name && e.name !== "AbortError") {
        Sentry.captureException(e);
      }
      return;
    }
  }

  const journalJSON = await generateJournalJSON(journal);
  zip.file(`${journal.name}.json`, JSON.stringify(journalJSON, null, 2));

  const moments = includeMedia
    ? journalJSON.entries.reduce((acc, entry) => {
        if (entry.photos) {
          acc.photos.push(...entry.photos);
        }
        if (entry.videos) {
          acc.videos.push(...entry.videos);
        }
        if (entry.audios) {
          acc.audios.push(...entry.audios);
        }
        if (entry.pdfAttachments) {
          acc.pdfs.push(...entry.pdfAttachments);
        }
        return acc;
      }, getBlankMoments())
    : getBlankMoments();
  if (moments.audios) {
    await Promise.all(
      moments.audios.map(async (audio) => {
        const blob = await d1Classes.momentRepository.getMediaForMoment(
          audio.identifier,
          audio.md5,
          null,
          journal.id,
        );
        if (isValidMedia(blob)) {
          zip.file(`audios/${audio.md5}.m4a`, blob, {
            binary: true,
          });
        }
      }),
    );
  }
  if (moments.photos) {
    await Promise.all(
      moments.photos.map(async (photo) => {
        const blob = await d1Classes.momentRepository.getMediaForMoment(
          photo.identifier,
          photo.md5,
          null,
          journal.id,
        );
        if (isValidMedia(blob)) {
          zip.file(`photos/${photo.md5}.${photo.type}`, blob, {
            binary: true,
          });
        }
      }),
    );
  }
  if (moments.videos) {
    await Promise.all(
      moments.videos.map(async (video) => {
        const blob = await d1Classes.momentRepository.getMediaForMoment(
          video.identifier,
          video.md5,
          null,
          journal.id,
        );
        if (isValidMedia(blob)) {
          zip.file(`videos/${video.md5}.${video.type}`, blob, {
            binary: true,
          });
        }
      }),
    );
  }
  if (moments.pdfs) {
    await Promise.all(
      moments.pdfs.map(async (pdf) => {
        const blob = await d1Classes.momentRepository.getMediaForMoment(
          pdf.identifier,
          pdf.md5,
          null,
          journal.id,
        );
        if (isValidMedia(blob)) {
          zip.file(`pdfs/${pdf.md5}.pdf`, blob, {
            binary: true,
          });
        }
      }),
    );
  }
  if (failedMedia.length) {
    const grouped = failedMedia.reduce((acc, item) => {
      if (!item) {
        return acc;
      }

      const reason = item.reason;
      acc.set(reason, [...(acc.get(reason) || []), item]);
      return acc;
    }, new Map<string, MediaDownloadResults["details"][]>());

    let text = "# Failed to include media\n\n";

    grouped.forEach((items, reason) => {
      text += `## ${reason}\n\n`;
      items.forEach((item) => {
        if (!item) {
          return;
        }
        text += `ID: ${item.momentId}\n`;
        text += `- Link: https://dayone.me/journals/${item.journalId}/${item.entryId}\n`;
        text += `- Journal ID: ${item.journalId}\n`;
        text += `- Entry ID: ${item.entryId}\n`;
        if (item.bodyMd5) {
          text += `- Body MD5: ${item.bodyMd5}\n`;
        }
        if (item.envelopeMd5) {
          text += `- Envelope MD5: ${item.envelopeMd5}\n`;
        }
        text += "\n";
      });
    });
    zip.file("debug/failed-media.txt", text);
  }

  if (supportsStreamingExport && fs) {
    start();
    const stream = await fs.createWritable();
    const streamHelper = zip
      .generateInternalStream({
        type: "uint8array",
        streamFiles: true,
      })
      .on("data", (data) => {
        stream.write(data);
      })
      .on("end", () => {
        finished();
        stream.close();
      })
      .on("error", (err) => console.error(err))
      .resume();

    cancel = async () => {
      streamHelper.pause();
      await stream.close();
      // @ts-ignore Not all browsers support this
      if (fs.remove) {
        // @ts-ignore Not all browsers support this
        await fs.remove();
      }
      cancelled();
    };
  } else {
    start();
    const streamHelper = zip.generateInternalStream({ type: "blob" }).resume();
    streamHelper.accumulate().then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${name}.zip`;
      link.click();
      finished();
    });
    cancel = () => {
      streamHelper.pause();
      cancelled();
    };
  }

  return cancel;
};
