import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import {
  PBCPrompt,
  pbcPromptsSchema,
} from "@/data/api_callers/schemas/PBCPromptAPISchemas";
import { safelyDecodeAPIResponse } from "@/data/api_callers/utils/safelyDecodeAPIResponse";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";

export class PBCPromptAPICaller {
  constructor(
    private fetchWrapper: FetchWrapper,
    private syncStateRepository: SyncStateRepository,
    private sentry: Sentry,
  ) {}

  async fetchPBCPrompts(): Promise<PBCPrompt[]> {
    const storedCursor = await this.syncStateRepository.getPBCPromptCursor();
    let finished = false;
    let cursor = storedCursor;
    const prompts = [];
    while (!finished) {
      const res = await this.fetchWrapper.fetchAPI(
        "/journal-prompts" + (cursor ? `?cursor=${cursor}` : ""),
        {},
        { expectedStatusCodes: [200] },
      );

      if (!res.ok) {
        this.sentry.captureException(
          new Error(
            `Failed to fetch PBC Prompts: ${res.status} - ${res.statusText}`,
          ),
        );
        throw new Error("Failed to fetch PBC Prompts");
      }

      const response = await res.json();
      finished = response.finished;
      if (response.data.length > 0) {
        prompts.push(...response.data);
      }
      cursor = response.cursor;
    }
    await this.syncStateRepository.setPBCPromptCursor(cursor);
    return safelyDecodeAPIResponse(pbcPromptsSchema, prompts);
  }
}
