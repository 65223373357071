import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import {
  Template,
  templateCategoriesSchema,
  TemplateCategory,
  templatesSchema,
} from "@/data/api_callers/schemas/PBCTemplateAPISchemas";
import { safelyDecodeAPIResponse } from "@/data/api_callers/utils/safelyDecodeAPIResponse";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";

export class PBCTemplateAPICaller {
  constructor(
    private fetchWrapper: FetchWrapper,
    private syncStateRepository: SyncStateRepository,
    private sentry: Sentry,
  ) {}

  async fetchTemplateCategories(): Promise<TemplateCategory[]> {
    const storedCursor =
      await this.syncStateRepository.getPBCTemplateCategoryCursor();

    const res = await this.fetchWrapper.fetchAPI(
      "/v2/templates/categories" +
        (storedCursor ? `?cursor=${storedCursor}` : ""),
      {},
      { expectedStatusCodes: [200, 304] },
    );

    if (!res.ok) {
      this.sentry.captureException(
        new Error(
          `Failed to fetch PBC Template Categories: ${res.status} - ${res.statusText}`,
        ),
      );
      throw new Error("Failed to fetch PBC Template Categories");
    }

    const { data, cursor } = await res.json();

    await this.syncStateRepository.setPBCTemplateCategoryCursor(cursor);
    return safelyDecodeAPIResponse(templateCategoriesSchema, data);
  }

  async fetchTemplates(): Promise<Template[]> {
    const storedCursor = await this.syncStateRepository.getPBCTemplateCursor();
    const res = await this.fetchWrapper.fetchAPI(
      "/v2/templates" + (storedCursor ? `?cursor=${storedCursor}` : ""),
      {},
      { expectedStatusCodes: [200, 304] },
    );

    if (!res.ok) {
      this.sentry.captureException(
        new Error(
          `Failed to fetch PBC Templates: ${res.status} - ${res.statusText}`,
        ),
      );
      throw new Error("Failed to fetch PBC Templates");
    }

    const { data, cursor } = await res.json();
    await this.syncStateRepository.setPBCTemplateCursor(cursor);
    return safelyDecodeAPIResponse(templatesSchema, data);
  }
}
