import { CheckboxControl as WpCheckboxControl } from "@wordpress/components";
import { CheckboxControlProps } from "@wordpress/components/build-types/checkbox-control/types";
import { WordPressComponentProps } from "@wordpress/components/ui/context/wordpress-component";

export const CheckboxControl: React.FC<
  WordPressComponentProps<CheckboxControlProps, "input", false>
> = (props) => {
  return (
    <WpCheckboxControl
      __nextHasNoMarginBottom
      sx={{
        "& .components-base-control__field": {
          display: "flex",
          alignItems: "center",
        },
        '& input[type="checkbox"]': {
          backgroundColor: "checkboxBg",
          borderWidth: 2,
          borderColor: "checkboxBorder",
        },
      }}
      {...props}
    />
  );
};
