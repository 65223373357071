import { Notice } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import React from "react";

import { Item, ItemGroup } from "@/components/ItemGroup";
import { WPConnectDisconnectButton } from "@/components/Settings/WPConnect/WPConnectDisconnectButton";
import { WPConnection } from "@/components/Settings/WPConnect/WPConnectionTypes";
import { WPConnectViewState } from "@/view_state/WPConnect_ViewState";

export const WPConnectedBlogList: React.FC<{
  showDisconnectButton?: boolean;
  onClickBlog?: (blog: WPConnection) => void;
  viewState: WPConnectViewState;
}> = observer(({ showDisconnectButton = false, onClickBlog, viewState }) => {
  const { connectedBlogs, status, error } = viewState;
  const { __ } = useI18n();
  const emptyState =
    connectedBlogs.length === 0 ? (
      <div sx={{ p: 2 }}>{__("No blogs connected yet")}</div>
    ) : null;

  const renderBlogContent = (blog: WPConnection) => (
    <>
      <div sx={{ flex: 1 }}>
        <div sx={{ fontWeight: "bold", mb: 2 }}>
          {blog.blog_url.replace("http://", "").replace("https://", "")}
        </div>
        <div sx={{ color: "textSecondary" }}>
          Connected: {new Date(blog.connected_at).toLocaleDateString()}
        </div>
      </div>
    </>
  );

  return (
    <ItemGroup addMargin={true}>
      {emptyState}
      {status === "loading" ? (
        <div>Loading...</div>
      ) : (
        connectedBlogs.map((blog) => (
          <Item
            key={blog.blog_url}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              alignItems: "center",
              alignContent: "center",
              padding: 3,
              cursor: onClickBlog ? "pointer" : "default",
            }}
          >
            {onClickBlog ? (
              <button
                onClick={() => onClickBlog(blog)}
                sx={{
                  appearance: "none",
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                  width: "100%",
                  textAlign: "left",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  alignItems: "center",
                  alignContent: "center",
                  cursor: "pointer",
                }}
              >
                {renderBlogContent(blog)}
              </button>
            ) : (
              renderBlogContent(blog)
            )}
            {showDisconnectButton && (
              <WPConnectDisconnectButton
                blogUrl={blog.blog_url}
                disconnectBlog={viewState.disconnectBlog}
              />
            )}
          </Item>
        ))
      )}
      {error && (
        <Notice status="error" isDismissible={false}>
          {error}
        </Notice>
      )}
    </ItemGroup>
  );
});

WPConnectedBlogList.displayName = "WPConnectedBlogList";
