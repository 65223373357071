import { Button, Flex, FlexBlock, FlexItem } from "@wordpress/components";
import { TokenItem } from "@wordpress/components/build-types/form-token-field/types";
import { useI18n } from "@wordpress/react-i18n";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { D1Modal } from "@/components/D1Modal";
import { ENTER } from "@/components/Editor/utils/keycodes";
import { SelectControl } from "@/components/Form/SelectControl";
import { TagField } from "@/components/Form/TagField";
import { TextControl } from "@/components/Form/TextControl";
import { EditSection } from "@/components/Settings/EditSection";
import { SelectedTemplate } from "@/data/db/migrations/template";
import { isDeepEqual } from "@/utils/is-equal";
import { primaryViewState } from "@/view_state/ViewStates";

type Props = {
  handleClose: () => void;
  selectedTemplate: SelectedTemplate | null;
  saveTemplate: (template: SelectedTemplate) => void;
};

const MAX_FIELD_WIDTH = "335px";
const MAX_JOURNAL_NAME_LENGTH = 50;

export const fixTags = (tags: string[] | string) => {
  if (typeof tags === "string") {
    return tags.split(",");
  }
  return toJS(tags) || [];
};

export const TemplateSettingsModal: React.FC<Props> = observer(
  ({ handleClose, selectedTemplate, saveTemplate }) => {
    const { __ } = useI18n();
    const template =
      selectedTemplate?.type !== "gallery" ? selectedTemplate : null;
    const tags = fixTags(template?.tags || []);
    const [journalId, setJournalId] = useState<string>(
      template?.journalSyncID || "",
    );
    const [templateTitle, setTemplateTitle] = useState<string>(
      template?.title || "",
    );
    const [templateTags, setTemplateTags] = useState<string[]>(tags);

    const options = [
      { label: __("None"), value: "", disabled: false },
      ...primaryViewState.journals.map((j) => {
        const name = [...j.name];
        return {
          label:
            name.length > MAX_JOURNAL_NAME_LENGTH
              ? name.slice(0, MAX_JOURNAL_NAME_LENGTH).join("") + "..."
              : j.name,
          value: j.id,
          disabled: false,
        };
      }),
    ];

    function handleKeyUp(e: React.KeyboardEvent) {
      if (e.key === ENTER) {
        handleSave();
      }
    }

    const handleSave = () => {
      if (selectedTemplate && selectedTemplate?.type !== "gallery") {
        const template = {
          ...selectedTemplate,
          title: templateTitle.trim(),
          journalSyncID: journalId,
          tags: templateTags,
        };
        saveTemplate(template);
      }
      handleClose();
    };

    useEffect(() => {
      setTemplateTitle(template?.title || "");
      setJournalId(template?.journalSyncID || "");
      setTemplateTags(tags);
    }, [template?.journalSyncID, template?.title, tags.length]);

    return (
      <D1Modal
        title={__("Template settings")}
        onRequestClose={handleClose}
        sx={{
          "&& .components-select-control": {
            flexDirection: "column",
            justifyContent: "flex-start",
            "& .components-flex-item": {
              width: "100%",
            },
          },
        }}
      >
        <EditSection>
          <FlexItem sx={{ flex: 1 }}>
            <Flex sx={{ mb: 2 }}>
              <FlexBlock>
                <TextControl
                  label={__("Template Name")}
                  name="template-title"
                  id="template-title"
                  onKeyUp={handleKeyUp}
                  onChange={(value: string) => {
                    setTemplateTitle(value);
                  }}
                  value={templateTitle}
                  autoFocus
                />
              </FlexBlock>
            </Flex>
            <Flex
              sx={{
                mb: 2,
                "&& label": {
                  ml: "-0.3rem",
                },
              }}
            >
              <SelectControl
                sx={{
                  "& .components-input-control__container": {
                    width: MAX_FIELD_WIDTH,
                  },
                  "&&& .components-select-control__input": {
                    width: MAX_FIELD_WIDTH,
                  },
                }}
                label={__("Journal")}
                onChange={(id: string) => {
                  setJournalId(id);
                }}
                value={journalId}
                __nextHasNoMarginBottom={true}
                __next36pxDefaultSize={true}
                options={options}
              ></SelectControl>
            </Flex>
            <Flex>
              <TagField
                label={__("Tags")}
                tags={templateTags}
                width={MAX_FIELD_WIDTH}
                handleOnChange={(tokens: (string | TokenItem)[]) => {
                  const tags = tokens.map((tag) => {
                    if (typeof tag === "string") {
                      return tag;
                    } else {
                      return tag.value;
                    }
                  });
                  setTemplateTags(tags);
                }}
              />
            </Flex>
            <Flex sx={{ justifyContent: "flex-end", mt: 3 }}>
              <Button
                variant="secondary"
                onClick={() => {
                  handleClose();
                }}
              >
                {__("Cancel")}
              </Button>
              <Button
                variant="primary"
                disabled={
                  templateTitle.trim() === "" ||
                  (template?.title === templateTitle &&
                    template?.journalSyncID === journalId &&
                    isDeepEqual(template?.tags, templateTags))
                }
                onClick={handleSave}
              >
                {__("Save")}
              </Button>
            </Flex>
          </FlexItem>
        </EditSection>
      </D1Modal>
    );
  },
);
