import { store as blockEditorStore } from "@wordpress/block-editor";
import { DropZone } from "@wordpress/components";
import { useDispatch, useSelect } from "@wordpress/data";
import { useState } from "react";

import { TRANSFER_DATA_TYPE } from "@/components/Editor/components/DraggableMediaButton";

export const GalleryMediaDropZone: React.FC<{
  blockId: string;
  children: React.ReactNode;
}> = ({ blockId, children }) => {
  const [isActive, setIsActive] = useState(false);
  const [hoverSide, setHoverSide] = useState<"left" | "right" | null>("left");

  const { moveBlocksToPosition } = useDispatch(blockEditorStore);

  const { getBlockIndex, getBlockRootClientId } = useSelect(
    blockEditorStore,
    [],
  );

  const _rootClientId = getBlockRootClientId(blockId);
  return (
    <div
      className={`droppable-image-zone${isActive ? "-active" : ""}`}
      data-drop-block-id={blockId}
      sx={{
        position: "relative",
        // add a shadow to the side that the image is being dragged over
        "&.droppable-image-zone-active::after": {
          content: '""',
          position: "absolute",
          left: hoverSide === "left" ? "-3px" : "unset",
          right: hoverSide === "right" ? "-3px" : "unset",
          width: "5px",
          top: "1px",
          height: "calc(100% - 2px)",
          zIndex: 1,
          boxShadow: "focusInnerLg",
        },

        "& .drop-zone-content": {
          display: "flex",
        },

        "& .components-drop-zone__content-inner": {
          display: "none",
        },

        "& .components-drop-zone.is-dragging-over-element": {
          opacity: 0,
          bg: "transparent",
        },
      }}
    >
      <div className="drop-zone-content">{children}</div>

      <DropZone
        onDragOver={(e) => {
          e.preventDefault();

          // We're calculating if it's hovering over the left or right side of the image
          // @ts-ignore
          const rect = e.target.getBoundingClientRect();
          const isOverLeft = e.clientX < rect.left + rect.width / 2;

          if (isOverLeft) {
            setHoverSide("left");
          } else {
            setHoverSide("right");
          }
          return false;
        }}
        onDragEnter={() => {
          setIsActive(true);
        }}
        onDragLeave={() => {
          setIsActive(false);
        }}
        onDragEnd={() => {
          setIsActive(false);
        }}
        onDrop={(e) => {
          e.preventDefault();
          setIsActive(false);
          const data = JSON.parse(
            e.dataTransfer?.getData(TRANSFER_DATA_TYPE) ?? "{}",
          );
          if (!data?.clientId || data.clientId === blockId) {
            return;
          }
          // The following code is calculating which index the image should be moved to
          // based on which side of the image is being hovered over
          let blockIndex = getBlockIndex(blockId);
          const currentBlockIndex = getBlockIndex(data.clientId);

          if (hoverSide === "right") {
            if (blockIndex + 1 === currentBlockIndex) {
              return;
            }
            if (blockIndex < currentBlockIndex) {
              blockIndex = blockIndex + 1;
            }
          }
          if (hoverSide === "left") {
            if (blockIndex - 1 === currentBlockIndex) {
              return;
            }
            if (blockIndex > currentBlockIndex) {
              blockIndex = blockIndex - 1;
            }
          }

          moveBlocksToPosition(
            [data.clientId],
            _rootClientId,
            _rootClientId,
            blockIndex,
          );
        }}
      />
    </div>
  );
};
