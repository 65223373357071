import { unregisterBlockType, registerBlockType } from "@wordpress/blocks";

import {
  GENERIC_MEDIA_BLOCK_ID,
  GenericMediaBlockEditProps,
} from "@/components/Editor/blocks/constants";
import { SuggestionWrapper } from "@/components/Editor/blocks/suggestions/SuggestionWrapper";
import { blockIsRegistered } from "@/components/Editor/utils/register-blocks";
import { Song } from "@/components/icons/suggestions/Song";
import { useMomentThumb } from "@/data/hooks/moments";

export const GenericMediaBlock: React.FC<GenericMediaBlockEditProps> = ({
  attributes,
  clientId,
  isSelected,
}) => {
  const { url } = useMomentThumb(
    attributes.journalId,
    attributes.entryId,
    attributes.iconIdentifier,
  );
  const body = `${attributes.artist}, ${attributes.album}`;
  return (
    <SuggestionWrapper
      title={attributes.title}
      imageURL={url}
      body={body}
      fallBackIcon={<Song />}
      clientId={clientId}
      isSelected={isSelected}
    />
  );
};

export const register = () => {
  if (!blockIsRegistered(GENERIC_MEDIA_BLOCK_ID)) {
    registerBlockType(GENERIC_MEDIA_BLOCK_ID, {
      edit: (props: GenericMediaBlockEditProps) => {
        return <GenericMediaBlock {...props} />;
      },
      title: "Generic Media",
      category: "media",
      textdomain: "default",
      description: "Day One Generic Media Block",
      attributes: {
        type: {
          type: "string",
        },
        identifier: {
          type: "string",
        },
        title: {
          type: "string",
        },
        artist: {
          type: "string",
        },
        album: {
          type: "string",
        },
        iconIdentifier: {
          type: "string",
        },
        date: {
          type: "string",
        },
        journalId: {
          type: "string",
        },
        entryId: {
          type: "string",
        },
        source: {
          type: "string",
        },
      },
      supports: {
        lock: false,
        html: false,
        inserter: false,
      },
    });
  }
};

export const unregister = () => {
  if (blockIsRegistered(GENERIC_MEDIA_BLOCK_ID)) {
    return unregisterBlockType(GENERIC_MEDIA_BLOCK_ID);
  }
  return false;
};
