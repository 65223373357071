import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "wouter";

import { d1Classes } from "@/D1Classes";
import { d1MainThreadClasses } from "@/D1MainThreadClasses";
import { Sentry } from "@/Sentry";
import { getRedirectURL } from "@/data/URLFunctions";
import { UserLoginResponse } from "@/data/UserLoginService";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

type UserAuthenticationState = {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  errors: string[];
};

export type UserAuthenticationContext = UserAuthenticationState & {
  attemptLogin: (username: string, password: string, next?: string) => void;
  attemptQRLogin: (
    nonce: string,
    secret: string,
    key: CryptoKey,
  ) => Promise<number>;
  attemptAppleLogin: (token: string) => void;
  attemptCloudKitLogin: (token: string) => void;
  attemptGoogleLogin: (token: string) => void;
  attemptTestLogin: (
    email: string,
    password: string,
    key: string,
  ) => Promise<void>;
  completeLogin: (resp: UserLoginResponse, next?: string) => Promise<void>;
  removeSyncData: () => Promise<{ success: boolean; error: string }>;
  doLogout: () => Promise<void>;
  doForceLogout: () => Promise<void>;
};

const AuthContext = createContext<UserAuthenticationContext>({
  isAuthenticated: false,
  isAuthenticating: true,
  attemptLogin: () => {
    // default empty function
  },
  attemptQRLogin: () => {
    return new Promise((resolve) => resolve(0));
  },
  attemptGoogleLogin: () => {
    // default empty function
  },
  attemptTestLogin: async () => {
    // default empty function
  },
  removeSyncData: () => {
    return new Promise((resolve) =>
      resolve({
        success: true,
        error: "",
      }),
    );
  },

  completeLogin: () => {
    // default empty function
    // this is the function that will take the server response with the user in it and save everything in the right place
    return new Promise((resolve) => resolve());
  },

  attemptAppleLogin: () => {
    // default empty function
  },

  attemptCloudKitLogin: () => {
    // default empty function
  },

  doLogout: () => {
    return new Promise((resolve) => resolve());
  },
  doForceLogout: () => {
    return new Promise((resolve) => resolve());
  },
  errors: [],
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = observer(
  ({ children }) => {
    // Keep the user in memory for now
    const [authState, setAuthState] = useState<UserAuthenticationState>({
      isAuthenticated: false,
      isAuthenticating: true,
      errors: [],
    });
    const [, setLocation] = useLocation();
    const { __ } = useI18n();
    const user = primaryViewState.user;
    const userLoading = primaryViewState.userLoading;
    const forceLogout = primaryViewState.forceLogout;
    const setIsLoggingOut = primaryViewState.setIsLoggingOut;
    const userLoginService = d1MainThreadClasses.userLoginService;

    if (user) {
      Sentry.setUser({ id: user.id });
    }

    useEffect(() => {
      if (userLoading) {
        return;
      } else if (user?.id) {
        setAuthState({
          ...authState,
          isAuthenticating: false,
          isAuthenticated: true,
          errors: [],
        });
      } else {
        setAuthState({
          ...authState,
          isAuthenticating: false,
          isAuthenticated: false,
        });
      }
    }, [`${user?.id}${userLoading}`]);

    useEffect(() => {
      if (forceLogout === "yes") {
        doLogout();
      }
    }, [forceLogout]);

    const removeSyncData = async () => {
      let error = "";
      const res = await d1Classes.fetchWrapper.fetchAPI("/v3/users/syncData", {
        method: "DELETE",
      });
      if (res.status !== 200) {
        error = "Unable to remove sync data. Please try again.";
      }
      return new Promise<{ success: boolean; error: string }>((resolve) =>
        resolve({
          success: !error,
          error: error,
        }),
      );
    };

    const attemptLogin = async (
      username: string,
      password: string,
      next?: string,
    ) => {
      try {
        completeLogin(
          await userLoginService.loginWithEmail(username, password),
          next,
        );
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
        failLogin(__("Something went wrong, please try again later."));
      }
    };

    const attemptQRLogin = async (
      nonce: string,
      secret: string,
      key: CryptoKey,
    ) => {
      try {
        const res = await userLoginService.loginWithQR(nonce, secret, key);
        if (res === "ok") {
          await completeLogin(res, "/");
        } else if (res === 400 || res === 404 || res === 408) {
          return res;
        } else {
          failLogin(__("Test login failed. Please try again."));
        }
      } catch (e) {
        console.log(e);
      }
      return 0;
    };

    const failLogin = (error: string) => {
      setAuthState({
        isAuthenticated: false,
        errors: [error],
        isAuthenticating: false,
      });
    };

    const completeLogin = async (res: UserLoginResponse, next?: string) => {
      if (res == "ok") {
        const nextURLFromQuery = getRedirectURL();
        if (nextURLFromQuery) {
          setLocation(nextURLFromQuery);
        } else if (next) {
          setLocation(next);
        } else if (window.location.pathname.includes("checkout")) {
          return;
        } else {
          setLocation("/");
        }
      } else if (res.status === 409) {
        failLogin(__("Another account is using that email address."));
      } else if (res.status === 404) {
        failLogin(__("Email and password combination not found."));
      } else if (res.status === 401) {
        failLogin(
          __(
            "The password is weak or compromised, it must be reset, please use the link below.",
          ),
        );
      } else if (res.status === 472) {
        if (res.source === "apple") {
          failLogin(__("Another account is linked with that Apple account."));
        } else if (res.source === "google") {
          failLogin(__("Another account is linked with that Google account."));
        } else {
          failLogin(__("Something went wrong, please try again later."));
        }
      } else {
        failLogin(__("Something went wrong, please try again later."));
        Sentry.captureException(
          `Failed attempt to login with status code ${res.status}`,
        );
      }
    };

    const attemptAppleLogin = async (token: string) => {
      await completeLogin(await userLoginService.loginWithApple(token));
    };

    const attemptCloudKitLogin = async (token: string) => {
      await completeLogin(
        await userLoginService.logInWithExistingCloudkitUser(token),
      );
    };

    const attemptGoogleLogin = async (token: string) => {
      await completeLogin(await userLoginService.loginWithGoogle(token));
    };

    const attemptTestLogin = async (
      email: string,
      password: string,
      key: string,
    ) => {
      if (viewStates.primary.user) {
        failLogin(__("You are already logged in."));
        return;
      }
      try {
        const res = await userLoginService.loginWithEmail(email, password, key);
        if (res === "ok") {
          await completeLogin(res, "/");
        } else {
          failLogin(__("Test login failed. Please try again."));
        }
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
        failLogin(__("Something went wrong, please try again later."));
      }
    };

    const doLogout = async () => {
      const outboxItems = await d1Classes.db.outbox_items.toArray();
      if (outboxItems.length > 0) {
        await d1Classes.keyValueStore.set("pending-logout", true);
        return;
      }
      await doForceLogout();
    };

    const doForceLogout = async () => {
      await setIsLoggingOut(true);
      await userLoginService.logOut();
      window.location.replace("/login");
    };

    return (
      <AuthContext.Provider
        value={{
          ...authState,
          attemptLogin,
          attemptQRLogin,
          attemptAppleLogin,
          attemptCloudKitLogin,
          attemptGoogleLogin,
          attemptTestLogin,
          removeSyncData,
          completeLogin,
          doLogout,
          doForceLogout,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  },
);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};
