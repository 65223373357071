export const StateOfMind: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21.5V8.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="12" cy="4.5" r="2" fill="currentColor" />
      <circle cx="6" cy="8.5" r="1.5" fill="currentColor" />
      <circle cx="18" cy="8.5" r="1.5" fill="currentColor" />
      <path
        d="M12 21C12 19.1615 12.3621 17.3409 13.0657 15.6424C13.7693 13.9438 14.8005 12.4005 16.1005 11.1005"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12 21C12 19.1615 11.6379 17.3409 10.9343 15.6424C10.2307 13.9438 9.19951 12.4005 7.89949 11.1005"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12 21C12 19.6792 12.4358 18.3953 13.2399 17.3475C14.0439 16.2996 15.1713 15.5463 16.4471 15.2045"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12 21C12 19.6792 11.5642 18.3953 10.7601 17.3475C9.95607 16.2996 8.82872 15.5463 7.55292 15.2045"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="19" cy="14.5" r="1" fill="currentColor" />
      <circle cx="5" cy="14.5" r="1" fill="currentColor" />
    </svg>
  );
};
