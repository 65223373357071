import { Button, Spinner } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { D1Modal } from "@/components/D1Modal";
import { DownloadMedia } from "@/components/Export/DownloadMedia";
import { LoadingModal } from "@/components/LoadingModal";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { useExport } from "@/hooks/useExport";
import { useShowHideModal } from "@/hooks/useShowHideModal";
import { MediaDownloadStatus } from "@/utils/export";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

type Props = {
  journalId: string;
  handleClose(): void;
};

export const IncludeMedia: React.FC<Props> = ({ journalId, handleClose }) => {
  const { __ } = useI18n();
  const [mediaDownloadStatus, setMediaDownloadStatus] =
    useState<MediaDownloadStatus>("NOT_STARTED");
  const { generateZip, cancel, setCancel } = useExport();
  const [showDownloadMedia, setShowDownloadMedia] = useState(false);
  const { shouldShowModal, showModal, hideModal } = useShowHideModal();

  const journal = primaryViewState.getJournalById(journalId);

  const closeModals = () => {
    hideModal();
    setShowDownloadMedia(false);
    handleClose();
  };

  const saveExportDetails = async (
    journal: JournalDBRow,
    includeMedia: boolean,
  ) => {
    let totalMediaSize = 0;
    let downloadedMediaSize = 0;
    if (includeMedia) {
      const mediaInfo = await d1Classes.journalStore.getMediaSizeInfoByJournal(
        journal.id,
      );
      totalMediaSize = mediaInfo.totalMediaSize;
      downloadedMediaSize = mediaInfo.downloadedMediaSize;
    }
    d1Classes.importExportRepository.setExport(`journal-${journal.id}`, {
      format: "json",
      type: "journal",
      journalId: journal.id,
      includeMedia: includeMedia,
      totalMediaSize,
      downloadedMediaSize,
    });
  };

  const startExport = async (includeMedia: boolean) => {
    if (!journal) {
      return;
    }
    saveExportDetails(journal, includeMedia);
    const cancelFn = await generateZip(
      journal,
      exportFinished,
      showModal,
      cancelExport,
      includeMedia,
    );
    setCancel({ fn: cancelFn });
  };

  const checkIfAllMediaDownloaded = async () => {
    const isDownloaded =
      await d1Classes.journalStore.isAllJournalMediaDownloaded(journalId);
    if (isDownloaded) {
      setMediaDownloadStatus("HAVE_ALL");
    } else {
      setMediaDownloadStatus("SOME_MISSING");
    }
    return isDownloaded;
  };

  useEffect(() => {
    checkIfAllMediaDownloaded();
  }, []);

  const exportFinished = () => {
    closeModals();
    viewStates.snackbar.newMessage(__("Journal exported successfully"));
    d1Classes.importExportRepository.deleteExport(`journal-${journalId}`);
  };

  const cancelExport = () => {
    closeModals();
    viewStates.snackbar.newMessage(__("Export cancelled"));
    d1Classes.importExportRepository.deleteExport(`journal-${journalId}`);
  };

  const checkingMedia = mediaDownloadStatus === "NOT_STARTED";

  return (
    <>
      <D1Modal title={__("Include Media")} onRequestClose={handleClose}>
        <div sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Button
            sx={{
              "&& svg": {
                mt: 0,
              },
            }}
            disabled={checkingMedia}
            variant="primary"
            onClick={() => {
              if (mediaDownloadStatus === "SOME_MISSING") {
                setShowDownloadMedia(true);
                if (journal) {
                  saveExportDetails(journal, true);
                }
              } else {
                startExport(true);
              }
            }}
          >
            {checkingMedia && <Spinner />}
            {__("Include media")}
          </Button>
          <Button
            sx={{
              "&& svg": {
                mt: 0,
              },
            }}
            variant="primary"
            onClick={() => {
              startExport(false);
            }}
          >
            {__("Export without media")}
          </Button>
        </div>
      </D1Modal>
      {showDownloadMedia && (
        <DownloadMedia
          journalId={journalId}
          handleClose={closeModals}
          initialMediaDownloadStatus={mediaDownloadStatus}
        />
      )}
      {shouldShowModal && (
        <LoadingModal
          title={__("Exporting Journal")}
          handleClose={hideModal}
          action={cancel?.fn}
          actionLabel={__("Cancel Export")}
        />
      )}
    </>
  );
};
