import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Dropdown } from "@/components/D1Dropdown";
import { JournalFilterOptions, Loading } from "@/components/Search/FilterPills";
import { FilterSearchBox } from "@/components/Search/FilterSearchBox";
import { JournalOptions } from "@/components/Search/JournalOptions";
import LineDivider from "@/components/Search/LineDivider";
import { Pill } from "@/components/Search/Pill";
import { Book } from "@/components/Search/icons/Book";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { isDeepEqual } from "@/utils/is-equal";
import { SearchViewState } from "@/view_state/SearchViewState";
import { primaryViewState } from "@/view_state/ViewStates";

type Props = {
  searchViewState: SearchViewState;
  journalStore: typeof d1Classes.journalStore;
  journals: JournalDBRow[];
  isInModal?: boolean;
};

export const JournalFilter: React.FC<Props> = observer(
  ({ searchViewState, journalStore, journals, isInModal }) => {
    const { __ } = useI18n();
    const { indexStatus, filter, setFilter } = searchViewState;
    const { journalIds: filterJournalIds } = filter;

    const [originalJournalFilterOptions, setOriginalJournalFilterOptions] =
      useState<JournalFilterOptions[]>([]);
    const [journalFilterOptions, setJournalFilterOptions] = useState<
      JournalFilterOptions[]
    >([]);
    const [journalSearchInput, setJournalSearchInput] = useState<string | null>(
      null,
    );

    const [syncedJournals, setSyncedJournals] = useState<string[] | null>(null);

    useEffect(() => {
      const unsub =
        d1Classes.syncStateRepository.subscribeToSyncedJournals(
          setSyncedJournals,
        );

      return () => {
        unsub();
      };
    }, []);

    useEffect(() => {
      if (!syncedJournals) {
        return;
      }
      if (syncedJournals.length > 0) {
        searchViewState.search(searchViewState.searchString);
      }
      const newFilterJournalIds = filterJournalIds.filter((id) =>
        syncedJournals.includes(id),
      );
      if (!isDeepEqual(newFilterJournalIds, filterJournalIds)) {
        setFilter({ journalIds: newFilterJournalIds });
      }
    }, [syncedJournals?.length]);

    const [loading, setLoading] = useState(true);

    const toggleJournalFilter = useCallback(
      (journalId: string) => {
        const newJournalIds = filterJournalIds.includes(journalId)
          ? filterJournalIds.filter((id) => id !== journalId)
          : [...filterJournalIds, journalId];

        setFilter({ journalIds: newJournalIds });
      },
      [filterJournalIds, setFilter],
    );

    useEffect(() => {
      setLoading(true);
      const loadOptions = async () => {
        const allJournalFilterOptions = await Promise.allSettled(
          journals.map(async (journal) => {
            const stats = await journalStore.getStatsByID(journal.id);
            return {
              journalId: journal?.id,
              name: journal?.name,
              entryCount: stats?.count || 0,
              color: journal?.color,
              hasSynced: syncedJournals?.includes(journal.id),
            } as JournalFilterOptions;
          }),
        );
        const sortedJournalFilterOptions = allJournalFilterOptions
          .filter(
            (result): result is PromiseFulfilledResult<JournalFilterOptions> =>
              result.status === "fulfilled",
          )
          .map((result) => result.value)
          .sort((a, b) => {
            if (a.hasSynced === b.hasSynced) {
              return b.entryCount - a.entryCount;
            }
            return a.hasSynced ? -1 : 1;
          });

        setOriginalJournalFilterOptions(sortedJournalFilterOptions);
        setJournalFilterOptions(sortedJournalFilterOptions);
      };
      loadOptions().then(() => setLoading(false));
    }, [indexStatus, syncedJournals?.length]);

    useEffect(() => {
      if (journalSearchInput === null) {
        return;
      }
      const options = originalJournalFilterOptions.reduce(
        (acc, journalFilterOption) => {
          const name = journalFilterOption.name?.toLocaleLowerCase();
          const input = journalSearchInput.toLocaleLowerCase().trim();
          const startsWith = name?.startsWith(input);
          const includes = name?.includes(input);
          if (startsWith) {
            acc.startsWith.push(journalFilterOption);
          } else if (includes) {
            acc.includes.push(journalFilterOption);
          }
          return acc;
        },
        {
          startsWith: [] as JournalFilterOptions[],
          includes: [] as JournalFilterOptions[],
        },
      );
      setJournalFilterOptions([...options.startsWith, ...options.includes]);
    }, [journalSearchInput]);

    const filtersSelected = filterJournalIds.length;

    const selectedJournalFilterOptions = journalFilterOptions.filter(
      (journalFilterOption) =>
        filterJournalIds.includes(journalFilterOption.journalId || ""),
    );
    // 0 journalFilterOptions -> show "Journal"
    // <=2 journalFilterOptions -> truncateString(name, MAX_LENGTH)
    // > 2 journalFilterOptions -> show first journal name, +N
    let pillText = isInModal ? __("All") : __("Journal");
    if (isInModal && selectedJournalFilterOptions.length > 0) {
      const truncatedNames = selectedJournalFilterOptions.map(
        (option) => option.name || "",
      );
      if (selectedJournalFilterOptions.length > 4) {
        pillText = `${truncatedNames[0]},+${truncatedNames.length - 1}`;
      } else {
        pillText = truncatedNames.join(", ");
      }
    }

    return (
      <D1Dropdown
        focusOnMount
        popoverProps={{
          placement: "bottom-start",
          variant: "primary",
        }}
        onClose={() => {
          searchViewState.setFilterDropdownOpen(false);
        }}
        renderToggle={({ isOpen, onToggle }) => (
          <Pill
            name="journal"
            icon={isInModal ? null : <Book />}
            text={pillText}
            filterCounter={
              !isInModal && filtersSelected > 0 ? filtersSelected : undefined
            }
            isActive={filtersSelected > 0}
            aria-expanded={isOpen}
            fullWidth={isInModal}
            onClear={() => {
              setFilter({ journalIds: [] });
            }}
            onClick={() => {
              searchViewState.setFilterDropdownOpen(true);
              onToggle();
              if (!isOpen) {
                analytics.tracks.recordEvent(EVENT.buttonTap, {
                  button_identifier: "searchFilter_filterRow_journals",
                });
              }
            }}
          />
        )}
        renderContent={() => (
          <div sx={{ width: "245px", mx: -2, mb: -2 }}>
            <FilterSearchBox
              searchInput={journalSearchInput}
              setSearchInput={setJournalSearchInput}
              loading={loading}
              placeHolderText={__("Search journals...")}
              onSubmitNullifyInput={false}
            />
            <LineDivider />

            <div
              sx={{
                overflowY: "auto",
                maxHeight: "350px",
                pb: 2,
                pt: 1,
                fontSize: 0,
                lineHeight: 2,
              }}
            >
              {loading ? (
                <Loading />
              ) : (
                <JournalOptions
                  journalFilterOptions={journalFilterOptions}
                  filterJournalIds={filterJournalIds}
                  toggleJournalFilter={toggleJournalFilter}
                  hiddenJournalCount={primaryViewState?.hiddenJournalCount || 0}
                />
              )}
            </div>
          </div>
        )}
      />
    );
  },
);
