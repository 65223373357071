import {
  __experimentalDivider as Divider,
  Spinner,
} from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { Comment } from "@/components/Editor/components/comments/Comment";
import { NewComment } from "@/components/Editor/components/comments/NewComment";
import {
  AvatarDetails,
  getAvatarDetails,
  ShowAvatar,
} from "@/components/Settings/Account/Avatar/utils";
import { CommentDBRow } from "@/data/db/migrations/comment";
import { useSubscription } from "@/hooks/d1_hooks";
import { useScrollIntoView } from "@/hooks/useScrollIntoView";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

export const EntryComments = observer(() => {
  const { __ } = useI18n();
  const [avatarDetails, setAvatarDetails] = useState<AvatarDetails | null>(
    null,
  );
  const urlParams = new URLSearchParams(window.location.search);
  const commentId = urlParams.get("comment");

  useScrollIntoView(commentId);

  const [loading, setLoading] = useState(true);
  const user = primaryViewState.user;
  const globalEntryID = primaryViewState.selectedGlobalEntryID;
  if (!user || !globalEntryID) {
    return null;
  }
  const activeEntry = viewStates.activeEntry.entryModel;
  const isNewEntry = !activeEntry?.revisionID;
  const fetchComments = async () => {
    return await d1Classes.commentRepository.getCommentsForOneEntry(
      globalEntryID.journal_id,
      globalEntryID.id,
    );
  };

  useEffect(() => {
    let unsub = () => {};
    if (!isNewEntry) {
      fetchComments();
      const interval = setInterval(() => {
        fetchComments();
      }, 1000 * 30);
      unsub = () => clearInterval(interval);
    }
    getAvatarDetails(user).then((avatarDetails) =>
      setAvatarDetails(avatarDetails),
    );

    return () => {
      unsub();
    };
  }, []);

  const comments =
    useSubscription<CommentDBRow[]>((cb) => {
      d1Classes.commentRepository.subscribeToComments(
        globalEntryID.journal_id,
        globalEntryID.id,
        cb,
      );
      setLoading(false);
    }) || [];

  const userAvatar = ShowAvatar(avatarDetails);

  // This should never happen, but just in case
  if (!activeEntry) {
    return null;
  }

  return (
    <>
      <Divider
        sx={{
          my: 4,
          color: "borderPrimary",
        }}
      />
      <h3 sx={{ mb: 3 }}>{__("Comments")}</h3>
      {loading && (
        <>
          <Spinner />
          <span>{__("Loading...")}</span>
        </>
      )}
      {!loading && comments.length === 0 && (
        <span>{__("No comments yet.")}</span>
      )}
      {!loading && (
        <ul>
          {comments.map((comment) => (
            <li key={comment.id} id={comment.id}>
              <Comment
                comment={comment}
                userAvatar={userAvatar}
                active={commentId === comment.id}
                entryCreator={activeEntry.creatorUserId || ""}
              />
            </li>
          ))}
        </ul>
      )}
      <NewComment
        userAvatar={userAvatar}
        globalEntryID={globalEntryID}
        userId={user.id}
      />
    </>
  );
});

EntryComments.displayName = "Entry Comments";
