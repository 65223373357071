import { useEffect, useRef, useState } from "react";

import { Sentry } from "@/Sentry";

// Given a function that takes a callback and returns new data,
// update state any time the data changes.
export function useSubscription<T>(
  subscribe: (callback: (data: T) => void) => void,
  deps: any[] = [],
): T | null {
  const [data, setData] = useState<T | null>(null);
  useEffect(() => {
    return subscribe((data) => {
      setData(data);
    });
  }, deps);
  return data;
}

export function useStateDetectingChanges<T>(
  initialValue: T,
  areEqual: (a: T, b: T) => boolean,
) {
  const [value, setValue] = useState(initialValue);
  return [
    value,
    (newValue: T) => {
      setValue((current) => {
        if (!areEqual(current, newValue)) {
          return newValue;
        } else {
          return current;
        }
      });
    },
  ] as const;
}

export function usePromise<T>(
  pThunk: () => Promise<T>,
  dependencies: any[],
): [null | T, Error | null, boolean] {
  const [state, setState] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let cancelled = false;

    if (!loading) {
      setLoading(true);
    }

    pThunk()
      .then((result) => !cancelled && setState(result))
      .catch((error) => !cancelled && setError(error))
      .finally(() => setLoading(false));

    return () => {
      cancelled = true;
    };
  }, dependencies);

  return [state, error, loading];
}

export function useAsyncEffect(
  cb: () => Promise<void>,
  changes: any[],
  errorContext: string,
) {
  useEffect(() => {
    cb().catch((e) => {
      Sentry.captureException({
        ...e,
        message: `[usePromise]: ${errorContext}`,
      });
    });
  }, changes);
}

// Use this when you have a mobx state class that should only live for the lifetime of a component.
export function useClassInstance<T>(constructorFn: () => T): T {
  const ref = useRef<T>();
  if (!ref.current) {
    ref.current = constructorFn();
  }
  return ref.current;
}
