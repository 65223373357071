import { ThemeUICSSObject } from "@theme-ui/css";
import { Dropdown } from "@wordpress/components";
import { DropdownProps } from "@wordpress/components/build-types/dropdown/types";
import { useEffect, useRef, useState } from "react";

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  sx?: ThemeUICSSObject;
};

export const D1Dropdown = (props: Props & DropdownProps) => {
  const { isOpen, sx, renderContent, renderToggle, onClose, ...dropdownProps } =
    props;
  const [dropDownOpen, setDropDownOpen] = useState(isOpen || false);
  const toggleRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleDropDown = () => {
    if (dropDownOpen) {
      onClose?.();
    }
    setDropDownOpen(!dropDownOpen);
  };

  const handleClose = () => {
    onClose?.();
    setDropDownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        toggleRef.current &&
        contentRef.current &&
        !toggleRef.current.contains(event.target as Node) &&
        !contentRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleRef, contentRef]);

  return (
    <Dropdown
      {...dropdownProps}
      sx={sx}
      open={dropDownOpen}
      onClose={handleClose}
      renderToggle={() => (
        <div ref={toggleRef}>
          {renderToggle({
            isOpen: dropDownOpen,
            onToggle: toggleDropDown,
            onClose: handleClose,
          })}
        </div>
      )}
      renderContent={() => (
        <div ref={contentRef}>
          {renderContent({
            isOpen: dropDownOpen,
            onToggle: toggleDropDown,
            onClose: handleClose,
          })}
        </div>
      )}
    />
  );
};
