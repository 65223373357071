import { MomentModel } from "@/data/models/MomentModel";

const galleryBorders = 6;
export const MAX_EDITOR_WIDTH = 620 - galleryBorders;

export interface MomentIds {
  journalId: string;
  entryId: string;
  clientId: string;
  type: string;
}

export interface MomentIdsWithModel extends MomentIds {
  moment: MomentModel | null;
}

export interface MomentIdsWithAspect extends MomentIds {
  aspectRatio: number;
}

export interface MomentIdsWithGalleryData extends MomentIdsWithAspect {
  height: number;
}

export const MAX_ROW_HEIGHT = 450;

export const getMediaWidth = (aspectRatio: number, height: number) => {
  return Math.floor(height * aspectRatio);
};

export const getMomentsWithGalleryData = (
  moments: MomentIdsWithModel[] = [],
  editorWidth = MAX_EDITOR_WIDTH,
) => {
  if (editorWidth < MAX_EDITOR_WIDTH) {
    editorWidth -= galleryBorders;
  }
  const maxRowHeight = Math.min(MAX_ROW_HEIGHT, editorWidth * 1.05);
  const rows: MomentIdsWithGalleryData[][] = [];

  let row: MomentIdsWithGalleryData[] = [];
  let sumOfAspectRatios = 0;
  let rowHeight = 0;

  const reset = () => {
    row = [];
    sumOfAspectRatios = 0;
    rowHeight = 0;
  };

  moments.forEach(({ moment, journalId, entryId, clientId, type }) => {
    const aspectRatio =
      // We need to remove 1px from the width due to the gallery items gap
      moment?.width && moment.height ? (moment.width - 1) / moment.height : 1;

    sumOfAspectRatios += aspectRatio;
    rowHeight = Math.ceil(editorWidth / sumOfAspectRatios);

    row.push({
      journalId,
      entryId,
      clientId,
      type,
      aspectRatio,
      height: rowHeight,
    });

    if (rowHeight <= maxRowHeight) {
      rows.push(row);
      reset();
    }
  });

  if (row.length > 0) {
    rows.push(row);
    reset();
  }

  const modifiedRows = rows
    .filter((row) => row.length)
    .map((row) => {
      const height = row[row.length - 1].height;

      return row.map((moment) => ({
        ...moment,
        height,
      }));
    });

  return modifiedRows;
};
