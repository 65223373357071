import { fullscreen, info } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";
import { useState } from "react";

import { D1Dropdown } from "@/components/D1Dropdown";
import { DraggableMediaButton } from "@/components/Editor/components/DraggableMediaButton";
import { MissingMediaInfo } from "@/components/Editor/components/FailedMedia";
import { FullscreenButton } from "@/components/SyncableImage/FullscreenButton";
import { ImageErrorStates } from "@/components/SyncableImage/utils";
import { MomentModel } from "@/data/models/MomentModel";
import { Z_INDEX_GALLERY_MEDIA_BUTTONS } from "@/styles/theme";

export const TopRightButtonWrapper = ({
  blockClientId,
  moment,
  setIsFullscreen,
  imageErrorState,
  showDragButton,
}: {
  blockClientId: string;
  moment?: MomentModel;
  setIsFullscreen?: (isFullscreen: boolean) => void;
  imageErrorState?: ImageErrorStates;
  showDragButton?: boolean;
}) => {
  const [isDragging, setIsDragging] = useState(false);
  return (
    <div
      sx={{
        position: "absolute",
        top: "16px",
        right: "16px",
        zIndex: Z_INDEX_GALLERY_MEDIA_BUTTONS,
      }}
    >
      {(showDragButton || isDragging) && (
        <DraggableMediaButton
          clientId={blockClientId}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={() => setIsDragging(false)}
          addRightMargin={!!setIsFullscreen}
        />
      )}
      {setIsFullscreen && moment && imageErrorState && (
        <TopRightButton
          moment={moment}
          setIsFullscreen={setIsFullscreen}
          imageErrorState={imageErrorState}
        />
      )}
    </div>
  );
};

export const TopRightButton = ({
  imageErrorState,
  moment,
  setIsFullscreen,
}: {
  imageErrorState: ImageErrorStates;
  setIsFullscreen: (isFullscreen: boolean) => void;
  moment: MomentModel | null;
}) => {
  const { __ } = useI18n();
  if (imageErrorState === "NO_ERROR") {
    return (
      <FullscreenButton
        icon={fullscreen}
        onClick={() => {
          setIsFullscreen(true);
        }}
        label={__("View full screen")}
      />
    );
  }

  if (imageErrorState === "LOW_RES") {
    return (
      <D1Dropdown
        renderToggle={({ isOpen, onToggle }) => (
          <FullscreenButton
            icon={info}
            onClick={onToggle}
            aria-expanded={isOpen}
          />
        )}
        popoverProps={{ placement: "bottom-end" }}
        renderContent={() => (
          <div
            sx={{
              fontSize: 0,
              lineHeight: "body",
              width: "340px",
              px: 3,
              pt: 2,
              color: "textSecondary",
            }}
          >
            <strong
              sx={{
                mb: 2,
                display: "block",
              }}
            >
              Low resolution media.
            </strong>
            <MissingMediaInfo moment={moment} />
          </div>
        )}
      />
    );
  }

  return null;
};
