import { observer } from "mobx-react-lite";

import { EntryTags } from "@/components/Editor/components/EntryTags";
import { EntryComments } from "@/components/Editor/components/comments/EntryComments";
import { Reactions } from "@/components/SharedJournals/Reactions";
import { Reactor } from "@/components/SharedJournals/Reactor";
import { viewStates } from "@/view_state/ViewStates";

export const AdditionalEntryInfo: React.FC<{ hideEntryTags?: boolean }> =
  observer(({ hideEntryTags = false }) => {
    const globalEntryID = viewStates.primary.selectedGlobalEntryID;
    const entryJournal = viewStates.primary.getJournalById(
      globalEntryID?.journal_id,
    );

    const reactions = viewStates.activeEntry.reactions;
    const entry = viewStates.activeEntry.entryModel;

    const showComments =
      !entryJournal?.comments_disabled &&
      !!entry?.revisionID &&
      !viewStates.activeEntry.isMoving;

    return (
      <div
        id="additional-entry-info"
        sx={{
          color: "textPrimary",
        }}
      >
        {!hideEntryTags && <EntryTags />}
        {entryJournal?.is_shared && (
          <div
            sx={{
              my: 3,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Reactions
              reactions={reactions}
              journalColor={entryJournal?.color}
              journalId={entryJournal?.id || ""}
            />
            <Reactor journalColor={entryJournal?.color} />
          </div>
        )}
        {showComments && <EntryComments />}
      </div>
    );
  });
