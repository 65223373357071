import * as Sentry from "@sentry/react";
import { liveQuery } from "dexie";

import { d1Classes } from "@/D1Classes";
import { DODexie, EntryCounts } from "@/data/db/dexie_db";
import { EntryMoveSendable } from "@/data/models/OutboxTypes";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";
import { syncStates } from "@/worker/SyncWorkerTypes";

export type JournalStat = {
  journal_id: string;
  entry_count: number;
  image_count: number;
  video_count: number;
  audio_count: number;
};

export class JournalStatsRepository {
  isSynchronizing: boolean;

  constructor(
    protected db: DODexie,
    private syncStateRepository: SyncStateRepository,
  ) {
    this.isSynchronizing = false;
  }

  async getServerSyncStats() {
    return this.getJournalStats();
  }

  private async getJournalStats() {
    await this.syncStateRepository.setStatSyncStatus(syncStates.DOWNLOADING);
    const res = await d1Classes.fetchWrapper.fetchAPI(
      "/v6/sync/journals/stats",
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (res.status === 200 || res.status === 304) {
      const stats = (await res.json()) as JournalStat[];

      await this.syncStateRepository.setStatSyncStatus(syncStates.IDLE);
      return stats;
    }
    await this.syncStateRepository.setStatSyncStatus(syncStates.ERROR);
    return null;
  }

  async updateAllStats() {
    let allStats: EntryCounts[] = [];

    const pendingMoves = (await this.db.outbox_items
      .filter((obj) => obj.action === "MOVE")
      .toArray()) as unknown as EntryMoveSendable[];

    // Avoid updating stats if there are pending moves because we
    // already did so locally. Once the Move is completed/reverted,
    // we will update stats again to make sure they are synced with the server.
    if (pendingMoves.length > 0) {
      return;
    }

    const allJournalStats = await this.getJournalStats();
    if (allJournalStats) {
      allStats = allJournalStats.map((stats) => {
        const stat = {
          journal_id: stats.journal_id + "",
          count: stats.entry_count,
          photos: stats.image_count,
          videos: stats.video_count,
          audio: stats.audio_count,
        };
        return stat;
      });
    }

    // Sync journal stats
    await this.db.transaction("rw", this.db.entry_counts_cache, async () => {
      await this.db.entry_counts_cache.clear();
      await this.db.entry_counts_cache.bulkAdd(allStats);
    });
  }

  async modifyCachedCount(
    journalId: string,
    counts: { photos: number; videos: number; audio: number; count?: number },
    op: "+" | "-" | "=" = "+",
  ) {
    await this.db.transaction("rw", this.db.entry_counts_cache, async () => {
      // Try adding an initial count of 0 if it doesn't exist
      try {
        await this.db.entry_counts_cache.add({
          journal_id: journalId,
          count: 0,
          photos: 0,
          videos: 0,
          audio: 0,
        });
      } catch (e) {
        /* Do nothing, a record already exists */
      }
      await this.db.entry_counts_cache
        .where("journal_id")
        .equals(journalId)
        .modify((x) => {
          if (op === "+") {
            x.count = counts.count ? x.count + counts.count : x.count;
            x.photos = x.photos + counts.photos;
            x.videos = x.videos + counts.videos;
            x.audio = x.audio + counts.audio;
          } else if (op === "-") {
            x.count = counts.count
              ? Math.max(x.count - counts.count, 0)
              : x.count;
            x.photos = x.photos - counts.photos;
            x.videos = x.videos - counts.videos;
            x.audio = x.audio - counts.audio;
          } else if (op === "=") {
            x.count = counts.count || x.count;
            x.photos = counts.photos;
            x.videos = counts.videos;
            x.audio = counts.audio;
          }
        });
    });
  }

  async getStatsByJounalId(journalId: string) {
    return this.db.entry_counts_cache.get(journalId);
  }

  async getAllStats() {
    return await this.db.entry_counts_cache.toArray();
  }

  async removeCountCacheForJournals(journalIds: string[]) {
    await this.db.entry_counts_cache.bulkDelete(journalIds);
  }

  subscribeToCounts(callback: (counts: Record<string, number>) => void) {
    const stream = liveQuery(async () => {
      const counts = await (
        await this.db.entry_counts_cache.toArray()
      ).map((x) => [x.journal_id, x.count]);
      return Object.fromEntries(counts);
    }).subscribe(
      (counts) => {
        callback(counts);
      },
      (err) => {
        Sentry.captureException(err);
      },
    );
    return () => {
      stream.unsubscribe();
    };
  }
}
