import { d1Classes } from "@/D1Classes";
import { KeyValueStore } from "@/data/db/KeyValueStore";
import { EntryRepository } from "@/data/repositories/EntryRepository";
import { JournalRepository } from "@/data/repositories/JournalRepository";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";
import {
  JournalInfo,
  SyncTelemetry,
} from "@/data/repositories/SyncTelemetryAPI";
import { UserRepository } from "@/data/repositories/UserRepository";
import { VaultRepository } from "@/data/repositories/VaultRepository";
import { JournalStore } from "@/data/stores/JournalStore";
import { UserKeysStore } from "@/data/stores/UserKeysStore";

const KEY = "sync_telemetry_last_date_checked";
const MIN_DAYS = 7;
const DAYS_DIVISOR = 1000 * 60 * 60 * 24;
const MINUTES_DIVISOR = 1000 * 60;

/**
 * This repository is in charge of checking and collecting SyncTelemetry data to send to the server.
 */
export class SyncTelemetryRepository {
  constructor(
    private journalRepo: JournalRepository,
    private syncStateRepository: SyncStateRepository,
    private entryRepository: EntryRepository,
    private userRepository: UserRepository,
    private userKeyStore: UserKeysStore,
    private keyValueStore: KeyValueStore,
    private vaultRepository: VaultRepository,
    private journalStore: JournalStore,
  ) {}

  async checkSyncTelemetry(): Promise<void> {
    const activeUser = await this.userRepository.getActiveUser();
    if (activeUser == null) {
      // We only run this if a user is logged in.
      return;
    }

    const existingStoredDate = await this.keyValueStore.get<string>(KEY);
    if (existingStoredDate == null) {
      // This means first time we got here.
      const now = new Date();
      this.keyValueStore.set<string>(KEY, now.toISOString());
    } else {
      const parsedExistingDate = new Date(existingStoredDate);
      const daysPassed = this.calculateDaysPassed(parsedExistingDate);
      if (daysPassed >= MIN_DAYS) {
        const telemetry = await this.loadData();
        const res = await d1Classes.fetchWrapper.postJson(
          "/users/devices/telemetry",
          telemetry,
        );
        if (res.status === 200) {
          const now = new Date();
          this.keyValueStore.set<string>(KEY, now.toISOString());
        }
      }
    }
  }

  async loadData(): Promise<SyncTelemetry> {
    const journalCursor = await this.syncStateRepository.getJournalCursor();
    const activeJournals = await this.journalRepo.getActiveJournals();

    const journalData = await Promise.all<JournalInfo>(
      activeJournals.map(async (element) => {
        const entryCount = await this.entryRepository.getEntryCountByJournal(
          element.id,
        );
        const encryptionKeyPresent =
          (await this.vaultRepository.getVaultByJournalId(element.id)) != null;
        const momentAndMediaCounts =
          await this.journalStore.getAllJournalMomentsAndMediaDownloadedCount(
            element.id,
          );
        return {
          journal_id: element.id,
          journal_cursor: journalCursor,
          journal_entry_count: entryCount,
          journal_is_encrypted: element.e2e ? true : false,
          journal_encryption_key_present: encryptionKeyPresent,
          journal_number_of_attachments_available:
            momentAndMediaCounts.mediaCount,
          journal_number_of_attachments_missing:
            momentAndMediaCounts.missingMediaCount,
        };
      }),
    );

    const masterKey = await this.userKeyStore.getMasterKeyString();
    return {
      has_master_key: masterKey != undefined,
      unified_feed_cursor: journalCursor,
      journals: journalData,
    };
  }

  /**
   * Calculate the days that have passed between an existing start date and now.
   *
   * @param existingDate the existing Date we are using to compare against.
   * @param convertDaysToMinutes test parameter so we check if minutes have passed instead of days.
   * @returns the number of days that has passed between an exisiting date and now.
   */
  private calculateDaysPassed(
    existingDate: Date,
    convertDaysToMinutes = false,
  ) {
    const now = new Date();
    if (now < existingDate) {
      // now must be after existing date.
      return 0;
    }
    const timeDiff = now.getTime() - existingDate.getTime();
    const days = Math.floor(
      timeDiff / (convertDaysToMinutes ? MINUTES_DIVISOR : DAYS_DIVISOR),
    );
    return days;
  }
}
