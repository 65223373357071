import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { D1Modal } from "@/components/D1Modal";
import { JournalSyncStats } from "@/components/Settings/Advanced/SyncDetails/JournalSyncStats";
import { OutboxItems } from "@/components/Settings/Advanced/SyncDetails/OutboxItems";
import { syncViewState } from "@/view_state/ViewStates";

type Props = {
  onClose: () => void;
};

export const SyncDetailsModal: React.FC<Props> = observer(({ onClose }) => {
  const { __ } = useI18n();
  return (
    <D1Modal onRequestClose={onClose} title={__("Sync Details")}>
      <div
        sx={{
          mb: 2,
          pb: 2,
          borderBottom: "1px solid",
          borderColor: "borderPrimary",
          fontSize: 1,
          width: ["100%", "100%", "600px"],
        }}
      >
        {__("Sync status")}:{" "}
        <span sx={{ textTransform: "capitalize" }}>
          {syncViewState.isOnline
            ? syncViewState.state.toLocaleLowerCase()
            : __("Offline")}
        </span>
      </div>

      <OutboxItems onClose={onClose} />
      <JournalSyncStats syncState={syncViewState} />
    </D1Modal>
  );
});
