import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useRef, useState } from "react";
import xss from "xss";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { Button } from "@/components/Settings/Button";
import { SendComment } from "@/components/icons/SendComment";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { primaryViewState } from "@/view_state/ViewStates";

type Props = {
  userAvatar: JSX.Element;
  globalEntryID: GlobalEntryID;
  userId: string;
};

export const NewComment: React.FC<Props> = ({
  userAvatar,
  globalEntryID,
  userId,
}) => {
  const { __ } = useI18n();
  const [disableSave, setDisableSave] = useState(true);
  const commentRef = useRef<HTMLDivElement>(null);
  const entryJournal = primaryViewState.getJournalById(
    globalEntryID.journal_id,
  );

  const getPlaceHolder = () => {
    return commentRef.current?.getAttribute("data-placeholder") || "";
  };

  const handleCommentSubmit = async () => {
    if (!commentRef.current) {
      return;
    }
    const comment = commentRef.current.innerText || "";
    if (comment.trim().length === 0 || comment.trim() === getPlaceHolder()) {
      return;
    }

    // Temporary mitigation for XSS. Eventually we'll support rich text, for now we'll filter out all HTML.
    const sanitized = xss(comment, {
      whiteList: {},
    });

    await d1Classes.commentRepository.createLocalComment(
      globalEntryID.journal_id,
      globalEntryID.id,
      sanitized.trimEnd(),
      userId,
    );

    analytics.tracks.recordEvent(EVENT.entryCommentAdded, {
      shared_journal: entryJournal?.is_shared || false,
      entry_id: globalEntryID.id,
    });
    commentRef.current.innerHTML = getPlaceHolder();
    commentRef.current.style.color = "var(--theme-ui-colors-textSecondary)";
  };

  const setFocus = () => {
    if (!commentRef.current) {
      return;
    }
    if (commentRef.current.innerHTML.trim() === getPlaceHolder()) {
      commentRef.current.style.color = "var(--theme-ui-colors-textPrimary)";
      commentRef.current.innerHTML = "";
    }
  };

  const setBlur = () => {
    if (!commentRef.current) {
      return;
    }
    if (
      commentRef.current.innerHTML.trim() === "" ||
      commentRef.current.innerText.trim() === ""
    ) {
      commentRef.current.style.color = "var(--theme-ui-colors-textSecondary)";
      commentRef.current.innerHTML = getPlaceHolder();
    }
  };

  // Temporary fix. Eventually we'll support rich text, for now we'll only allow plain text on paste.
  const pasteAsPlainText = (event: ClipboardEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!commentRef.current) {
      return;
    }

    const paste = event.clipboardData?.getData("text/plain");
    if (!paste) {
      return;
    }
    const selection = window.getSelection();
    if (!selection?.rangeCount) {
      return;
    }
    selection.deleteFromDocument();
    selection.getRangeAt(0).insertNode(document.createTextNode(paste));
    selection.collapseToEnd();
    handleChange();
  };

  const handleChange = () => {
    if (
      commentRef.current?.innerHTML.trim() === getPlaceHolder() ||
      commentRef.current?.innerText.trim() === ""
    ) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  };

  useEffect(() => {
    if (!commentRef.current) {
      return;
    }

    if (commentRef.current.innerHTML.trim() === "") {
      commentRef.current.innerHTML = getPlaceHolder();
    }

    commentRef.current.addEventListener("paste", pasteAsPlainText);
    commentRef.current.addEventListener("keyup", handleChange);
    commentRef.current.addEventListener("focus", setFocus);
    commentRef.current.addEventListener("blur", setBlur);

    return () => {
      commentRef.current?.removeEventListener("focus", setFocus);
      commentRef.current?.removeEventListener("blur", setBlur);
      commentRef.current?.removeEventListener("paste", pasteAsPlainText);
      commentRef.current?.removeEventListener("keyup", handleChange);
    };
  }, []);

  return (
    <div sx={{ display: "flex", mt: 4, pl: 2 }}>
      <div
        sx={{
          flexShrink: 0,
          "& img, & div": {
            width: "32px",
            height: "32px",
            lineHeight: "32px",
            fontSize: 2,
            flexShrink: 0,
          },
        }}
      >
        {userAvatar}
      </div>
      <div
        sx={{
          ml: 3,
          mb: 4,
          flexGrow: 1,
          display: "flex",
          alignContent: "flex-start",
        }}
      >
        <div
          id="new-comment"
          ref={commentRef}
          aria-label={__("Write a comment")}
          data-placeholder={__("Write a comment")}
          contentEditable="true"
          sx={{
            color: "textSecondary",
            fontSize: 1,
            flexGrow: 1,
            borderRadius: "sm",
            backgroundColor: "surface_light2_dark2",
            border: "1px solid",
            borderColor: "borderPrimary",
            p: 2,
          }}
        />
        <Button
          aria-label={__("Send Comment")}
          disabled={disableSave}
          sx={{
            mt: "4px",
            mx: 2,
            "&&.components-button:disabled": {
              opacity: 0.4,
            },
            "& svg": { color: entryJournal?.color || "textTertiary" },
          }}
          onClick={() => handleCommentSubmit()}
        >
          <SendComment />
        </Button>
      </div>
    </div>
  );
};
