import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { ToggleSetting } from "@/components/Settings/ToggleSetting";
import { viewStates } from "@/view_state/ViewStates";

export const AutoTitleFirstLine: React.FC = observer(() => {
  const { __ } = useI18n();
  const autoTitleFirstLine =
    viewStates.userSettings.settings?.auto_title_first_line ?? false;

  return (
    <ToggleSetting
      label={__("Auto title first line")}
      checked={autoTitleFirstLine}
      onChange={() => {
        viewStates.userSettings.saveSettings({
          auto_title_first_line: !autoTitleFirstLine,
        });
      }}
    />
  );
});

AutoTitleFirstLine.displayName = "AutoTitleFirstLine";
