export const Workout: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9779 7.38452L16.6151 10.0217L10.0214 16.6154L7.38421 13.9782L13.9779 7.38452Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.42856 11.3403C3.79253 10.9763 4.38264 10.9763 4.74661 11.3403L12.6594 19.2531C13.0234 19.6171 13.0234 20.2072 12.6594 20.5712L11.9996 21.231C11.6356 21.595 11.0455 21.595 10.6815 21.231L2.76871 13.3182C2.40474 12.9542 2.40474 12.3641 2.76871 12.0001L3.42856 11.3403Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.42873 15.2971C3.7927 14.9331 4.38281 14.9331 4.74678 15.2971L8.70382 19.2541C9.06779 19.6181 9.06779 20.2082 8.70382 20.5722L8.04398 21.232C7.68001 21.596 7.0899 21.596 6.72593 21.232L2.76888 17.275C2.40491 16.911 2.40491 16.3209 2.76888 15.9569L3.42873 15.2971Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5712 12.6603C20.2072 13.0243 19.6171 13.0243 19.2531 12.6603L11.3403 4.74748C10.9763 4.38351 10.9763 3.7934 11.3403 3.42943L12.0001 2.76958C12.3641 2.40562 12.9542 2.40562 13.3182 2.76958L21.231 10.6824C21.595 11.0464 21.595 11.6365 21.231 12.0005L20.5712 12.6603Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5715 8.70292C20.2075 9.06688 19.6174 9.06688 19.2534 8.70291L15.2964 4.74587C14.9324 4.3819 14.9324 3.79179 15.2964 3.42782L15.9562 2.76798C16.3202 2.40401 16.9103 2.40401 17.2743 2.76798L21.2313 6.72502C21.5953 7.08899 21.5953 7.6791 21.2313 8.04307L20.5715 8.70292Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
