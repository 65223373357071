import { z } from "zod";

export const journalPresetSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  long_description: z.string(),
  color: z.string(),
  icon_url: z.string().nullable(),
  prompt_ids: z.array(z.string()),
  template_ids: z.array(z.string()),
  created_at: z.string(),
  deleted_at: z.string().nullable(),
  sort_idx: z.number(),
});

export const journalPresetsSchema = z.array(journalPresetSchema);

export type JournalPreset = z.infer<typeof journalPresetSchema>;
