import { ToggleControl as WpToggleControl } from "@wordpress/components";
import { ToggleControlProps } from "@wordpress/components/build-types/toggle-control/types";
import { WordPressComponentProps } from "@wordpress/components/ui/context/wordpress-component";

import { dayOneBlue } from "@/styles/theme";

export const ToggleControl: React.FC<
  WordPressComponentProps<ToggleControlProps, "input", false>
> = (props) => {
  return (
    <WpToggleControl
      __nextHasNoMarginBottom
      sx={{
        ".components-form-toggle": {
          ".components-form-toggle__track": {
            borderColor: "borderHighlight",
            backgroundColor: "transparent",
          },
          ".components-form-toggle__thumb": {
            borderColor: "transparent",
            backgroundColor: "borderHighlight",
          },
          "&.is-checked": {
            ".components-form-toggle__track": {
              backgroundColor: "primary",
              borderColor: "transparent",
            },
            ".components-form-toggle__thumb": {
              backgroundColor: "#fff",
              borderColor: "#fff",
            },
            ".components-form-toggle__input:focus + .components-form-toggle__track":
              {
                boxShadow: (theme) =>
                  `0 0 0 2px ${theme.colors?.borderPrimary}`,
              },
          },
          ".components-form-toggle__input:focus + .components-form-toggle__track":
            {
              boxShadow: `0 0 0 2px ${dayOneBlue}`,
            },
        },
      }}
      {...props}
    />
  );
};
