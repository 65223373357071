import { Flex } from "@wordpress/components";

import { D1Modal } from "@/components/D1Modal";
import { JournalPicker } from "@/components/JournalPicker";
import { JournalDBRow } from "@/data/db/migrations/journal";

interface Props {
  handleClose: () => void;
  onPickHandler: (journalId: string) => () => void;
  journals: JournalDBRow[];
  title: string;
  messages: string[];
}

export const JournalPickerModal: React.FC<Props> = ({
  handleClose,
  onPickHandler,
  journals,
  messages,
  title,
}) => {
  if (journals.length <= 1) {
    handleClose();
    const onJournalSelected = onPickHandler(journals[0].id);
    onJournalSelected();
    return null;
  }

  return (
    <D1Modal
      sx={{
        width: ["100dvw", "100dvw", "420px"],
        "& p": {
          lineHeight: 2,
          marginBottom: 2.5,
        },
      }}
      title={title}
      onRequestClose={handleClose}
      shouldCloseOnClickOutside={true}
    >
      {messages.map((message) => (
        <p key={message}>{message}</p>
      ))}
      <Flex
        direction="row"
        justify={"left"}
        align={"flex-end"}
        gap={4}
        sx={{
          mb: 4,
          pt: 0,
        }}
      >
        <JournalPicker
          onChange={(id) => {
            const onJournalSelected = onPickHandler(id);
            onJournalSelected();
            handleClose();
          }}
          journals={journals}
        />
      </Flex>
    </D1Modal>
  );
};
