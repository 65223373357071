import { makeAutoObservable, runInAction } from "mobx";

import { Sentry } from "@/Sentry";
import { WPConnectAPIClient } from "@/components/Settings/WPConnect/WPConnectAPIClient";
import { WPConnection } from "@/components/Settings/WPConnect/WPConnectionTypes";

export type wpConnectViewStateStatus =
  | "pre-load"
  | "loading"
  | "loaded"
  | { error: string };

export class WPConnectViewState {
  public connectedBlogs: WPConnection[] = [];
  public status: wpConnectViewStateStatus = "pre-load";

  get error() {
    return typeof this.status === "object" && "error" in this.status
      ? this.status.error
      : null;
  }

  constructor(private wpConnectApi: WPConnectAPIClient) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async fetchConnectedBlogs() {
    runInAction(() => {
      this.status = "loading";
    });
    try {
      const blogs = await this.wpConnectApi.fetchConnectedBlogs();
      runInAction(() => {
        if ("error" in blogs) {
          this.status = { error: blogs.error };
          return;
        }
        this.connectedBlogs = blogs;
        this.status = "loaded";
      });
    } catch (error) {
      runInAction(() => {
        this.status = {
          error: "An unknown error occurred while loading blogs",
        };
      });
      Sentry.captureException(error);
      throw error;
    }
  }

  async connectNewBlog({
    blog_url,
    username,
    app_pass,
  }: {
    blog_url: string;
    username: string;
    app_pass: string;
  }) {
    const response = await this.wpConnectApi.connectNewBlog({
      blog_url,
      username,
      app_pass,
    });
    if (response.error) {
      return response.error;
    } else {
      runInAction(() => {
        response.newBlog && this.connectedBlogs.push(response.newBlog);
      });
      return "ok";
    }
  }

  async disconnectBlog(blogUrl: string): Promise<"failed" | void> {
    try {
      await this.wpConnectApi.disconnectBlog(blogUrl);
      runInAction(() => {
        this.connectedBlogs = this.connectedBlogs.filter(
          (blog) => blog.blog_url !== blogUrl,
        );
      });
    } catch (error) {
      return "failed";
    }
  }
}
