import { useI18n } from "@wordpress/react-i18n";
import { isEmail } from "@wordpress/url";
import { observer } from "mobx-react-lite";

import { ErrorMessage } from "@/components/ErrorMessage";
import { TextControl } from "@/components/Form/TextControl";
import { GoogleLogin } from "@/components/GoogleLogin";
import { Button } from "@/components/LoggedOut/Button";
import { FormCard } from "@/components/LoggedOut/FormCard";
import { OrSeparator } from "@/components/OrSeparator";
import { SignInWithApple } from "@/components/SignInWithApple";
import { useLogin } from "@/data/hooks/userAccount/useLogin";
import { primaryViewState } from "@/view_state/ViewStates";

type Props = {
  message?: string;
};

export const Reauth: React.FC<Props> = observer(({ message }) => {
  const { __ } = useI18n();
  const user = primaryViewState.user;
  const credentialTypes = user?.credentials;

  const { auth, credsValid, creds, setCreds, validateEmail } = useLogin();

  return (
    <>
      <p>
        {message ||
          __("You need to confirm your account before completing this action.")}
      </p>
      {auth.errors.length > 0 && (
        <ErrorMessage
          message={auth.errors[0]}
          sx={{ "& h3": { display: "none" }, "& p": { mb: 0 } }}
        />
      )}
      {credentialTypes?.includes("Password") && (
        <FormCard
          sx={{
            mt: 2,
            div: {
              px: 0,
              width: "100%",
            },
          }}
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            if (credsValid) {
              auth.attemptLogin(creds.email, creds.password);
            }
          }}
        >
          <TextControl
            label={__("Email address")}
            autoComplete="webauthn username"
            onChange={(value: string) => {
              let isInvalid = creds.isInvalid;
              if (isInvalid && (isEmail(value) || value === "")) {
                isInvalid = false;
              }
              setCreds({ ...creds, email: value.trim(), isInvalid });
            }}
            type="email"
            value={creds.email}
            help={
              creds.isInvalid ? __("Enter a valid email address") : undefined
            }
            onBlur={() => {
              validateEmail(creds);
            }}
            sx={{
              "&&": {
                mb: 5,
                "&&& .components-base-control__help": {
                  color: "red",
                  position: "absolute",
                  mt: 0,
                },
              },
            }}
          />
          <TextControl
            label={__("Password")}
            autoComplete="current-password"
            onChange={(value: string) => {
              setCreds({ ...creds, password: value });
            }}
            type="password"
            value={creds.password}
          />
          <Button
            className="button"
            variant="primary"
            type="submit"
            disabled={!credsValid}
          >
            {__("Log In")}
          </Button>
        </FormCard>
      )}
      {credentialTypes?.includes("Password") &&
        (credentialTypes?.includes("Google ID") ||
          credentialTypes?.includes("SIWA")) && <OrSeparator />}
      {credentialTypes?.includes("Google ID") && (
        <GoogleLogin action={auth.attemptGoogleLogin} />
      )}
      {credentialTypes?.includes("SIWA") && (
        <div sx={{ color: "textPrimary", mb: 4, mt: 3 }}>
          <SignInWithApple action={auth.attemptAppleLogin} />
        </div>
      )}
    </>
  );
});
